import React from "react";
import { Link } from "react-router-dom";
import styles from "./Sessions.module.css";
import moment from "moment";

const UpcomingSessions = (props) => {
  const { allSessions, upcomingSession } = props;
  console.log("Sagar", upcomingSession)
  return (
    <React.Fragment>
      <div className="col-md-12 pad-0">
        <div className="row">
          {
            upcomingSession.map((as) => {

              console.log(upcomingSession);
              let start, end, startTime, endTime;
              if (as.start) {
                start = moment.parseZone(as.start).local().format("YYYY-MM-DD")
                startTime = moment.parseZone(as.start).local().format("HH:mm")
              }
              if (as.end) {
                end = moment.parseZone(as.end).local().format("YYYY-MM-DD")
                endTime = moment.parseZone(as.end).local().format("HH:mm")
              }
              if (as.status === "ACCEPTED") {
                return (
                  <div className="col-md-4">
                    <div className={`cardDiv ${styles.sessionsDiv}`}>
                      <Link to={`/student/session-preview/${as.sessionID}`}>
                        <h2>{as.title}</h2>
                      </Link>
                      <p>{as.topic}</p>
                      <div className="d-flex justify-content-between">
                        <div >
                          <p>Start Time</p>
                          <span>{start}</span> <span>{startTime}</span>
                        </div>
                        <div className=' ' >
                          <p>End Time</p>
                          <span>{end}</span> <span>{endTime}</span>
                        </div>


                      </div>
                      {/* <button className="btn btn-sm">Subscribe</button> */}
                    </div>
                  </div>
                );
              }
              return (
                <h3></h3>
              )
            })}
        </div>
      </div>
     
    </React.Fragment>
  );
};

export default UpcomingSessions;
