import React, { Fragment, useState } from 'react'
import parse from "html-react-parser";
import styles from "../../Students/MyQueries/MyQueries.module.css";
import { userID } from "../../../services/Authentication/AuthService";
import DisplayErrorMessage from '../../Share/DisplayErrorMessage';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Modal, Button } from "react-bootstrap";

const QueriesInprogress = props => {
    const { QueriesInprogressList, statchange, addReplay } = props
    const [show, setShow] = useState(false)
    const [mess, setMess] = useState('')
    const [modalShow,setModalShow]=useState(false)
    const [chatId,setChatId]=useState('')
    if (QueriesInprogressList && QueriesInprogressList.length === 0) {
        return (
            <DisplayErrorMessage
                message="No Inprogress queries found"
                errorSize="big"
            />
        );
    }
    const submitHandler=(data)=>{
            console.log('submiy'+JSON.stringify(data))
    }
    return <Fragment>
        {QueriesInprogressList.length > 0 &&
            QueriesInprogressList.map(q => {
                return <div className={`cardDiv ${styles.queryDiv}`} >
                    <h5 title="Curriculum" className={styles.curriculum}>
                        <p>{q.curriculum}</p> {'-'}
                    </h5>
                    <div title="Subjet" className={styles.subject}>
                        <p>{q.subject}</p>{'-'}
                    </div>
                    <div title="Topic" className={styles.topic}>
                        <p>{q.topic}</p>{'-'}
                    </div>
                    <div title="Chapter" className={styles.chapter}>
                        <p>{q.chapter}</p>{'-'}
                    </div>

                    <hr className={styles.hr} />
                    {q.messages &&
                        q.messages.map((m) => {
                            // console.log('idd' + m.author_id + '===' + userID() + '//' + (m.author_id === userID()))
                            return <Fragment>
                                {m.author_id === +userID() ? (
                                    <div className="col-md-12">
                                        <p
                                            key={m.createdAt}
                                            className={styles.studentReview}
                                        >
                                            {parse(m.message)}
                                        </p>
                                    </div>
                                ) : (
                                    <div className="col-md-12 text-right">
                                        <p
                                            key={m.createdAt}
                                            className={styles.teacherReview}
                                        >
                                            {parse(m.message)}
                                        </p>
                                    </div>
                                )}
                            </Fragment>
                        })}
                    {!show && <div className="d-flex flex-row  justify-content-end mt-2">
                        <button
                            className="btn btn-success   mr-2"
                            onClick={() => setShow(state => !state)}
                        >
                            replay
                        </button>
                        <button
                            className="btn btn-danger   mr-0"
                            // onClick={raiseQuery}
                            onClick={() => { setChatId(q.chatID); setModalShow(true) }}
                        >
                            resolve
                        </button>
                    </div>}
                    {show && <div>
                        <div className="col-md-12 pad-0 mt-2">
                            <CKEditor
                                editor={ClassicEditor}
                                data={mess}
                                onChange={(event, editor) => {
                                    setMess(editor.getData());
                                }}
                            />
                        </div>
                        <div className="col-md-12 pad-0 text-right">
                            <button
                                className="btn btn-danger mt-3 mr-2 mr-0"
                                onClick={() => setShow(state => !state)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary mt-3 mr-0"
                                onClick={() => { addReplay(q, mess); setShow(false)}}
                            >
                                Submit
                            </button>
                         
                        </div>
                    </div>}

                </div>
            })
        }
        <Modal show={modalShow} onHide={!modalShow}>
            <Modal.Header closeButton>
                <Modal.Title>Resolve Query</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you want close the query?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalShow(false)}>
                    No
                </Button>
                <Button variant="primary" onClick={() => { statchange(2, chatId); setModalShow(false);}}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    </Fragment>
}

export default QueriesInprogress;
