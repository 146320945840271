import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const Assessment = ({ assessmentList, search, searchAssessment }) => {
  return (
    <div className="row">
      <h1 className="form-heading">Assessment List</h1>
      <div className="add-btn-div">
        <Link
          to={`/admin/add-assessment`}
          className="btn btn-sm btn-primary add-btn"
        >
          Add Assessment
        </Link>
      </div>
      <div class="input-group row col-12   mb-2 ">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <img src='https://img.icons8.com/search' style={{ maxWidth: '40%' }} />
          </div>
        </div>
        <input type="text" value={search} onChange={text => searchAssessment(text)} class="form-control" placeholder="Search Assessment Name	" />
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Assessment Name</th>
                  <th>Type</th>
                  <th>Curriculum</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {assessmentList &&
                  assessmentList.length > 0 &&
                  assessmentList.map((a) => {
                    return (
                      <tr key={a._id}>
                        <td>{a.testID}</td>
                        <td>{a.assessmentName}</td>
                        <td>{a.type}</td>
                        <td>{a.curriculum}</td>
                        <td>{moment(a.startdate).format("MM/DD/YYYY")}</td>
                        <td>{moment(a.enddate).format("MM/DD/YYYY")} </td>
                        <td>
                          <Link
                            to={`/admin/view-assessment/${a.testID}/${a.curriculumCode}`}
                          >
                            <button className="btn btn-sm btn-primary">
                              View
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assessment;
