import React from "react";
import Subscription from "../../components/Students/Subscription/Subscription";

export default function SubscriptionContainer() {
  return (
    <React.Fragment>
      <Subscription />
    </React.Fragment>
  );
}
