import React, { Component } from "react";
import LoadingModal from "../../components/Share/LoadingModal";
import AddCourseContent from "../../components/admin/course/addCourseContent";
import CouseContent from "../../components/admin/course/courseContent";
import ViewCourseContent from "../../components/admin/course/viewCourseContent";

import {
  getAllCourse,
  getCourse,
  saveCourse,
  getSubjectsForCourse,
  getSubjectinfo,
} from "../../services/Admin/courseService";

import { getCurriculumCodeFun } from "../../utils/utils";

import { getAllCurriculumList } from "../../components/Share/GetCurriculumDetails";

class CourseContainer extends Component {
  state = {
    mode: "",
    search: '',
    curriculumList: [],
    courseListAll: [],
    subjectsList: [],
    courseList: [],
    courseListIndividual: [],
    courseData: {
      curriculum: "",
      curriculumCode: "",
      subject: "",
      subjectInfo: "",
      minCredits: "",
      maxCredits: "",
      practicalSession: "",
      topic: "",
      topicInfo: "",
      startDate: "",
      endDate: "",
      chapters: [
        {
          chapter: "",
          chapterInfo: "",
        },
      ],
    },
    isLoading: false,

  };

  loadDefaultValues = () => {
    const pathName = this.props.match.path;
    if (pathName === "/admin/add-course-content") {
      this.setState({ mode: "Add" });
    } else if (pathName === "/admin/edit-course-content/:id") {
      this.setState({ mode: "Edit" });
    } else if (pathName === "/admin/view-course-content/:id") {
      this.setState({ mode: "View" });
    } else if (pathName === "/admin/course-content") {
      this.setState({ mode: "List" });
    }
  };

  handleInput = async ({ currentTarget: input }, name) => {
    const data = { ...this.state.courseData };
    const { options, value, selectedIndex } = input;
    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;
      data[input.name] = optValue;
    } else {
      data[input.name] = input.value;
    }

    if (name === "curriculum") {
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      data["curriculumCode"] = curriculumCodeValue;
      this.getAllSubject(curriculumCodeValue);
    }

    if (name === "subject") {
      this.getSubjectinfo(data.curriculumCode, input.value);
    }

    this.setState({ courseData: data });
  };

  handleInput1 = (input, name) => {
    const data = { ...this.state.courseData };
    if (name) {
      data[name] = input;
    }

    if (name === "curriculum") {
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input
      );
      data["curriculumCode"] = curriculumCodeValue;
      this.getAllSubject(curriculumCodeValue);
    }

    if (name === "subject") {
      this.getSubjectinfo(data.curriculumCode, input);
    }

    this.setState({ courseData: data });
  };

  handleDates = (date, name) => {
    const courseData = this.state.courseData;
    courseData[name] = date;
    this.setState({ courseData });
  };

  handleCkEditor = (data, name) => {
    let courseData = this.state.courseData;
    courseData[name] = data;
    this.setState({ courseData });
  };

  handleChapterInfo = (data, index) => {
    const chapters = this.state.courseData.chapters;
    chapters[index].chapterInfo = data;
    this.setState({ chapters });
  };

  handlechapter = ({ currentTarget: input }, index) => {
    const chapters = this.state.courseData.chapters;
    chapters[index].chapter = input.value;
    this.setState({ chapters });
  };

  handleChaptersAdd = (e) => {
    e.preventDefault();
    const chapters = this.state.courseData.chapters;
    chapters.push({
      chapter: "",
      chapterInfo: "",
    });
    this.setState({ chapters });
  };
  deleteChapter=(ind)=>{
      console.log('ind',ind);
    const newChap = this.state.courseData.chapters.filter((q,index)=>{
      if(index!==ind){
        return q
      }
    })
    this.setState({ chapters: newChap, courseData: {...this.state.courseData,chapters: newChap}})
  }

  // Get the all subject list in add course
  getAllSubject = async (curriculumCode) => {
    const payload = { curriculumCode: curriculumCode };
    getSubjectsForCourse(payload)
      .then((res) => {
        this.setState({ subjectsList: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Get the all subject list in add course
  getSubjectinfo = async (curriculumCode, subject) => {
    const payload = {
      curriculumCode: curriculumCode,
      subject: subject,
    };
    getSubjectinfo(payload)
      .then((res) => {
        const data = res.data.data[0];
        console.log("data", data);
        const courseData = { ...this.state.courseData };
        courseData.subject = data.subject;
        courseData.subjectInfo = data.subjectInfo;
        courseData.minCredits = data.minCredits;
        courseData.maxCredits = data.maxCredits;
        courseData.startDate = data.startDate;
        courseData.endDate = data.endDate;
        courseData.practicalSession = data.practicalSession;
        this.setState({
          courseData,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Get the all subject list in list view
  getAllCourseList = async () => {
    getAllCourse()
      .then((res) => {
        this.setState({ courseList: res.data.data, courseListAll: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Submit course form
  handleSubmit = async (e) => {
    const courseId = this.props.match.params.id;
    const payload = this.state.courseData;
    const chapters = this.state.courseData.chapters;
    let errChap;
    chapters.map((c) => {
      if (c.chapter === "" || c.chapterInfo === "") {
        errChap = false;
        return false;
      } else {
        errChap = true;
      }
    });

    if (payload.topic === "") {
      errChap = false;
    }

    if (errChap) {
      this.setState({ isLoading: true });
      saveCourse(payload, courseId)
        .then((res) => {
          this.props.history.push("/admin/course-content");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      alert("All fields are mandatory");
    }
  };

  // Get the individual course details
  getIndividualCurriculumList = async (courseId) => {
    const courseId1 = this.props.match.params.id;
    const payload = {
      _id: courseId1,
    };
    getCourse(payload)
      .then((res) => {
        this.setState({ courseListIndividual: res.data.data[0] });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  editCourse = async () => {
    const courseId = this.props.match.params.id;
    const courseDataIndividual = await getCourse({
      _id: courseId,
    });
    let courseDataIndividualData = courseDataIndividual.data.data[0];
    let subjectsList = this.getAllSubject(
      courseDataIndividualData.curriculumCode
    );
    this.setState({
      courseData: courseDataIndividualData,
      subjectsList: subjectsList,
    });
  };

  closeViewCourse = () => {
    this.props.history.push("/admin/course-content");
  };

  async componentDidMount() {
    await this.loadDefaultValues();
    const { mode } = this.state;

    if (mode === "Add") {
      let data = await getAllCurriculumList();
      this.setState({ curriculumList: data });
    } else if (mode === "Edit") {
      this.editCourse();
      const data = this.state.courseData;
      let curriculumList = await getAllCurriculumList();
      this.setState({ curriculumList });
    } else if (mode === "View") {
      this.getIndividualCurriculumList();
    } else if (mode === "List") {
      this.getAllCourseList();
    }
  }
  searchCourse = (event) => {
    console.log('check');
    this.setState({ search: event.target.value })
    let val = String(event.target.value).toLowerCase()
    if (!val) {
      this.getAllCourseList()
      return
    }
    let filter = this.state.courseListAll.filter(q => {
      console.log('q===',q);
      if (q.subject.toLowerCase().indexOf(val) !== -1) {

        return q
      } else if (q.curriculum.toLowerCase().indexOf(val)!==-1){
        return q
      } else if (q.topic.toLowerCase().indexOf(val) !== -1){
        return q
      }
    })
    // console.log('filter-' + JSON.stringify(filter))
    this.setState({ courseList: [...filter] })

  }
  render() {
    let path = this.props.match.path;
    const { mode, courseData, curriculumList, subjectsList, chapters } =
      this.state;
    return (
      <React.Fragment>
        {path === "/admin/course-content" && (
          <CouseContent courseList={this.state.courseList} searchCourse={this.searchCourse} search={this.state.search} />
        )}
        {(path === "/admin/add-course-content" ||
          path === "/admin/edit-course-content/:id") && (
            <AddCourseContent
              mode={mode}
              courseData={courseData}
              curriculumList={curriculumList}
              subjectsList={subjectsList}
              handleSubmit={this.handleSubmit}
              handleInput={this.handleInput}
              handleCkEditor={this.handleCkEditor}
              handlechapter={this.handlechapter}
              handleChapterInfo={this.handleChapterInfo}
              handleChaptersAdd={this.handleChaptersAdd}
              handleDates={this.handleDates}
              handleInput1={this.handleInput1}
            deleteChapter={this.deleteChapter}
            />
          )}

        {path === "/admin/view-course-content/:id" && (
          <ViewCourseContent
            courseListIndividual={this.state.courseListIndividual}
            closeViewCourse={this.closeViewCourse}
          />
        )}
        <LoadingModal visible={this.state.isLoading} />
      </React.Fragment>
    );
  }
}

export default CourseContainer;
