export default function OrgAdmin() {
  return (
    <div className="row p-3">
      <div className="col-md-6">
        <h2>Org-Admin</h2>
      </div>
      <div className="col-md-6">
        <button type="button" class="btn btn-sm btn-primary add-btn ml-3">
          Add Org-admin
        </button>
      </div>
      <input type="text" class="form-control" placeholder="Org Admin" />
    </div>
  );
}
