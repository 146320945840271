import React, { useState, useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import Header from "../../components/admin/header/header";
import Sidebar from "../../components/admin/sidebar/sidebar";
import routerService from "./adminRoutes";
import "../../styles/admin.css";
import { getInactiveUser } from '../../services/Admin/commonService';
import { useSelector, useDispatch } from 'react-redux';
import * as Inactive from '../../store/actions/inactiveUser';
import {
  userID,
} from "../../services/Authentication/AuthService";
const AdminLayout = (props) => {
  // state={
  //   apiRes:{},
  // }
  //console.log('id---------------'+userID())
  const dispatch = useDispatch()
  const [apiRes, setApiRes] = useState()
  const [apiSession, setSession] = useState()

  // componentDidMount(){
  //   this.getInactive()
  // }
  let lis = useSelector(state => {
    return state.headUser.userList
  })
  let session = useSelector(state => {
    return state.headUser.sessionList
  })
  useEffect(() => {
    setSession(session)
  }, [session])
  useEffect(() => {
    setApiRes(lis)
  }, [lis])
  useEffect(() => {
    // getInactive();
    dispatch(Inactive.getList())
    dispatch(Inactive.getSession())
 

  }, [])
  const getInactive = () => {
    getInactiveUser().then(result => {
      if (result.status === 200) {
        // this.setState({ apiRes: result.data.userDetail})
        setApiRes(result.data.userDetail)
        // dispatch(Inactive.inActiveList(result.data.userDetail))
        dispatch(Inactive.getList())

      }
    }).catch(err => console.log('err header-' + err))
  }

  const { match } = props;


  return (
    <React.Fragment>
      <div
        className="main-wrapper"
        style={{ minHeight: window.innerHeight - 60 }}
      >
        <Header userList={apiRes} apiSession={apiSession} />
        <Sidebar />
        <div
          className="content-wrapper"
          style={{ minHeight: window.innerHeight - 60 }}
        >
          {routerService &&
            routerService.map((route, key) => {
              return (
                <Route
                  key={key}
                  path={`${match.url}/${route.path}`}
                  component={route.component}
                />
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );

}
export default withRouter(AdminLayout);
