import React, { Component } from "react";
import Staff from "../../components/admin/staff/staff";
import AddStaff from "../../components/admin/staff/addStaff";
import AddStaffRole from "../../components/admin/staff/addStaffRole";
import { staffValues } from "../../constants/AdminInitialValues";
import LoadingModal from "../../components/Share/LoadingModal";

import {
  createRole,
  saveStaff,
  getStaffList,
  getRolesList,
  deleteStaff,
  getStatusChange
} from "../../services/Admin/staffService";

import {
  getAllCurriculumList,
  getAllSubjectList,
} from "../../components/Share/GetCurriculumDetails";
import { getCurriculumCodeFun } from "../../utils/utils";
import { getInactiveUser } from "../../services/Admin/commonService";

class StaffContainer extends Component {
  state = {
    mode: "",
    curriculumList: [],
    subjectsList: [],
    staffList: {},
    staffListAll: [],

    rolesList: [],
    staffValues: staffValues,
    search: '',
    isLoading: false,

  };

  loadDefaultValues = () => {
    const pathName = this.props.match.path;
    if (pathName === "/admin/add-staff") {
      this.setState({ mode: "Add" });
    } else if (pathName === "/admin/edit-staff/:id") {
      this.setState({ mode: "Edit" });
    } else if (pathName === "/admin/staff") {
      this.setState({ mode: "View" });
    }
  };

  handleInput = async ({ currentTarget: input }, name, formik) => {
    const data = { ...this.state.staffValues };
    const { options, selectedIndex } = input;
    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;
      data[input.name] = optValue;
    } else {
      data[input.name] = input.value;
    }

    if (name === "curriculum") {
      data.subject = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      formik.curriculumCode = curriculumCodeValue;
      let subjectsList = await getAllSubjectList(curriculumCodeValue);
      this.setState({ subjectsList });
    }
  };

  handleSubmitRole = async (formik) => {
    const values = formik.values;
    var role = values.roleName.toLowerCase();
    const payload = { roleName: role };
    if (formik.isValid) {
      createRole(payload)
        .then((res) => {
          this.props.history.push("/admin/staff");
        })
        .catch((err) => { });
    }
  };

  handleSubmitStaff = async (formik) => {
    const payload = formik.values;
    if (formik.isValid) {
      this.setState({ isLoading: true });
      saveStaff(payload)
        .then((res) => {
          this.props.history.push("/admin/staff");
        })
        .catch((err) => { });
    }
  };
  changeStatus = (id, value) => {
    let payload = {
      id: id,
      inactive: value
    }
    getStatusChange(payload).then(res => {
      if (res.status === 200 && res.data.status) {
        this.getStaffList();
      }
    })
  }

  editStaff = async () => {
    const staffId = this.props.match.params.id;
    const payload = {
      userID: parseInt(staffId),
    };
    let pay={}
    getStaffList(payload)
      .then((res) => {
        const values = res.data.data[0];
        pay=values
        // this.setState({ staffValues: values });
      })
      .catch((err) => { });
    let curriculumList = await getAllCurriculumList();
    let subjectsList = await getAllSubjectList(
      pay.curriculumCode
    );
    this.setState({ curriculumList, subjectsList });
    this.setState({ staffValues: pay });

  };

  getStaffList = async () => {
    getStaffList()
      .then((res) => {
        this.setState({
          staffList: res.data.data, staffListAll: res.data.data
        });
      })
      .catch((err) => { });
  };

  getRolesList = async () => {
    getRolesList()
      .then((res) => {
        this.setState({
          rolesList: res.data.data,
        });
      })
      .catch((err) => { });
  };

  async componentDidMount() {
    await this.loadDefaultValues();
    if (this.state.mode === "Add") {
      this.getRolesList();
      let data = await getAllCurriculumList();
      this.setState({ curriculumList: data });
    } else if (this.state.mode === "Edit") {
      this.editStaff();
      this.getRolesList();
    } else if (this.state.mode === "View") {
      this.getStaffList();
    }
  }
  staffDelete = async (ID) => {
    try {
      await deleteStaff(ID);
      this.getStaffList();
      this.props.history.push("/admin/staff");
    } catch (ex) {
      // if (ex.response && ex.response.status === 404) {
      //   this.props.history.replace("/not-found");
      // }
    }
  };
  searchStaff = (event) => {
    this.setState({ search: event.target.value })
    let val = String(event.target.value).toLowerCase()
    if (!val) {
      this.getStaffList()
      return
    }
    let filter = this.state.staffListAll.filter(q => {

      // console.log('ass--',q)
      try {
        if (q.firstName.toLowerCase().indexOf(val) !== -1) {
          return q
        } else if (q.curriculum.toLowerCase().indexOf(val) !== -1) {
          return q
        } else if (q.subject.toLowerCase().indexOf(val) !== -1) {
          return q
        }
      } catch (err) {

      }

    })
    // console.log('filter-' + JSON.stringify(filter))
    this.setState({ staffList: [...filter] })

  }
  render() {
    const {
      curriculumList,
      subjectsList,
      mode,
      staffValues,
      staffList,
      rolesList,
    } = this.state;
    let path = this.props.match.path;
    return (
      <React.Fragment>
        {path === "/admin/staff" && (
          <Staff staffList={staffList} updateStaffData={this.updateStaffData} search={this.state.search} searchStaff={this.searchStaff} staffDelete={this.staffDelete} changeStatus={this.changeStatus} />
        )}

        {(path === "/admin/add-staff" || path === "/admin/edit-staff/:id") && (
          <AddStaff
            mode={mode}
            staffValues={staffValues}
            curriculumList={curriculumList}
            subjectsList={subjectsList}
            rolesList={rolesList}
            handleInput={this.handleInput}
            handleSubmitStaff={this.handleSubmitStaff}
          />
        )}

        {path === "/admin/add-staff-role" && (
          <AddStaffRole handleSubmitRole={this.handleSubmitRole} />
        )}
        <LoadingModal visible={this.state.isLoading} />

      </React.Fragment>
    );
  }
}

export default StaffContainer;
