import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../../common/Formik/Common.css'
import Autocomplete1 from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from "../common/button";
import Select from "../common/select";
import Input from "../common/input";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextError from "../../../common/Formik/TextError";

import { curriculumListFun, subjectListFun } from "../../../utils/utils";
import { Link } from "react-router-dom";

const AddCourseContent = (props) => {
  const {
    mode,
    curriculumList,
    subjectsList,
    courseData,
    handleInput,
    handleCkEditor,
    handleSubmit,
    handlechapter,
    handleChapterInfo,
    handleChaptersAdd,
    handleInput1,
    deleteChapter
  } = props;

  let data = courseData;
  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <h1 className="form-heading">{mode} Chapter Content</h1>
          <div className="add-btn-div">
            <Link
              to={`/admin/course-content`}
              className="btn btn-sm btn-danger"
            >
              Cancel
            </Link>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {/* <Select
                    name="curriculum"
                    optname="curriculum"
                    label="Curriulum Name"
                    value={data.curriculum ? data.curriculum : ""}
                    options={curriculumOptions}
                    onChange={(e) => handleInput(e, "curriculum")}
                  /> */}
                  <div className="form-group ">
                    <label className="label-heading" htmlFor="curriculum">
                      Curriculum
                    </label>

                    {mode === 'Add' && <Autocomplete1
                      disablePortal
                      id="combo-box-demo"
                      // className="form-control"
                      options={curriculumOptions}
                      getOptionLabel={(option) => option.curriculum}
                      onChange={(event, value) => handleInput1(value.curriculum, "curriculum")}

                      style={{ width: '100%' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />}
                    {mode === 'Edit' && data.curriculum && <Autocomplete1
                      disablePortal
                      id="combo-box-demo"
                      // className="form-control"
                      options={curriculumOptions}
                      getOptionLabel={(option) => option.curriculum}
                      onChange={(event, value) => handleInput1(value.curriculum, "curriculum")}
                      disableClearable={true}
                      defaultValue={{ curriculum: data.curriculum ? data.curriculum : '' }}
                      style={{ width: '100%', height: '38px' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />}
                    {/* <ErrorMessage component={TextError} name="curriculum" /> */}
                  </div>

                </div>

                <input
                  type="hidden"
                  className="form-control"
                  name="curriculumCode"
                  defaultValue={data.curriculumCode}
                  disabled
                />

                <div className="col-md-6">
                  {/* <Select
                    name="subject"
                    optname="subject"
                    label="Subject Name"
                    value={data.subject ? data.subject : ""}
                    options={subjectOptions}
                    onChange={(e) => handleInput(e, "subject")}
                  /> */}
                  <div className="form-group ">
                    <label className="label-heading" htmlFor="curriculum">
                      Subject Name
                    </label>

                    {mode === 'Add' && <Autocomplete1
                      disablePortal
                      id="combo-box-demo"
                      // className="form-control"
                      options={subjectOptions}
                      getOptionLabel={(option) => option.subject}
                      onChange={(event, value) => handleInput1(value.subject, "subject")}

                      //style={{ width: '100%' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />}
                    {mode === 'Edit' && data.subject && <Autocomplete1
                      disablePortal
                      id="combo-box-demo"
                      // className="form-control"
                      options={subjectOptions}
                      getOptionLabel={(option) => option.subject}
                      onChange={(event, value) => handleInput1(value.subject, "subject")}
                      disableClearable={true}
                      defaultValue={{ subject: data.subject ? data.subject : '' }}
                      style={{ width: '100%', height: '38px' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />}
                    {/* <ErrorMessage component={TextError} name="curriculum" /> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="label-heading" htmlFor="">
                      Subject Info
                    </label>

                    <CKEditor
                      editor={ClassicEditor}
                      data={data.subjectInfo ? data.subjectInfo : ""}
                      isReadOnly={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Course Start Date (MM/DD/YYYY)</label>
                    <DatePicker
                      name="startDate"
                      selected={
                        data.startDate
                          ? new Date(
                            moment(data.startDate).format("MM/DD/YYYY")
                          )
                          : ""
                      }
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Course End Date (MM/DD/YYYY)</label>
                    <DatePicker
                      name="endDate"
                      selected={
                        data.endDate
                          ? new Date(moment(data.endDate).format("MM/DD/YYYY"))
                          : ""
                      }
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <Input
                    type="text"
                    name="minCredits"
                    label="Min Credits"
                    value={data.minCredits ? data.minCredits : ""}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    type="text"
                    name="maxCredits"
                    label="Max Credits"
                    value={data.maxCredits ? data.maxCredits : ""}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    type="text"
                    name="practicalSession"
                    label="Practical Session"
                    value={data.practicalSession ? data.practicalSession : ""}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    type="text"
                    name="topic"
                    label="Topic Name"
                    value={data.topic ? data.topic : ""}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="label-heading" htmlFor="">
                      Topic Info
                    </label>

                    <CKEditor
                      editor={ClassicEditor}
                      data={data.topicInfo ? data.topicInfo : ""}
                      onChange={(event, editor) => {
                        handleCkEditor(editor.getData(), "topicInfo");
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  {data.chapters.map((option, index) => {
                    return (
                      <div
                        className="col-md-12"
                        key={index}
                        style={{
                          background: "#e6e6e6",
                          padding: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <div className="col-md-12">
                          <button
                            className="btn btn-danger  btn-sm float-right mb-2"
                            onClick={() => deleteChapter(index)}
                          >
                            delete
                          </button>
                        </div>
                        <div className="form-group">
                          <label>Chapter Name - {index + 1}</label>
                          <input
                            type="text"
                            className="form-control"
                            name={option.chapter}
                            value={option.chapter ? option.chapter : ""}
                            onChange={(e) => handlechapter(e, index)}
                          />
                        </div>
                        <div className="form-group">
                          <label>Chapter Info - {index + 1}</label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={option.chapterInfo}
                            onBlur={(event, editor) =>
                              handleChapterInfo(editor.getData(), index)
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="col-md-12">
                  <button
                    className="btn btn-primary mt-2 btn-sm float-right"
                    onClick={handleChaptersAdd}
                  >
                    Add More
                  </button>
                </div>

                <div className="text-center col-md-12">
                  <Button
                    className="btn btn-primary form-btn"
                    label={mode + " Chapter"}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourseContent;
