import React from "react";
import styles from "./share.module.css";

const DisplayErrorMessage = (props) => {
  const { message, errorSize } = props;
  let heightValue;
  let iconSizeValue;
  let fontSizeValue;
  if (errorSize === "big") {
    heightValue = window.innerHeight - 155;
    iconSizeValue = "150px";
    fontSizeValue = "18px";
  } else {
    heightValue = "130px";
    iconSizeValue = "40px";
    fontSizeValue = "14px";
  }
  return (
    <div className={styles.cardDiv} style={{ height: heightValue }}>
      <div>
      
      </div>
      <p className={styles.center} style={{ fontSize: fontSizeValue }}>
        {message}
      </p>
    </div>
  );
};

export default DisplayErrorMessage;
