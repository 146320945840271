import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, HashRouter } from "react-router-dom";
import App from "./App";
import { Provider } from 'react-redux'
import "bootstrap/dist/css/bootstrap.css";
import "line-awesome/dist/line-awesome/css/line-awesome.css";
import "./global.css";
import { createStore, combineReducers, applyMiddleware } from 'redux';
import inactiveUser from './store/reducer/inactiveUser'
import logo from './store/reducer/logoImage';

import thunk from 'redux-thunk';

const rootReducer = combineReducers({
  headUser: inactiveUser,
  logo:  logo
});
const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(

  <HashRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </HashRouter>
  ,
  document.getElementById("root")
);
