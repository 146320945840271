import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

const Input = (props) => {
  const { label, name, ...rest } = props;
  return (
    <div className="form-group">
      <label className="label-heading" htmlFor={name}>
        {label}
      </label>
      {/* <input {...rest} name={name} id={name} className="form-control" /> */}
      <Field {...rest} name={name} id={name} className="form-control" />
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default Input;
