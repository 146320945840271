import React from "react";
import image from "../../..//assets/Images/admin-bg2.jpg";
import image1 from "../../..//assets/Images/admin-bg3.jpg";
import image2 from "../../..//assets/Images/admin-bg4.jpg";

const ClassroomScheduling = () => {
  return (
    <React.Fragment>    
        <h2>Offline Sessions</h2>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <p style={{ fontSize: "20px" }}>Session 1</p>
              <a href="/#/OrgAdmin/ClassroomScheduling">
                <img src={image} style={{ width: "100%", height: "100%" }} />
              </a>
            </div>
            <div className="col-md-4">
              <p style={{ fontSize: "20px" }}>Session 2</p>
              <a href="/#/OrgAdmin/ClassroomScheduling">
                <img src={image1} style={{ width: "100%", height: "100%" }} />
              </a>
            </div>
            <div className="col-md-4">
              <p style={{ fontSize: "20px" }}>Session 3</p>
              <a href="/#/OrgAdmin/ClassroomScheduling">
                <img src={image2} style={{ width: "100%", height: "100%" }} />
              </a>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-4">
              <p style={{ fontSize: "20px" }}>Session 4</p>
              <a href="/#/OrgAdmin/ClassroomScheduling">
                <img src={image} style={{ width: "100%", height: "100%" }} />
              </a>
            </div>
            <div className="col-md-4">
              <p style={{ fontSize: "20px" }}>Session 5</p>
              <a href="/#/OrgAdmin/ClassroomScheduling">
                <img src={image1} style={{ width: "100%", height: "100%" }} />
              </a>
            </div>
            <div className="col-md-4">
              <p style={{ fontSize: "20px" }}>Session 6</p>
              <a href="/#/OrgAdmin/ClassroomScheduling">
                <img src={image2} style={{ width: "100%", height: "100%" }} />
              </a>
            </div>
          </div>
        </div>
    </React.Fragment>
  );
};

export default ClassroomScheduling;
