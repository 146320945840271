import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import Autocomplete from 'react-autocomplete';
import './Common.css'
import Autocomplete1 from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

function AutoComp(props) {
    const { label, name, options, default1, optname, ...rest } = props;
    console.log(default1)

    return <div className="form-group col-md-6">
        <label className="label-heading" htmlFor={name}>
            {label}
        </label>
       
         <Autocomplete1
            id="combo-box-demo"
            // className="form-control"
            options={options}
            getOptionLabel={(option) => option[optname]}
            style={{ width: '100%', height: '38px' }}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
        <ErrorMessage component={TextError} name={name} />
    </div>

}
export default AutoComp
