import React, { useEffect, useState } from "react";
import Logo from "../../assets/Images/log.png";
import "./Auth.css";


import { Formik } from "formik";
import { loginValidation } from "../../validations/StudentValidations";
import FormikControl from "../../common/Formik/FormikControl";
import { Link } from "react-router-dom";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { useDispatch, useSelector } from 'react-redux';
import * as Img from '../../store/actions/logoImage'

const Login = (props) => {
  const [logoImg, setLogoImg] = useState('')

  const { loginData, errorMsg, handleSubmitLogin, redirectRegister, logToGoogle } = props;
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(Img.getlogo())
  }, [])
  useEffect(() => {

  }, [sessionStorage.logo])
  let image = useSelector(state => {
    localStorage.setItem('logo', state.logo.logo)
    return state.logo.logo
  })
  useEffect(() => {
    console.log('image logo')
    setLogoImg(image)
  }, [image])
  const responseGoogle = (response) => {

    const token = {
      tokenId: response.tokenId,
      id: response.googleId,
      // givenName: response.profileObj.givenName,
      // familyName: response.profileObj.familyName,
      email: response.profileObj.email

    }

    logToGoogle(token)

  }
  const responseFacebook = (response) => {
    console.log(response);
  }
  const componentClicked = (response) => {
    console.log(response);
  }

  const [passwordtype, setPasswordtype] = useState('password')
  const [eye, eyeClose] = useState(true)

  const toggle = ()=>{
    if( passwordtype == 'password'){
       setPasswordtype("text")
      eyeClose(false)
    }
    else{
      setPasswordtype("password")
      eyeClose(true)
    }
  }
  return (
    <div className="login theam-color">
      <div className="row">
        <div className="col-md-6 login-left"></div>
        <div className="col-md-6 login-right pad-0">
          {Logo && (
            <img
              src={Logo}
              alt="logo"
              height="150px"
              style={{ margin: "10px auto", display: "block" }}
            />
          )}
          <h3 className="register-heading mb-4">Login</h3>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <Formik
                initialValues={loginData}
                validationSchema={loginValidation}
                validateOnMount
                enableReinitialize
              >
                {(formik) => {
                  const { handleSubmit } = formik;
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="col-md-12">
                        <FormikControl
                          control="input"
                          type="text"
                          name="email"
                          placeholder="Email Address"
                        />
                      </div>
                      <div className="col-md-12 password">
                        <FormikControl
                          control="input"
                          type={passwordtype}
                          name="password"
                          placeholder="Password"
                          id="password-feild"
                          className='password_field w-100'
                          
                        />
                               
                     
                      <span id="eye_icon"><i onClick={toggle} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}  style={{zIndex:1}}></i> </span>
                      </div>
                       

                      <div className="loginError">{errorMsg}</div>

                      <div className="text-center col-md-12">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary theam-color mt-2"
                          // method='post'
                          onClick={() => handleSubmitLogin(formik)}
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="col-md-12 exists-acc">
              <p>
                Don't have an account?{" "}
                <strong>
                  <Link to="#" onClick={redirectRegister}>
                    Register Here
                  </Link>
                </strong>
              </p>
            </div>
            <div className="col-md-12 exists-acc">
              <p>
                <Link to="/verification">Forget Password.</Link>
              </p>
            </div>
            <div className="col-md-12 exists-acc">
              <GoogleLogin
                clientId="752039833235-5m8c0h49oarc5auh156vhhoqlt86og3a.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </div>
            {/* <div className="col-md-12 exists-acc">
              <FacebookLogin
                appId="351718189849173"
                autoLoad={false}
                textButton="Login with Facebook  "
                fields="name,email,picture"
                onClick={componentClicked}
                callback={responseFacebook}
                size="small"
                icon="fa-facebook-f"
              />,
            </div> */}

            <div id="google_translate_element"></div>
          </div>

          {/* <hr />

          <div className="lw lwg">
            <img src={googleImg} />
            <span>Login with Google</span>
          </div>

          <div className="lw lwg">
            <img src={facebookImg} />
            <span>Login with Facebook</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
