import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/Images/logo.png";
import { useDispatch, useSelector } from 'react-redux';

const RegisterSuccess = () => {
  const [logoImg, setLogoImg] = useState('')
  let image = useSelector(state => {
    localStorage.setItem('logo', state.logo.logo)
    return state.logo.logo
  })
  useEffect(() => {
    console.log('image logo',image)
    setLogoImg(image)
  }, [image])
  return (
    <div style={{ textAlign: "center" }}>
    {logoImg &&  <img
        src={logoImg}
        alt="logo"
        height="170px"
        style={{ margin: "23px auto", display: "block" }}
      />}
      <h2 style={{ textAlign: "center", fontSize: "25px", marginTop: "20px" }}>
        You successfully registered.
      </h2>
      <h6 className="mt-3">
        To login click here. <Link to={"/"}>Login</Link>
      </h6>
    </div>
  );
};

export default RegisterSuccess;
