import http from "../httpService";
import { apiUrl, api } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const getCurriculumList = () => {
  return http.get(apiUrl + "/getAllCurriculum", {
    headers: headers,
  });
};

export const getSubjectsByCur = (payload) => {
  return http.post(apiUrl + "/getSubjectsByCur", payload, {
    headers: headers,
  });
};

export const getTopicByCurSub = (payload) => {
  return http.post(apiUrl + "/getTopicByCurSub", payload, {
    headers: headers,
  });
};

export const getAllChapters = (payload) => {
  return http.post(apiUrl + "/getAllChapters", payload, {
    headers: headers,
  });
};
export const getInactiveUser = (payload) => {
  return http.get(api + "/getInactiveUser", {
    headers: headers,
  })
};
export const postActiveUser = (payload) => {
  return http.post(api + "/activateUser", payload, {
    headers: headers,
  })
}

export const postRejectUser = (payload) => {
  return http.post(api + "/rejectUser", payload, {
    headers: headers,
  })
}
export const getUserInfo = (payload) => {
  return http.get(api + "/getUserprofile/" + payload, {
    headers: headers,
  })
}

export const getLogo = () => {
  return http.get(api + "/getImage/logo", {
    headers: headers,
  })
}

export const updateImage = (payload) => {
  return http.post(api + "/updateImage", payload, {
    headers: headers,
  })
}
export const getAdminMeet = () => {
  return http.get(api + "/getMeeting", {
    headers: headers,
  })
}