export default function HrModule() {
  return (
    <div className="container">
      <h2>HR Module</h2>
      <div class="input-group row col-12   mb-2 ">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "40%" }}
            />
          </div>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Search Staff/roles"
        />
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>staff/ roles</th>
                  <th>issues/complaints</th>
                  <th> feedback discussion</th>
                  <th>Payment</th>
                  <th>Planing and Event Management</th>
                  <th>Organization Management</th>
                  <th>Alerts and Notification</th>
                </tr>
              </thead>
              <tr>
                <td>Student</td>
                <td>No issues</td>
                <td>Good</td>
                <td>Done</td>
                <td>
                  Event Planning consists of coordinating every detail of
                  meetings and conventions
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Student</td>
                <td>No issues</td>
                <td>Good</td>
                <td>Done</td>
                <td>
                  Event Planning consists of coordinating every detail of
                  meetings and conventions
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Student</td>
                <td>No issues</td>
                <td>Good</td>
                <td>Done</td>
                <td>
                  Event Planning consists of coordinating every detail of
                  meetings and conventions
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Student</td>
                <td>No issues</td>
                <td>Good</td>
                <td>Done</td>
                <td>
                  Event Planning consists of coordinating every detail of
                  meetings and conventions
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Student</td>
                <td>No issues</td>
                <td>Good</td>
                <td>Done</td>
                <td>
                  Event Planning consists of coordinating every detail of
                  meetings and conventions
                </td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
