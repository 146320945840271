import React, { Component } from "react";
import LoadingModal from "../../components/Share/LoadingModal";
import Question from "../../components/admin/question/question";
import AddQuestion from "../../components/admin/question/addQuestion";
import ViewQuestion from "../../components/admin/question/viewQuestion";
import parse from "html-react-parser";

import {
  saveQuestion,
  getQuestion,
  imageUpload
} from "../../services/Admin/questionService";

import { getCurriculumCodeFun } from "../../utils/utils";
import {
  getAllCurriculumList,
  getAllSubjectList,
  getAllTopicsList,
  getAllChaptersList,

} from "../../components/Share/GetCurriculumDetails";

import { userRole, userID } from "../../services/Authentication/AuthService";

import { questionValues } from "../../constants/AdminInitialValues";
class QuestionsContainer extends Component {
  state = {
    mode: "",
    search: '',
    curriculumList: [],
    subjectsList: [],
    topicsList: [],
    chaptersList: [],
    questionsList: [],
    questionsListAll: [],
    questionsIndividual: [],
    questionValues: questionValues,
    curriculumCode: "",
    question: "",
    questionError: "",
    setUpImg: [],
    setImageURL: [],
    isLoading: false,

  };


  loadDefaultValues = () => {
    const pathName = this.props.match.path;
    if (pathName === "/admin/add-question") {
      this.setState({ mode: "Add" });
    } else if (pathName === "/admin/edit-question/:id") {
      this.setState({ mode: "Edit" });
    } else if (pathName === "/admin/view-question/:id") {
      this.setState({ mode: "View" });
    } else if (pathName === "/admin/question") {
      this.setState({ mode: "List" });
    }
  };

  handleFileChange = (event) => {
    this.state.setUpImg.push({
      selectedFile: event.target.files[0],
    })
    console.log(this.state.setUpImg);
  };

  handleInput = async ({ currentTarget: input }, name) => {
    const data = { ...this.state.questionValues };
    const { options, selectedIndex } = input;
    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;
      data[input.name] = optValue;
    } else {
      data[input.name] = input.value;
    }

    if (name === "curriculum") {
      data.subject = "";
      data.topic = "";
      data.chapter = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      let subjectsList = await getAllSubjectList(curriculumCodeValue);
      this.setState({ subjectsList, curriculumCode: curriculumCodeValue });
    }

    if (name === "subject") {
      data.topic = "";
      data.chapter = "";
      let topicsList = await getAllTopicsList(
        this.state.curriculumCode,
        input.value
      );
      this.setState({ topicsList });
    }

    if (name === "topic") {
      let chaptersList = await getAllChaptersList(
        this.state.curriculumCode,
        data.subject,
        input.value
      );
      this.setState({ chaptersList });
    }

    this.setState({ questionValues: data });
  };
  handleInput1 = async (input, name) => {
    console.log('in--', input, name);
    const data = { ...this.state.questionValues };
    if (name) {
      data[name] = input;
    }

    if (name === "curriculum") {
      data.subject = "";
      data.topic = "";
      data.chapter = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input
      );
      data.curriculumCode = curriculumCodeValue
      let subjectsList = await getAllSubjectList(curriculumCodeValue);
      this.setState({ subjectsList, curriculumCode: curriculumCodeValue });
    }

    if (name === "subject") {
      data.topic = "";
      data.chapter = "";
      let topicsList = await getAllTopicsList(
        this.state.curriculumCode,
        input
      );
      this.setState({ topicsList });
    }

    if (name === "topic") {
      let chaptersList = await getAllChaptersList(
        this.state.curriculumCode,
        data.subject,
        input
      );
      this.setState({ chaptersList });
    }

    this.setState({ questionValues: data });
  };
  handleQuestionName = (data) => {
    if (data) {
      this.setState({ question: data, questionError: false });
    } else {
      this.setState({ questionError: true });
    }
  };

  getQuestionList = async () => {
    const payload = {
      query: {},
    };
    getQuestion(payload)
      .then((res) => {
        this.setState({ questionsList: res.data.data, questionsListAll: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Get the individual subject details
  getIndividualQuestion = async () => {
    const questionId = this.props.match.params.id;
    const payload = {
      query: {
        questionID: questionId,
        role: userRole(),
      },
    };
    return getQuestion(payload)
      .then((res) => {
        this.setState({ questionsIndividual: res.data.data[0] });
        return res.data.data[0];
      })
      .catch((err) => {
        //console.log("err", err);
        return [];
      });
  };

  editQuestion = async () => {
    let dataSingle = await this.getIndividualQuestion();
    const data = this.state.questionValues;
    data.curriculum = dataSingle.curriculum;
    data.subject = dataSingle.subject;
    data.topic = dataSingle.topic;
    data.chapter = dataSingle.chapter;
    data.question = dataSingle.question;
    data.answer1 = dataSingle.answer1;
    data.answer2 = dataSingle.answer2;
    data.answer3 = dataSingle.answer3;
    data.correctanswer = dataSingle.correctanswer;
    data.questionmarks = dataSingle.questionmarks;
    data.questionType = dataSingle.questionType;
    data.curriculumCode = dataSingle.curriculumCode;
    this.setState({
      questionValues: data,
      curriculumCode: dataSingle.curriculumCode,
      question: dataSingle.question,
    });
  };

  handleSubmit = async (formik) => {

    console.log(String(formik.values.questionType));
    const questionId = this.props.match.params.id;
    const payload = formik.values;
    payload.curriculumCode = this.state.curriculumCode;
    payload.question = this.state.question;
    payload.role = userRole();
    payload.createDate = new Date();
    payload.createdBy = userID();
    payload.questionID = questionId;
    console.log(formik, "datadatda");
    if (this.state.question === "") {
      this.setState({ questionError: true });
    }
    if (String(formik.values.questionType) === "Text") {
      if (formik.isValid && this.state.question !== "") {
        saveQuestion(payload, questionId)
          .then((res) => {
            this.props.history.push("/admin/question");
          })
      }
    } else {
      const data = new FormData();
      // if (this.state.selectedFile) 
      this.setState({ isLoading: true });
      let i=0;
      this.state.setUpImg.map((e) => {
        if(i<4){
        data.append(
          "uploadImages",
          e.selectedFile,
          e.selectedFile.name
        );
        }
        i=i+1;
        console.log(e);
      });
      let headers = {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      };
      imageUpload(data, headers)
        .then((response) => {
          if (200 === response.status) {
            // If file size is larger than expected.
            if (response.data.error) {
              if ("LIMIT_FILE_SIZE" === response.data.error.code) {
                console.log("Max size: 2MB -----------------");
                // this.ocShowAlert( 'Max size: 2MB', 'red' );
              } else {
                console.log(
                  "response Data ---------------",
                  response.data.error
                );
                // If not the given file type
                // this.ocShowAlert( response.data.error, 'red' );
              }
            } else {
              let fileData = response.data;
              let imagelocation = fileData.locationArray;
              if(imagelocation !== []){
              console.log(fileData.filesArray[0].key, "dataddd");
              payload.image_correctanswer.fileName = String(fileData.filesArray[0].key)
              payload.image_answer1.fileName = String(fileData.filesArray[1].key)
              payload.image_answer2.fileName = String(fileData.filesArray[2].key)
              payload.image_answer3.fileName = String(fileData.filesArray[3].key)
              payload.image_correctanswer.location = String(imagelocation[0]);
              payload.image_answer1.location = String(imagelocation[1]);
              payload.image_answer2.location = String(imagelocation[2]);
              payload.image_answer3.location = String(imagelocation[3]);
              this.state.setImageURL = imagelocation
            }
              console.log(payload, data);
              if (formik.isValid && this.state.question !== "") {
                saveQuestion(payload, questionId)
                  .then((res) => {
                    this.props.history.push("/admin/question");
                  })

              }
            }
          }
        })

      console.log(payload);

    }
  };

  closeViewQuestion = () => {
    this.props.history.push("/admin/question");
  };

  async componentDidMount() {

    await this.loadDefaultValues();
    const { mode } = this.state;
    const { setImageURL } = this.state;
    if (mode === "Add") {
      let data = await getAllCurriculumList();
      this.setState({
        curriculumList: data,
        questionValues: {
          curriculum: "",
          curriculumCode: "",
          subject: "",
          topic: "",
          chapter: "",
          correctanswer: "",
          image_correctanswer: {
            fileName: "",
            location: ""
          },
          answer1: "",
          image_answer1: {
            fileName: "",
            location: ""
          },
          answer2: "",
          image_answer2: {
            fileName: "",
            location: ""
          },
          answer3: "",
          image_answer3: {
            fileName: "",
            location: ""
          },
          questionmarks: "",
        },
      });
    } else if (mode === "Edit") {
      this.editQuestion();
      const data = this.state.questionValues;
      let curriculumList = await getAllCurriculumList();
      let subjectsList = await getAllSubjectList(data.curriculumCode);
      let topicsList = await getAllTopicsList(
        data.curriculumCode,
        data.subject
      );
      let chaptersList = await getAllChaptersList(
        data.curriculumCode,
        data.subject,
        data.topic
      );
      this.setState({
        curriculumList,
        subjectsList,
        topicsList,
        chaptersList,
        questionValues: data,
      });
    } else if (mode === "List") {
      this.getQuestionList();
    } else if (mode === "View") {
      await this.getIndividualQuestion();
    }
  }
  searchQuestion = (event) => {
    this.setState({ search: event.target.value })
    let val = String(event.target.value).toLowerCase()
    if (!val) {
      this.getQuestionList()
      return
    }
    let filter = this.state.questionsListAll.filter(q => {
      if (q.question.toLowerCase().indexOf(val) !== -1) {
        return q
      }
      else if (q.curriculum.toLowerCase().indexOf(val) !== -1) {
        return q
      }
      else if (q.subject.toLowerCase().indexOf(val) !== -1) {
        return q
      }

    })
    // console.log('filter-' + JSON.stringify(filter))
    this.setState({ questionsList: [...filter] })

  }

  uploadFileChange = (event) => {
    this.setState({ setUpImg: event.target.files[0] })
  }
  render() {
    const {
      mode,
      curriculumList,
      subjectsList,
      topicsList,
      chaptersList,
      questionValues,
      questionsList,
      questionsIndividual,
      questionError,
      uploadFileChange
    } = this.state;
    let path = this.props.match.path;
    return (
      <React.Fragment>
        {path === "/admin/question" && (
          <Question questionsList={questionsList} search={this.state.search} searchQuestion={this.searchQuestion} />
        )}

        {(path === "/admin/add-question" ||
          path === "/admin/edit-question/:id") && (
            <AddQuestion
              mode={mode}
              curriculumList={curriculumList}
              subjectsList={subjectsList}
              topicsList={topicsList}
              chaptersList={chaptersList}
              questionsList={questionsList}
              questionValues={questionValues}
              questionError={questionError}
              handleInput={this.handleInput}
              handleSubmit={this.handleSubmit}
              handleQuestionName={this.handleQuestionName}
              handleInput1={this.handleInput1}
              uploadFileChange={uploadFileChange}
              handleFileChange={this.handleFileChange}
            />
          )}
        {path === "/admin/view-question/:id" && (
          <ViewQuestion
            questionsIndividual={questionsIndividual}
            closeViewQuestion={this.closeViewQuestion}
            handleFileChange={this.handleFileChange}
          />
        )}
        <LoadingModal visible={this.state.isLoading} />

      </React.Fragment>
    );
  }
}

export default QuestionsContainer;
