import AddOragnizationContainer from "./AddOragnizationContainer";
import ClassroomSchedulingContainer from "./ClassroomSchedulingContainer";
import OragnizationContainer from "./OragnizationContainer";
import PracticalSessionContainer from "./PracticalSessionContainer";
export default [
  {
    path: "Oragnization",
    component: OragnizationContainer,
  },
  {
    path: "AddOragnization",
    component: AddOragnizationContainer,
  },
  {
    path: "PracticalSession",
    component: PracticalSessionContainer,
  },
  {
    path: "ClassroomScheduling",
    component: ClassroomSchedulingContainer,
  },
];
