import React, { Component } from "react";
import Oragnization from "../../components/OrgAdmin/Oragnization/Oragnization";
import { userID } from "../../services/Authentication/AuthService";

class OragnizationContainer extends Component {
  state = {};

  componentDidMount() {}
  render() {

    return (
      <React.Fragment>
        <Oragnization />
      </React.Fragment>
    );
  }
}
export default OragnizationContainer;
