import React from "react";
import { Link } from "react-router-dom";


const AddOragnization = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <h1 className="form-heading">Add Organization</h1>
            <div className="add-btn-div">
              <Link
                to={`/OrgAdmin/Oragnization`}
                className="btn btn-sm btn-danger"
              >
                Cancel
              </Link>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="card">
              <div className="card-body">
                <p>Organization Name</p>
                <input
                  type="text"
                  name="organizationName"
                  label="Organization Name"
                  className="w-100"
                />
                <p className="mt-3">Organization Code</p>
                <input
                  type="text"
                  name="organizationName"
                  label="Organization Name"
                  className="w-100"
                />
                <p className="mt-3">Organization Address</p>
                <input
                  type="text"
                  name="organizationName"
                  label="Organization Name"
                  className="w-100"
                />
                <p className="mt-3">Organization Image</p>
                <input
                  type="file"
                  name="organizationName"
                  label="Organization Name"
                  className="w-100"
                />

                <div className="d-flex justify-content-center align-items-center">
                  <div className="logo d-flex justify-content-center align-items-center">
                    <p className="text-center"> Select A Image</p>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary mt-2">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddOragnization;
