import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import routerService from "./StudentsRoutes";

// import Sidebar from "../../components/Students/Sidebar/Sidebar";
import Topbar from "../../components/Students/Topbar/Topbar";

import "../../styles/student.css";

class StudentLayout extends Component {
  render() {
    const { location, match } = this.props;
    return (
      <React.Fragment>

        {location.pathname === "/student/login" ||
          location.pathname === "/student/register" ||
          location.pathname === "/student/register-success" ||
          location.pathname.includes("/student/test-view") ||
          location.pathname.includes("/student/test-result") ? (
          <React.Fragment>

            {routerService &&
              routerService.map((route, key) => {
                return (
                  <Route
                    key={key}
                    path={`${match.url}/${route.path}`}
                    component={route.component}
                  />
                );
              })}
          </React.Fragment>
        ) : (
          <div>


            <Topbar />

            {routerService &&
              routerService.map((route, key) => {
                return (
                  <Route
                    key={key}
                    path={`${match.url}/${route.path}`}
                    component={route.component}
                  />
                );
              })}
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default withRouter(StudentLayout);
