import React from "react";
import parse from "html-react-parser";
import moment from "moment";

const ViewCurriculm = ({ curriculumListIndividual, closeViewCurriculum, deleteEntireCurriculum }) => {
  const data = curriculumListIndividual;
  return (
    <div className="row justify-content-center">
      <div className="closePage">
        <h3>View Curriculum</h3>
        <button className="btn btn-sm btn-danger " onClick={deleteEntireCurriculum} >
          Delete
        </button>
        <button className="btn btn-sm btn-secondary mr-2" onClick={closeViewCurriculum}>
          Close
        </button>
      </div>
      <div className="col-md-10">
        <div className="card-deck">
          <div className="card">
            <div className="card-body">
              <div className="text-view">
                <label>Organization</label>
                <span>
                  {data.organizationName ? data.organizationName : ""}
                </span>
              </div>
              <div className="text-view">
                <label>Organization Code</label>
                <span>
                  {data.organizationCode ? data.organizationCode : ""}
                </span>
              </div>
              <div className="text-view">
                <label>Curriculum Name</label>
                <span>{data.curriculum ? data.curriculum : ""}</span>
              </div>
              <div className="text-view">
                <label>Curriculum Award</label>
                <span>{data.curriculumAward ? data.curriculumAward : ""}</span>
              </div>
              <div className="text-view">
                <label>Curriculum Code</label>
                <span>{data.curriculumCode ? data.curriculumCode : ""}</span>
              </div>
              <div className="text-view">
                <label>Short Name</label>
                <span>{data.shortName ? data.shortName : ""}</span>
              </div>
              <div className="text-view">
                <label>Long Name</label>
                <span>{data.longName ? data.longName : ""}</span>
              </div>
              <div className="clearfix"></div>
              {/* <div className="text-view">
                <label>
                  Created Date <small>(DD-MM-YYYY)</small>
                </label>
                <span>
                  {data.createDate
                    ? moment(data.createDate).format("MM-DD-YYYY")
                    : ""}
                </span>
              </div> */}
              <div className="text-view">
                <label>
                  Effective Date <small>(DD-MM-YYYY)</small>
                </label>
                <span>
                  {data.effectiveDate
                    ? moment(data.effectiveDate).format("MM-DD-YYYY")
                    : ""}
                </span>
              </div>
              <div className="text-view">
                <label>
                  Start Date <small>(DD-MM-YYYY)</small>
                </label>
                <span>
                  {data.startDate
                    ? moment(data.startDate).format("MM-DD-YYYY")
                    : ""}
                </span>
              </div>
              <div className="text-view">
                <label>
                  End Date <small>(DD-MM-YYYY)</small>
                </label>
                <span>
                  {data.endDate
                    ? moment(data.endDate).format("MM-DD-YYYY")
                    : ""}
                </span>
              </div>
              <div className="text-view">
                <label>
                  Duration <small>(in Years)</small>
                </label>
                <span>{data.duration ? data.duration : ""}</span>
              </div>
              <div className="text-view">
                <label>Eligibility</label>
                <span>{data.eligibility ? parse(data.eligibility) : ""}</span>
              </div>
              <div className="text-view">
                <label>Pass Criteria</label>
                <span>{data.passCriteria ? parse(data.passCriteria) : ""}</span>
              </div>
              <div className="text-view">
                <label>Price</label>
                <span>{data.price ? data.price : ""}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-12 d-flex justify-content-center align-items-center">
        <div className="logo d-flex justify-content-center align-items-center">
          {! data.location && <p>No Image Available</p>}
          {data.location && <img className="imageLogo" src={data.location} />}
        </div>
      </div> */}
    </div>
  );
};

export default ViewCurriculm;
