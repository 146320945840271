import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const createRole = (payload) => {
  return http.post(apiUrl + "/createRole", payload, {
    headers: headers,
  });
};

export const getRolesList = (payload) => {
  return http.post(apiUrl + "/getRolesList", payload, {
    headers: headers,
  });
};

export function saveStaff(payload) {
  if (!payload.userID) {
    return http.post(apiUrl + "/createStaff", payload, {
      headers: headers,
    });
  } else {
    delete payload._id;
    return http.post(apiUrl + "/updateStaff", payload, {
      headers: headers,
    });
  }
}

export const getStaffList = (payload) => {
  return http.post(apiUrl + "/getStaffList", payload, {
    headers: headers,
  });
};
export const getStatusChange = (payload) => {
  return http.post(apiUrl + "/inactiveUser", payload, {
    headers: headers,
  });
};
export const deleteStaff = (payload) => {
  return http.post(apiUrl + "/deleteUser/"+ payload, {
    headers: headers,
  });
};
