import React from "react";

const Input = ({ name, label, error, ...rest  }) => {
  return (
    <div className="form-group">
      <label className="label-heading" htmlFor={name}>
        {label}
      </label>
      <input {...rest} name={name} id={name} className="form-control" required/>
    </div>
  );
};

export default Input;
