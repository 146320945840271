import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

class StudentEventInfoModal extends Component {
  state = {
    modalVisible: false,
  };

  constructor(props) {
    super(props);
    this.props.childRef(this);
  }

  handleClose = () => {
    this.setState({ modalVisible: false });
  };

  handleEventShow = () => {
    this.setState({ modalVisible: true });
  };

  render() {
    const e = this.props.eventInfo;
    return (
      <>
        <Modal
          size="lg"
          show={this.state.modalVisible}
          onHide={this.handleClose}
          className="eventModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{e.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="text-view">
              <label>Curriculum</label>
              <span>{e.curriculum}</span>
            </div>
            <div class="text-view">
              <label>Subject</label>
              <span>{e.subject}</span>
            </div>
            <div class="text-view">
              <label>Topics</label>
              <span>{e.topics}</span>
            </div>
            <div class="text-view">
              <label>Description</label>
              <span>{e.desc}</span>
            </div>
            <div class="text-view">
              <label>Start</label>
              <span>{moment(e.start).format("YYYY-MM-DD HH:mm")}</span>
            </div>
            <div class="text-view">
              <label>End</label>
              <span>{moment(e.end).format("YYYY-MM-DD HH:mm")}</span>
            </div>
            <div class="text-view">
              <label>Link join</label>
              <span>
                <button className="btn btn-info" onClick={() => window.open(e.link)}>
                  Click to go to the class
                </button>
              </span>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default StudentEventInfoModal;
