import React, { useState, useEffect, Fragment } from 'react'
import styles from './teacherSession.module.css';
import FlatList from 'flatlist-react';
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment"

const TeacherSessionComp = ({ sessionList, statusChange, loading, changeDateHandler, changeTimeHandler }) => {
    const [value, setValue] = useState(null)
    const [flat, setFlat] = useState()
    useEffect(() => {
        if (!loading) {
            setValue(null)
        }
    }, [loading])

    return <div className="container">
        <div className="row justify-content-center">
            <div className="col-md-12">
                <h1 className="form-heading">Session</h1>
                <FlatList
                    list={sessionList}
                    renderWhenEmpty={() => <div>No session Found!</div>}
                    renderItem={(q, index) => {
                        let start, startT, end, endT, len;
                        try {
                            start =moment.parseZone(q.start).local().format('YYYY-MM-DD');
                            startT = moment.parseZone(q.start).local().format('HH:mm');
                            end = moment.parseZone(q.end).local().format('YYYY-MM-DD');
                            endT = moment.parseZone(q.end).local().format('HH:mm');
                            len = q.student.length
                        } catch (err) {

                        }
                        let check = new Date(q.start).getTime()
                        console.log('hcelk', check)
                        let timePassed = false
                        if (check < Date.now()) {
                            console.log('---------')
                            timePassed = true
                        }
                        return <div className="card p-3 mb-3">
                            <h5 className="card-title mb-0">  <img className='mr-3' src="https://img.icons8.com/ios-glyphs/30/000000/arrow.png" />
                                ID: {q.sessionID}- {q.curriculum} - {q.subject} - {q.topics}   <br /> <hr /></h5>
                            <div className="card-body row pt-0">
                                <div className="col-md-6">
                                    <p className={`mb-2 ${styles.head}`}>{q.title}</p>
                                    {q.desc && <p className={`mb-2 `}>{parse(q.desc)}</p>}

                                    <div className='row'>
                                        <> <div className="col-5 d-flex flex-column align-items-center">
                                            <p className=''>Start</p>

                                            <p className={`${styles.date}`}>{start}</p>
                                            <p className={`${styles.date} mt-2`}>{startT}</p>
                                        </div>
                                            <div className="col-5 d-flex flex-column align-items-center">
                                                <p>End</p>
                                                <p className={`${styles.date} `}>{end}</p>
                                                <p className={`${styles.date} mt-2`}>{endT}</p>
                                            </div></>
                                        {/* {q.status !== "ACCEPTED" && <> <div className="col-5 d-flex flex-column align-items-center">
                                            <p className=''>Start</p>
                                            <input type="date" onChange={(value) => changeDateHandler(value, index, 1)} value={start} className="form-control" placeholder="date" />
                                            <input type="time" onChange={value => changeTimeHandler(value, index, 1)} value={startT} className="form-control" placeholder="time" />

                                        </div>
                                            <div className="col-5 d-flex flex-column align-items-center">
                                                <p>End</p>
                                                <input type="date" onChange={(value) => changeDateHandler(value, index, 2)} value={end} className="form-control" placeholder="date" />
                                                <input type="time" onChange={value => changeTimeHandler(value, index, 2)} value={endT} className="form-control" placeholder="time" />

                                            </div></>} */}
                                    </div>

                                </div>
                                <div className="col-md-3">
                                    <p className={`mb-2 ${styles.head} text-center `}>Total Students</p>
                                    <p className={`${styles.num} `}>{len}</p>

                                </div>
                                <div className="col-md-3 text-center">
                                    {q.status === "PENDING" && timePassed && !loading && <>
                                        <p className={`mb-2 ${styles.head} text-center`}>Session date has passed</p>

                                    </>

                                    }
                                    {q.status === "PENDING" && !timePassed && !loading && <> <button
                                        className={`btn btn-danger ${styles.click}`}
                                        style={{ borderRadius: '20px' }}
                                        onClick={() => {
                                            statusChange(q, 'REJECTED')
                                            setValue(q.sessionID)
                                        }}
                                    >
                                        Cancel
                                    </button>
                                        <br />
                                        <button
                                            className={`btn btn-success ${styles.click}`}
                                            style={{ borderRadius: '20px' }}
                                            onClick={() => {
                                                statusChange(q, 'ACCEPTED')
                                                setValue(q.sessionID)
                                            }}
                                        >
                                            Accept
                                        </button> </>}

                                    {loading && value !== q.sessionID && <> <button
                                        className={`btn btn-danger ${styles.click}`}
                                        style={{ borderRadius: '20px' }}
                                        onClick={() => {
                                            statusChange(q, 'REJECTED')
                                            setValue(q.sessionID)
                                        }}
                                    >
                                        Cancel
                                    </button>
                                        <br />
                                        <button
                                            className={`btn btn-success ${styles.click}`}
                                            style={{ borderRadius: '20px' }}
                                            onClick={() => {
                                                statusChange(q, 'ACCEPTED')
                                                setValue(q.sessionID)
                                            }}
                                        >
                                            Accept
                                        </button> </>}

                                    {q.status === "ACCEPTED" && !loading && <>
                                        <p className={`mb-2 ${styles.head} text-center`}>{q.status}</p>
                                        <button
                                            className={`btn btn-info ${styles.click}`}
                                            style={{ borderRadius: '20px' }}
                                            onClick={() => window.open(q.link)}
                                        >
                                            <a   >Join</a>

                                        </button>

                                    </>}

                                    {q.status === "REJECTED" && !loading && <>
                                        <p className={`mb-2 ${styles.head} text-center`}>{q.status}</p>
                                    </>}
                                    {loading && value === q.sessionID && <button className="btn btn-sm btn-primary "  >
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="sr-only">Loading...</span>
                                    </button>}
                                </div>
                            </div>
                        </div>
                    }}
                />

            </div>
        </div>
    </div >
}


export default TeacherSessionComp