import React from "react";
import { Formik, Form } from "formik";
import { staffValidation } from "../../../validations/AdminValidations";
import FormikControl from "../../../common/Formik/FormikControl";
import { curriculumListFun, subjectListFun } from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import { subjectValues } from "../../../constants/AdminInitialValues";

const AddStaff = (props) => {
  const {
    curriculumList,
    subjectsList,
    staffValues,
    mode,
    handleSubmitStaff,
    rolesList,
    handleInput,
  } = props;

  let data = staffValues;
  const history = useHistory();
  let calll ='Doing The Work'
  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);
  console.log('dadadada', data.subject)
  console.log('ds', subjectsList);
  const roleOptions = rolesList && rolesList.length > 0 ? rolesList : [];
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="d-flex flex-row">
            <h1 className="form-heading">{mode} Staff</h1>
            <button
              className="btn btn-sm btn-danger mb-4"
              onClick={() => history.goBack()}
            >
              Close
            </button>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={staffValues}
                validationSchema={staffValidation}
                validateOnMount
                enableReinitialize
              >
                {(formik) => {
                  console.log('dada')
                  const { handleChange } = formik;
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-md-6">
                          <FormikControl
                            control="input"
                            type="text"
                            label="First Name"
                            id="firstName"
                            name="firstName"
                          />
                        </div>

                        <div className="col-md-6">
                          <FormikControl
                            control="input"
                            type="text"
                            label="Last Name"
                            name="lastName"
                          />
                        </div>

                        <div className="col-md-6">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobile"
                            label="Mobile Number"
                          />
                        </div>

                        <div className="col-md-6">
                          <FormikControl
                            control="select"
                            label="Select Role"
                            optname="roleName"
                            name="role"
                            placeholder="Select Role"
                            options={roleOptions}
                            value={staffValues.role}
                          />
                        </div>

                        {formik.values.role === "teacher" && (
                          <React.Fragment>
                            <div className="col-md-6">
                              <FormikControl
                                control="select"
                                label="Curriculum Name"
                                optname="curriculum"
                                name="curriculum"
                                placeholder="Select Curriculum"
                                options={curriculumOptions}
                                value={data.curriculum}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInput(e, "curriculum", formik.values);
                                }}
                              />
                            </div>

                            <div className="col-md-6">
                              <FormikControl
                                control="select"
                                label="Subject Name"
                                optname="subject"
                                name="subject"
                                placeholder="Select Subject"
                                options={subjectOptions}
                                value={data.subject}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInput(e, "subject", formik.values);
                                }}
                              />
                            </div>
                          </React.Fragment>
                        )}

                        <div className="col-md-6">
                          <FormikControl
                            control="input"
                            type="text"
                            name="email"
                            label="Email ID"
                          />
                        </div>

                        <div className="col-md-6">
                          <FormikControl
                            control="textarea"
                            type="text"
                            name="address"
                            label="Address"
                          />
                        </div>

                        <div className="text-center col-md-12">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary mt-4"
                            onClick={() => handleSubmitStaff(formik)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaff;
