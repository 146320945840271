import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Routes from "./routes";

class App extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/" component={Routes} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
