import React, { Fragment } from 'react'
import styles from "../../Students/MyQueries/MyQueries.module.css";
import DisplayErrorMessage from '../../Share/DisplayErrorMessage';
import parse from "html-react-parser";

const QueriesList = props => {
    const { queriesOpen, statchange} = props
    console.log(queriesOpen.length > 0)
    if (queriesOpen && queriesOpen.length === 0) {
        return (
            <DisplayErrorMessage
                message="No Open queries found"
                errorSize="big"
            />
        );
    }

    return <Fragment> {queriesOpen.length > 0 &&
        queriesOpen.map(q => {
            // console.log('q-' + JSON.stringify(q))
            return <div className={`cardDiv ${styles.queryDiv}`} >
                <h5 title="Curriculum" className={styles.curriculum}>
                    <p>{q.curriculum}</p> {'-'}
                </h5>
                <div title="Subjet" className={styles.subject}>
                    <p>{q.subject}</p>{'-'}
                </div>
                <div title="Topic" className={styles.topic}>
                    <p>{q.topic}</p>{'-'}
                </div>
                <div title="Chapter" className={styles.chapter}>
                    <p>{q.chapter}</p>
                </div>

                <hr className={styles.hr} />
                {q.messages &&
                    q.messages.map((m) => {
                        return <p key={m.createdAt}>{parse(m.message)}</p>;
                    })}
                <div className="d-flex flex-row  justify-content-end">
                    <button
                        className="btn btn-success   mr-2"
                        onClick={() => statchange(1, q.chatID)}
                    // onClick={raiseQuery}
                    >
                        Open Query
                    </button>
                    <button
                        className="btn btn-danger   mr-0"
                    // onClick={raiseQuery}
                        onClick={() => statchange(2, q.chatID)}
                    >
                        Close Query
                    </button>
                </div>
            </div>
        })
    }
    </Fragment>
}
export default QueriesList