import React, { useState, useEffect, Fragment, } from "react";
import { useHistory, useLocation, useRouteMatch, useParams  } from 'react-router-dom';
import Verify from '../../components/verification/verify'
import ChangePassword from '../../components/verification/changePassword'
import EmailVerify from '../../components/verification/emailVerify'

const VerifyContainer = props => {
    const path = props.location.pathname
    const match = useRouteMatch();
    const params  = useParams()
   const sendPassEmail=props=>{

    }
    useEffect(() => {

    }, [])
    if (match.url === '/verification') {
        return <Fragment >
            <Verify />
        </Fragment>
    }
    if (match.url === '/change-password') {
        return <Fragment>
            <ChangePassword />
        </Fragment>
    }
    if (match.url === '/email-verification') {
        return <Fragment>
            <EmailVerify />
        </Fragment>
    }
    if (match.url === '/verify-sucess') {
        return <Fragment>
            <p>success</p>
        </Fragment>
    }
    return <Fragment>
        <p>page not found</p>
    </Fragment>
}


export default VerifyContainer