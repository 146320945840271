import React from "react";
import { Link } from "react-router-dom";


const Organization = ({searchCurr, search }) => {
  return (
    <React.Fragment>
      <div className="row">
        <h1 className="form-heading">Oragnization Admin Dashboard</h1>
        <div className="add-btn-div">
          <Link
            to={`/OrgAdmin/AddOragnization`}
            className="btn btn-sm btn-primary add-btn"
          >
            Add Organization
          </Link>
        </div>
        <div class="input-group row col-12   mb-2 ">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <img
                src="https://img.icons8.com/search"
                style={{ maxWidth: "40%" }}
              />
            </div>
          </div>
          <input
            type="text"
            value={search}
            onChange={(text) => searchCurr(text)}
            class="form-control"
            placeholder="Search Oragnization"
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Oragnization Name</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Jawaharlal Nehru Technological University Hyderabad</td>
                  <td>
                    <Link>
                      <button className="btn btn-sm btn-success mr-2">
                        Edit
                      </button>
                    </Link>
                    <Link>
                      <button className="btn btn-sm btn-info">View</button>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>Sri Krishna Devaraya University</td>
                  <td>
                    <Link>
                      <button className="btn btn-sm btn-success mr-2">
                        Edit
                      </button>
                    </Link>
                    <Link>
                      <button className="btn btn-sm btn-info">View</button>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>Jawaharlal Nehru Technological University Hyderabad</td>
                  <td>
                    <Link>
                      <button className="btn btn-sm btn-success mr-2">
                        Edit
                      </button>
                    </Link>
                    <Link>
                      <button className="btn btn-sm btn-info">View</button>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>Sri Krishna Devaraya University</td>
                  <td>
                    <Link>
                      <button className="btn btn-sm btn-success mr-2">
                        Edit
                      </button>
                    </Link>
                    <Link>
                      <button className="btn btn-sm btn-info">View</button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Organization;
