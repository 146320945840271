import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import routerService from "./VerifyRoutes";
class VerifyLayout extends Component {
    render() {
        const { location, match } = this.props;
        return (
            <React.Fragment>
                <React.Fragment>
                    <Switch>
                        {routerService &&
                            routerService.map((route, key) => {
                                return (
                                    <Route
                                        key={key}
                                        path={`${match.url}`}
                                        component={route.component}
                                    />
                                );
                            })}
                    </Switch>
                </React.Fragment>
            </React.Fragment>
        );
    }
}
export default withRouter(VerifyLayout);
