import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import moment from "moment";

import { sessionUpdate } from "../api";

import {
  userEmail,
  userID,
  userInfo,
  userRole,
} from "../../../services/Authentication/AuthService";
import { sessionStatus } from "../../../services/Student/SessionService";

class TeacherEventInfoModal extends Component {
  state = {
    modalVisible: false,
    isLoading: false,
  };

  constructor(props) {
    super(props);
    this.props.childRef(this);
  }

  handleClose = () => {
    this.setState({ modalVisible: false });
  };

  handleEventShow = () => {
    this.setState({ modalVisible: true });
  };
  statusChange = (data, status) => {
    const event = this.props.eventInfo;
    let startT = moment(event.end).format("YYYY-MM-DD HH:mm")
    //returnmoment(e.end).format("YYYY-MM-DD HH:mm")

    this.setState({ isLoading: true });

    let payload = {
      sessionID: event.sessionID,
      title: event.title,
      start: moment(event.start).format("YYYY-MM-DD HH:mm"),

      end: moment(event.end).format("YYYY-MM-DD HH:mm"),
      studentEmails: data.studentEmails,
      status: status,
      // email: 'support@advstechnosolutions.onmicrosoft.com'
      email: userEmail()
    }
    console.log('sessss upda-' + userID())
    if (status === 'REJECTED') {
      payload['requestedTeacher'] = event.requestedTeacher.filter(el => {

        return Number(el.userID) !== Number(userID())
      })
      payload['rejectedTeacher'] = event.requestedTeacher.filter(el => {

        return Number(el.userID) === Number(userID())
      })
    } else if (status === 'ACCEPTED') {
      payload['requestedTeacher'] = event.requestedTeacher.filter(el => {

        return Number(el.userID) === Number(userID())
      })
    }

    sessionStatus(payload).then(res => {
      if (res.status === 200 && res.data.status) {
        //getSession()
        this.setState({ modalVisible: false, isLoading: false });
        window.location.reload()
      } else {
        if (res.data.error) {

          alert(res.data.error)
        } else {
          alert(res.data.message)
        }

      }
    }).catch(err => {

    })
  }
  teacherAcceptEvent = () => {
    const event = this.props.eventInfo;
    event.status = "ACCEPTED";
    event.email = userEmail();
    console.log('user', userInfo())
    event.acceptedTeacher = event.acceptedTeacher ? event.acceptedTeacher : [];

    event.acceptedTeacher.push(userInfo());
    event.role = userRole();
    this.setState({ isLoading: true });

    sessionUpdate(event)
      .then((res) => {
        this.setState({ modalVisible: false, isLoading: false });
        window.location.reload()
        this.props.history.push("/teacher/calender");
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ modalVisible: false, isLoading: false });

      });
  };

  teacherRejectEvent = () => {
    const event = this.props.eventInfo;
    event.status = "REJECTED";
    event.email = userEmail();
    event.role = userRole();
    event.rejectedTeacher = event.rejectedTeacher ? event.rejectedTeacher : [];
    // console.log('user',info)
    event.rejectedTeacher.push(userInfo());
    this.setState({ isLoading: true });
    console.log('rejkecty')
    sessionUpdate(event)
      .then((res) => {
        this.setState({ modalVisible: false, isLoading: false });
        window.location.reload()

        this.props.history.push("/teacher/calender");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  render() {
    const e = this.props.eventInfo;
    let timee = new Date(e.start).getTime()
    let timePassed = false
    if (timee < Date.now()) {
      timePassed = true
    }
    return (
      <>
        <Modal
          size="lg"
          show={this.state.modalVisible}
          onHide={this.handleClose}
          className="eventModal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{e.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-view">
              <label>Curriculum</label>
              <span>{e.curriculum}</span>
            </div>
            <div className="text-view">
              <label>Subject</label>
              <span>{e.subject}</span>
            </div>
            <div className="text-view">
              <label>Topics</label>
              <span>{e.topics}</span>
            </div>
            <div className="text-view">
              <label>Description</label>
              <span>{e.desc}</span>
            </div>
            <div className="text-view">
              <label>Start</label>
              <span>{moment(e.start).format("YYYY-MM-DD HH:mm")}</span>
            </div>
            <div className="text-view">
              <label>End</label>
              <span>{moment(e.end).format("YYYY-MM-DD HH:mm")}</span>
            </div>
            <div className="text-view">
              <label>Students</label>
              <span>
                <ol>
                  {e.studentEmails &&
                    e.studentEmails.map((item, i) => <li>{item}</li>)}
                </ol>
              </span>
            </div>
            <div className="text-view">
              <label>Status</label>
              <span>{e.status}</span>
            </div>
            <div className="col-md-12 text-center mt-3">
              {this.state.isLoading && <Spinner animation="border" role="status">
                <span className="visually-hidden"></span>
              </Spinner>}
            </div>
            {(e.status === "PENDING" || e.status === "REJECTED") && timePassed && !this.state.isLoading && <div className="text-view">
              <span>Session date has passed</span>
            </div>}

            {(e.status === "PENDING" || e.status === "REJECTED") && !timePassed && !this.state.isLoading && (
              <div className="col-md-12 text-center mt-3">
                <button
                  onClick={() => this.statusChange(e, 'ACCEPTED')}
                  className="btn btn-primary btn-sm mr-2"
                >
                  Accept
                </button>
                <button
                  onClick={() => this.statusChange(e, 'REJECTED')}
                  className="btn btn-danger btn-sm"
                >
                  Reject
                </button>
              </div>
            )}

            {e.status === "ACCEPTED" && !this.state.isLoading && (
              <div className="text-view">
                <label>Link join</label>
                <span>

                  <button className="btn btn-info" onClick={() => window.open(e.link)}>
                    Click to go to the class
                  </button>
                </span>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default TeacherEventInfoModal;
