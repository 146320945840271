import React from "react";

import HrModule from "../../components/admin/HrModule/HrModule";

export default function 
() {
  return (
    <div>
      <React.Fragment>
        <HrModule />
      </React.Fragment>
    </div>
  );
}
