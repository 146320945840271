import React, { useState, useEffect, Fragment } from 'react'
import styles from './adminSession.module.css';
import FlatList from 'flatlist-react';
import Toggle from 'react-toggle'
import { Modal, Button } from 'react-bootstrap';
import './toogle.css'
import { indigo } from '@material-ui/core/colors';
import Select from '../common/select'
import { getAllTeachers, inviteTeacher } from '../../Schedule/api';
import { teacherListFun } from '../../../utils/utils';
const AdminSessionComp = ({ changeTime, removeTeacher, accTeacherList, addTeacher, sendMessage, handleClose, session, saveSession, teacherList, show, sendInvite, sessionList, statusChange, toogle, setToogle, getSession }) => {



    const setTeacherArr = (value) => {
        console.log(value)
    }
    console.log('accTeacherList' + JSON.stringify(accTeacherList));
    return <div className="container">
        <div className="row justify-content-center">
            <div className="col-md-12">
                <h1 className="form-heading">Session</h1>
                <Toggle
                    id='cheese-status'
                    defaultChecked={toogle}
                    onChange={() => setToogle(state => !state)}
                />
                <label className='ml-2' htmlFor='cheese-status '>Show All Session</label>
                <FlatList
                    list={sessionList}

                    renderItem={q => {
                        if (q.status === 'ACCEPTED') {
                            return;
                        }
                        // if (!toogle && q.student.length < 10) {
                        //     return;
                        // }
                        let start, startT, end, endT, len;
                        start = q.start.split(' ')[0]
                        startT = q.start.split(' ')[1]
                        end = q.end.split(' ')[0]
                        endT = q.end.split(' ')[1]
                        len = q.student.length
                        return <div class="card p-3">
                            <h5 class="card-title mb-0">  <img className='mr-3' src="https://img.icons8.com/ios-glyphs/30/000000/arrow.png" />
                                {q.curriculum} - {q.subject} - {q.topics} {q.sessionID}  <br /> <hr /></h5>
                            <div class="card-body row pt-0">
                                <div className="col-md-6">
                                    <p className={`mb-2 ${styles.head}`}>{q.title}</p>
                                    <div className='row'>
                                        <div className="col-4 d-flex flex-column align-items-center">
                                            <p className=''>Start</p>
                                            <p className={`${styles.date}`}>{start}</p>
                                            <p className={`${styles.date} mt-2`}>{startT}</p>
                                        </div>
                                        <div className="col-4 d-flex flex-column align-items-center">
                                            <p>End</p>
                                            <p className={`${styles.date} `}>{end}</p>
                                            <p className={`${styles.date} mt-2`}>{endT}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-3">
                                    <p className={`mb-2 ${styles.head} text-center `}>Total Students</p>
                                    <p className={`${styles.num} `}>{len}</p>

                                </div>
                                <div className="col-md-3 text-center">
                                    {/* <button
                                        className={`btn btn-danger ${styles.click}`}
                                        style={{ borderRadius: '20px' }}
                                    >
                                        Cancel
                                    </button> */}
                                    <br />
                                    <button
                                        className={`btn btn-success ${styles.click}`}
                                        style={{ borderRadius: '20px', height: '30px' }}
                                        onClick={() => saveSession(q)}
                                    >
                                        Accept
                                    </button>
                                </div>
                            </div>
                        </div>
                    }}
                />
            </div>
        </div>

        <Modal show={show} onHide={handleClose}>
            {/* <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <div className="text-view mb-3 ">
                    <label>Curriculum</label>
                    <span>{session.curriculumCode}</span>
                </div>
                <div className="text-view mb-3">
                    <label>subject</label>
                    <span>{session.subject}</span>
                </div>
                {/* <div className="text-view">
                    <label>teacher</label>
                    <select
                        name='teacher'
                        // optname={optname}
                        id='teacher'
                        value={teacherList}
                        onChange={(event) => setTeacherArr(event.target.value)}
                        className="form-control"
                    >
                        <option value="">Select</option>
                        <option value="all">Invite all</option>

                        {teacherList.map((option) => {

                            

                                return (
                                    <option key={option._id} value={option._id}>
                                        {option.firstName} {option.lastName}
                                    </option>
                                )
                            
                           

                        })}
                    </select>
                   
                </div> */}
                <div className="text-view mb-3 ">
                    <label>Start Date</label>
                    <input type="date" onChange={value => { changeTime('startDate',value.target.value)}} value={session.startDate} className="form-control" placeholder="date" />
                </div>
                <div className="text-view mb-3 ">
                    <label>Start Time</label>
                    <input type="time" onChange={value => { changeTime('startTime', value.target.value) }} value={session.startTime} className="form-control" placeholder="time" />
                </div>

                <div className="text-view mb-3 ">
                    <label>End Date</label>
                    <input type="date" value={session.endDate} onChange={value => { changeTime('endDate', value.target.value) }} className="form-control" placeholder="date" />
                </div>
                <div className="text-view mb-3 ">
                    <label>End Time</label>
                    <input type="time" value={session.endTime} onChange={value => { changeTime('endTime', value.target.value) }} className="form-control" placeholder="time" />
                </div>
                <div >
                    <label>Available Teacher</label>
                    <div className={styles.acceptedTecher}>
                        <ul className="list-group row col-12">
                            {teacherList.map(option => {
                                return (
                                    <li key={option._id} className="list-group-item d-flex justify-content-between" ><span> {option.firstName} {option.lastName}</span> <button type="button" onClick={() => addTeacher(option)} class="btn btn-success btn-sm">add</button> </li>
                                )
                            })}



                        </ul>
                    </div>
                </div>
                <label>Accepted Teacher</label>
                <div className={styles.acceptedTecher}>
                    <ul className="list-group row col-12">
                        {accTeacherList.map(option => {
                            return (
                                <li key={option._id} className="list-group-item d-flex justify-content-between" ><span> {option.firstName} {option.lastName}</span> <button type="button" onClick={() => removeTeacher(option)} class="btn btn-danger btn-sm">Delete</button> </li>
                            )
                        })}


                    </ul>
                </div>
                {sendMessage && <p>Invite send to teacher</p>}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={sendInvite}>
                    Invite
                </Button>
            </Modal.Footer>
        </Modal>
    </div >
}


export default AdminSessionComp