import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { toCapitalWord } from "../../../utils/utils";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const Staff = ({ staffList, search, searchStaff, staffDelete, changeStatus }) => {
  const [roleSelect, setRoleSelect] = useState("all");
  const handleStaffSelection = (e) => {
    setRoleSelect(e.target.value);
  };
  const [id, setId] = useState()
  const [modal, setModal] = useState(false)
  return (
    <div className="row">
      <div className="staffSelection">
        <select className="form-control" onChange={handleStaffSelection}>
          <option value="all">All</option>
          <option value="teacher">Teacher</option>
          <option value="admin">Admin</option>
          <option value="staff">Staff</option>
        </select>
      </div>
      <h1 className="form-heading">Staff List</h1>
      <div className="add-btn-div">
        <Link
          to={`/admin/add-staff-role`}
          className="btn btn-sm btn-primary add-btn ml-3"
        >
          Add Role
        </Link>
        <Link
          to={`/admin/add-staff`}
          className="btn btn-sm btn-primary add-btn"
        >
          Add Staff
        </Link>
      </div>
      <div class="input-group row col-12   mb-2 ">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <img src='https://img.icons8.com/search' style={{ maxWidth: '40%' }} />
          </div>
        </div>
        <input type="text" value={search} onChange={text => searchStaff(text)} class="form-control" placeholder="Search Staff" />
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Staff Name</th>
                  <th>Role</th>
                  <th>Email</th>
                  <th>Curriculum</th>
                  <th>Subject</th>


                  <th>Mobile No.</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {staffList.length > 0 &&
                  staffList.map((s) => {

                    if (s.role === roleSelect && s.role !== 'student1') {
                      return (
                        <tr key={s._id}>
                          <td>
                            {s.firstName ? toCapitalWord(s.firstName) : ""}{" "}
                            {s.lastName ? toCapitalWord(s.lastName) : ""}
                          </td>
                          <td>{s.role ? toCapitalWord(s.role) : ""}</td>
                          <td>{s.email}</td>
                          {s.role === "teacher" && (
                            <React.Fragment>
                              <td>{s.curriculum}</td>
                              <td>{s.subject}</td>
                            </React.Fragment>
                          )}

                          <td>{s.mobile}</td>
                          <td>
                            {/* <button className="btn btn-sm btn-info mt-1" onClick={() => staffDelete(s._id)}>
                              Delete
                            </button> */}

                            hello
                          </td>
                          <td>
                            <Link to={`/admin/edit-staff/${s.userID}`}>
                              <button className="btn btn-sm btn-info">
                                Edit
                              </button>
                            </Link>
                            <Link to={`/admin/edit-staff/${s.userID}`}>
                              <button className="btn btn-sm btn-info mt-1" onClick={() => {
                                alert("fgff");
                                staffDelete(s._id)
                              }}>
                                Delete
                              </button>

                            </Link>
                          </td>

                        </tr>
                      );
                    } else if (roleSelect === "all" && s.role !== 'student') {

                      return (
                        <tr key={s._id}>
                          <td>
                            {s.firstName ? toCapitalWord(s.firstName) : ""}{" "}
                            {s.lastName ? toCapitalWord(s.lastName) : ""}
                          </td>
                          <td>{s.role ? toCapitalWord(s.role) : ""}</td>
                          <td>{s.email}</td>
                          {s.role !== 'admin' ? (
                            <Fragment>
                              <td>{s.curriculum}</td>
                              <td>{s.subject}</td>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <td>Admin</td>
                              <td>Admin</td>
                            </Fragment>
                          )}
                          <td>{s.mobile}</td>

                          <td style={{ width: "15%" }}>
                            {s.isActivate}
                            {s.isActivate ? <button className="btn btn-sm btn-info mt-1" onClick={() => changeStatus(s._id, false)}>
                              Active
                            </button> : <button className="btn btn-sm btn-info mt-1" onClick={() => changeStatus(s._id, true)}>
                              InActive
                            </button>}


                          </td>
                          <td>
                            <Link to={`/admin/edit-staff/${s.userID}`}>
                              <button className="btn btn-sm btn-info">
                                Edit
                              </button>
                            </Link>

                            <Modal
                              size='md'
                              isOpen={modal}
                              toogle={() => { setModal(!modal) }}
                              style={{ marginTop: "18%" }}
                            >
                              <ModalHeader
                                toogle={() => { setModal(!modal) }}>
                              </ModalHeader>
                              <ModalBody>
                                <div style={{ textAlign: "center" }}>
                                  <h3>Are you sure you want to Delete?</h3>
                                </div>
                                <div class="confirm" style={{ display: "flex", justifyContent: "center" }}>
                                  <button className="btn btn-primary" onClick={() => {
                                    staffDelete(id)
                                    setModal(false)
                                  }} style={{ margin: "12px" }}>Yes</button>
                                  <button className="btn btn-danger" onClick={() => {
                                    setModal(false)
                                  }} style={{ margin: "12px" }}>No</button>
                                </div>

                              </ModalBody>
                            </Modal>
                            <button className="btn btn-sm btn-info mt-1" onClick={() => {
                              setModal(true)
                              setId(s._id);
                            }} >
                              Delete
                            </button>


                          </td>

                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staff;
