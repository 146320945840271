import React from "react";
import DatePicker from "react-datepicker";
import { useField, ErrorMessage } from "formik";

const InputComponent = ({
  field, // { name, value, onChange, onBlur }
  col,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <div className={col}>
      <div className="form-group">
        <label htmlFor={field.name}>{props.label}</label>
        <input
          type="text"
          className="form-control"
          {...field}
          {...props}
          value={field.value}
        // onChange={(e) => {
        //   field.onChange(e);
        // }}
        />
        {touched[field.name] && errors[field.name] && (
          <div className="error">{errors[field.name]}</div>
        )}
      </div>
    </div>
  );
};

const SelectComponent = ({
  field, // { name, value, onChange, onBlur }
  options,
  optname,
  col,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <div className={col}>
      <div className="form-group">
        <label htmlFor={field.name}>{props.label}</label>
        <select
          className="form-control"
          value={field.value}
          {...field}
          {...props}
        // onChange={(e) => {
        //   field.onChange(e);
        // }}
        >
          <option value="">Select</option>
          {options.map((option) => (
            <option key={option._id || option[optname]} value={option.name}>
              {optname ? option[optname] : option.name}
            </option>
          ))}
        </select>
        {touched[field.name] && errors[field.name] && (
          <div className="error">{errors[field.name]}</div>
        )}
      </div>
    </div>
  );
};

const DatepickerComponent = ({ col, ...props }) => {
  const [field] = useField(props);
  // console.log("field======", field, moment(field.value).format("DD/MM/YYYY"));
  return (
    <div className={col}>
      <div className="form-group">
        <label htmlFor={field.name}>{props.label}</label>
        <DatePicker
          {...field}
          {...props}
          className="form-control"
          autoComplete="off"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        // selected={
        //   (field.value &&
        //     new Date(moment(field.value).format("MM/DD/YYYY"))) ||
        //   null
        // }
        // onChange={(val) => {
        //   setFieldValue(field.name, val);
        // }}
        />
        <ErrorMessage component={TextError} name={props.name} />
      </div>
    </div>
  );
};

const TimeInputComponent = ({
  field, // { name, value, onChange, onBlur }
  col,
  // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  onChange,
  ...props
}) => {
  return (
    <div className={col}>
      <div className="form-group">
        <label htmlFor={field.name}>{props.label}</label>
        <input
          type="time"
          className="form-control"
          {...field}
          {...props}
          value={field.value}
        // onChange={(e) => {
        //   console.log('dsd', e.target.value);
        //   //field.onChange(e.target.value);
        //   onChange(e.target.value)
        // }}
        />
        {/* {touched[field.name] && errors[field.name] && (
          <div className="error">{errors[field.name]}</div>
        )} */}
      </div>
    </div>
  );
};
const TextError = (props) => {
  return <div className="error">{props.children}</div>;
};

const Button = ({ label, ...rest }) => {
  return (
    <div className="col-md-12 text-center">
      <button {...rest}>{label}</button>
    </div>
  );
};

export { InputComponent, SelectComponent, DatepickerComponent, Button, TimeInputComponent };
