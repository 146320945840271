import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};
export function saveTemplate(payload, templateId) {
  if (!templateId) {
    return http.post(apiUrl + "/createTemplate", payload, {
      headers: headers,
    });
  } else {
    return http.post(apiUrl + "/updateTemplate", payload, {
      headers: headers,
    });
  }
}

export const getTemplateQuestions = (payload) => {
  return http.post(apiUrl + "/getTemplateQuestions", payload, {
    headers: headers,
  });
};

export const getTemplateList = () => {
  return http.get(apiUrl + "/getAlltemp", {
    headers: headers,
  });
};
export const deleteTemplate = (payload) => {
  return http.post(apiUrl + "/deleteTemplate", payload, {
    headers: headers,
  });
};

export const copyTemplate = (id) => {
  return http.post(apiUrl + "/copyTemplates/" + id, {
    headers: headers,
  });
};
