import React, { Fragment } from "react";
import { Link } from "react-router-dom";
const Profile = (props) => {
    console.log('role=' + props.role)
    return <div className="container">
        <div className="row justify-content-center">
            <h1 className="form-heading">Profile</h1>
            {props.role === 'student' && <button onClick={props.proptoCertificate}
                className="btn btn-primary btn-sm w-25 mb-3"
            >Get Certificate</button>}
            <div className="col-md-10">

                <div className="card">
                    <div className="card-body">
                        <div className="text-view">
                            <label>Name</label>
                            <span>
                                {props.firstName} {props.middleName} {props.lastName}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>Mobile</label>
                            <span>
                                {props.mobile}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>Email
                            </label>
                            <span>
                                {props.email}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>Address</label>
                            <span>
                                {props.address}
                            </span>
                        </div>
                        {props.role !== 'admin' && <Fragment>

                            <div className="text-view">
                                <label>Curriculum</label>
                                <span>
                                    {props.curriculum}
                                </span>
                            </div>
                            <div className="text-view">
                                <label>Curriculum Code</label>
                                <span>
                                    {props.curriculumCode}
                                </span>
                            </div>
                            {props.role !== 'teacher' && <Fragment>   <div className="text-view">
                                <label>Gender</label>
                                <span>
                                    {props.gender}
                                </span>
                            </div>
                                <div className="text-view">
                                    <label>Date of birth</label>
                                    <span>
                                        {props.dob && props.dob.split('T')[0]}
                                    </span>
                                </div>
                                <div className="text-view">
                                    <label>Country</label>
                                    <span>
                                        {props.country}
                                    </span>
                                </div>
                                <div className="text-view">
                                    <label>State</label>
                                    <span>
                                        {props.state}
                                    </span>
                                </div>

                                <div className="text-view">
                                    <label>City</label>
                                    <span>
                                        {props.city}
                                    </span>
                                </div>
                            </Fragment>}
                        </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div >
}

export default Profile;