import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import routerService from "./AuthRoutes";
class AuthLayout extends Component {
  render() {
    const { location, match } = this.props;
    console.log("this.props", this.props);
    return (
      <React.Fragment>
        <React.Fragment>
          <Switch>
            {routerService &&
              routerService.map((route, key) => {
                return (
                  <Route
                    key={key}
                    path={`${match.url}`}
                    component={route.component}
                    exact={true}
                  />
                );
              })}
          </Switch>
        </React.Fragment>
      </React.Fragment>
    );
  }
}
export default withRouter(AuthLayout);
