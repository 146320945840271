import React from "react";
import { Link } from "react-router-dom";
const Video = ({ videosList, deleteVideo, search, searchVideo }) => {
  return (
    <div className="row">
      <h1 className="form-heading">Videos</h1>
   
      <div className="add-btn-div d-flex   flex-row-reverse  add-btn">
        <Link
          to={`/admin/add-video`}
          className="btn btn-sm btn-primary  col-1 "
        >
          Add Video
        </Link>
        <Link
          to={`/admin/add-read`}
          className="btn btn-sm btn-primary mr-2 col-1"
        >
          Add reading Material
        </Link>
      </div>
      {/* <div className="add-btn-div">
        
     
      </div> */}
      <div class="input-group row col-12   mb-2 ">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <img src='https://img.icons8.com/search' style={{ maxWidth: '40%' }} />
          </div>
        </div>
        <input type="text" value={search} onChange={text => searchVideo(text)} class="form-control" placeholder="Search Video/Curriculum/Subject/Topic" />
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Video ID</th>
                  <th>Video Name</th>
                  <th>Curriculum</th>
                  <th>Subject</th>
                  <th>Topic</th>
                  <th>Chapter</th>
                  <th>Read file</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {videosList &&
                  videosList.map((v) => {
                    return (
                      <tr key={v._id}>
                        <td>{v.videoID}</td>
                        <td>{v.fileName}</td>
                        <td>{v.curriculum}</td>
                        <td>{v.subject}</td>
                        <td>{v.topic}</td>
                        <td>{v.chapter}</td>
                        {v.readArr && <td>{v.readArr.map(data => {
                          return (< a href={data.location} target="_blank" >
                            <p>{data.name}</p>
                          </a >)
                        })}</td>}
                        {!v.readArr && <td>No file</td>}
                        <td>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => deleteVideo(v.videoID, v.fileName)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Video;
