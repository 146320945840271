import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const fetchAssignments = (payload) => {
  return http.post(apiUrl + "/fetchAssignments", payload, {
    headers: headers,
  });
};

export const fetchAssignmentsById = (payload) => {
  return http.post(apiUrl + "/fetchAssignmentsById", payload, {
    headers: headers,
  });
};

export const getPendingQuestions = (payload) => {
  return http.post(apiUrl + "/getPendingQuestions", payload, {
    headers: headers,
  });
};

export const testSubmitByTeacher = (payload) => {
  return http.post(apiUrl + "/testSubmitByTeacher", payload, {
    headers: headers,
  });
};
