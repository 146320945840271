import React, { Component } from "react";
import LoadingModal from "../../components/Share/LoadingModal";
import AddSubject from "../../components/admin/subject/addSubject";
import Subject from "../../components/admin/subject/subject";
import ViewSubject from "../../components/admin/subject/viewSubject";

import {
  saveSubject,
  getAllSubject,
  getSubject,
  deleteSubject,

} from "../../services/Admin/subjectService";
import {
  getAllCurriculum
} from "../../services/Admin/curriculumService";
import { getCurriculumCodeFun } from "../../utils/utils";
import { getAllCurriculumList } from "../../components/Share/GetCurriculumDetails";
import { subjectValues } from "../../constants/AdminInitialValues";

class SubjectContainer extends Component {
  state = {
    mode: "",
    curriculumList: [],
    curriculumListIndividual: [],
    subjectList: [],
    subjectIndividual: [],
    subjectData: subjectValues,
    search: '',
    subjectListAll: [],
    isLoading: false,
  };

  loadDefaultValues = () => {
    const pathName = this.props.match.path;
    if (pathName === "/admin/add-subject") {
      this.setState({ mode: "Add" });
    } else if (pathName === "/admin/edit-subject/:id") {
      this.setState({ mode: "Edit" });
    } else if (pathName === "/admin/view-subject/:id") {
      this.setState({ mode: "View" });
    } else if (pathName === "/admin/subject") {
      this.setState({ mode: "List" });
    }
  };

  handleInput = ({ currentTarget: input }, name) => {
    const data = { ...this.state.subjectData };
    const { options, value, selectedIndex } = input;
    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;
      data[input.name] = optValue;
    } else {
      data[input.name] = input.value;
    }

    if (name === "curriculum") {
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      data["curriculumCode"] = curriculumCodeValue;
    }

    this.setState({ subjectData: data });
  };
  handleInput1 = (input, name) => {
    const data = { ...this.state.subjectData };
    // const { options, value, selectedIndex } = input;
    if (name) {
      // let optValue = options[selectedIndex].innerHTML;
      data[name] = input;
    }

    if (name === "curriculum") {
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input
      );
      data["curriculumCode"] = curriculumCodeValue;
    }

    this.setState({ subjectData: data });
  };
  handleDates = (date, name) => {
    const subjectData = this.state.subjectData;
    subjectData[name] = date;
    this.setState({ subjectData });
  };

  handleCkEditor = (data, name) => {
    let subjectData = this.state.subjectData;
    subjectData[name] = data;
    this.setState({ subjectData });
  };

  // Get the all subject details
  getSubjectList = () => {
    getAllSubject()
      .then((res) => {
        this.setState({ subjectList: res.data.data, subjectListAll: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };


  // Get the individual subject details
  getIndividualSubject = async () => {
    const payload = {
      subjectId: this.props.match.params.id,
    };
    return getSubject(payload)
      .then((res) => {
        this.setState({ subjectIndividual: res.data.data[0] });
        return res.data.data[0];
      })
      .catch((err) => {
        console.log("err", err);
        return [];
      });
  };

  // Edit subject
  editSubject = async () => {
    await this.getIndividualSubject();
    let data = await this.getIndividualSubject();
    let subjectData = {
      curriculum: data.curriculum,
      curriculumCode: data.curriculumCode,
      subject: data.subject,
      subjectInfo: data.subjectInfo,
      minCredits: data.minCredits,
      maxCredits: data.maxCredits,
      practicalSession: data.practicalSession,
      startDate: data.startDate,
      endDate: data.endDate,
      subjectId: this.props.match.params.id,
    };
    this.setState({ subjectData });
  };

  // Submit course form
  handleSubmit = async (formik) => {
    console.log("formik", formik);
    const courseId = this.props.match.params.id;
    const payload = this.state.subjectData;
    if (formik.isValid) {
      this.setState({ isLoading: true });
      saveSubject(payload, courseId)
        .then((res) => {
          this.props.history.push("/admin/subject");
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  deleteEntiresubject = async () => {
    const codeCurriculum = this.state.curriculumListIndividual.curriculumCode;
    const curriculum = this.state. curriculumListIndividual.curriculum;
    const subject =this.state.subjectIndividual.subject;
    console.log(curriculum,codeCurriculum,subject,"dasdasddasdadsads")
    const payload = {
      curriculumCode: codeCurriculum,
      curriculum: curriculum,
      subject:subject,
    };
    try {
      await deleteSubject(payload);
      this.props.history.push("/admin/subject");
    } catch (ex) {
      // if (ex.response && ex.response.status === 404) {
      //   this.props.history.replace("/not-found");
      // }
    }
  };

  closeViewCourse = () => {
    this.props.history.push("/admin/subject");
  };

  async componentDidMount() {
    await this.loadDefaultValues();
    const { mode } = this.state;

    if (mode === "Add") {
      let data = await getAllCurriculumList();
      this.setState({ curriculumList: data });
    } else if (mode === "Edit") {
      let data = await getAllCurriculumList();
      this.setState({ curriculumList: data });
      await this.editSubject();
    } else if (mode === "View") {
      this.getIndividualSubject();
    } else if (mode === "List") {
      this.getSubjectList();
    }
  }
  searchSubject = (event) => {
    this.setState({ search: event.target.value })
    let val = String(event.target.value).toLowerCase()
    if (!val) {
      this.getSubjectList();
      return
    }
    // console.log(this.state.subjectListAll);
    let filter = this.state.subjectListAll.filter(q => {

      if (q.subject.toLowerCase().indexOf(val) !== -1) {

        return q
      }
      else if (q.curriculum.toLowerCase().indexOf(val) !== -1) {
        return q
      }
    })
    // console.log('filter-' + JSON.stringify(filter))
    this.setState({ subjectList: [...filter] })

  }
  render() {
    let path = this.props.match.path;
    const {
      mode,
      subjectData,
      curriculumList,
      subjectList,
      subjectIndividual,
    } = this.state;

    // console.log("subjectData", subjectData);
    return (
      <React.Fragment>
        {path === "/admin/subject" && <Subject subjectList={subjectList} searchSubject={this.searchSubject} search={this.state.search} />}
        {(path === "/admin/add-subject" ||
          path === "/admin/edit-subject/:id") && (
            <AddSubject
              mode={mode}
              subjectData={subjectData}
              curriculumList={curriculumList}
              handleSubmit={this.handleSubmit}
              handleInput={this.handleInput}
              handleCkEditor={this.handleCkEditor}
              handleDates={this.handleDates}
              handleInput1={this.handleInput1}
            />
          )}

        {path === "/admin/view-subject/:id" && (
          <ViewSubject
          curriculumListIndividual={this.state.curriculumListIndividual}
            subjectIndividual={subjectIndividual}
            closeViewCourse={this.closeViewCourse}
            deleteEntireSubject={this.deleteEntiresubject}
          />
        )}
        <LoadingModal visible={this.state.isLoading} />
      </React.Fragment>
    );
  }
}

export default SubjectContainer;
