import React from "react";
import parse from "html-react-parser";
import { deleteQuestion } from "../../../services/Admin/questionService";
const ViewQuestion = (props) => {
  const { questionsIndividual, closeViewQuestion } = props;
  const data = questionsIndividual;
   
  return (
    <div className="row justify-content-center">
      <div className="closePage">
        <h3>View Question</h3>
        <button className="btn btn-sm btn-danger" onClick={()=>{deleteQuestion(data._id); closeViewQuestion();}}>
          Delete
        </button>
        <button className="btn btn-sm btn-secondary mr-2" onClick={closeViewQuestion}>
          Close
        </button>
      </div>
      <div className="col-md-10">
        <div className="card-deck">
          <div className="card">
            <div className="card-body">
              <div className="text-view">
                <label>Question ID</label>
                <span>{data.questionID ? data.questionID : ""}</span>
              </div>
              <div className="text-view">
                <label>Curriculam Name</label>
                <span>{data.curriculum ? data.curriculum : ""}</span>
              </div>
              <div className="text-view">
                <label>Subject Name</label>
                <span>{data.subject ? data.subject : ""}</span>
              </div>
              <div className="text-view">
                <label>Topic Name</label>
                <span>{data.topic ? data.topic : ""}</span>
              </div>
              <div className="text-view">
                <label>Chapter Name</label>
                <span>{data.chapter ? data.chapter : ""}</span>
              </div>
              <div className="text-view">
                <label>Question Type</label>
                <span>{data.questionType ? data.questionType : ""}</span>
              </div>
              <div className="text-view">
                <label>Marks for Questions</label>
                <span>{data.questionmarks ? data.questionmarks : ""}</span>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-3 ">
          <div className="question-list card mb-2">
            <div>
              <div>
                <p style={{ float: "left", marginRight: "10px" }}>Question:</p>{" "}
                {data.question ? parse(data.question) : ""}
              </div>
              {data.questionType === "Multi" && (
                <React.Fragment>
                  <p>
                    <i className="las la-check-circle"></i>{" "}
                    {data.correctanswer ? data.correctanswer : ""}
                  </p>
                  <img src={data.image_correctanswer.location} alt="" style={{ width: '100%', height: '100%', maxWidth: '500px', border: "1px solid #000" }}/>

                  <p>
                    <i className="las la-times-circle"></i>{" "}
                    {data.answer1 ? data.answer1 : ""}
                  </p>
                  <img src={data.image_answer1.location} alt="" style={{ width: '100%', height: '100%', maxWidth: '500px', border: "1px solid #000" }}/>
                  <p>
                    <i className="las la-times-circle"></i>{" "}
                    {data.answer2 ? data.answer2 : ""}
                  </p>
                  <img src={data.image_answer2.location} alt="" style={{ width: '100%', height: '100%', maxWidth: '500px', border: "1px solid #000" }}/>

                  <p>
                    <i className="las la-times-circle"></i>{" "}
                    {data.answer3 ? data.answer3 : ""}
                  </p>
                  <img src={data.image_answer3.location} alt="" style={{ width: '100%', height: '100%', maxWidth: '500px', border: "1px solid #000" }}/>

                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewQuestion;
