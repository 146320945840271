import React, { Component } from "react";
import Assignment from "../../components/Teachers/Assignment/Assignment";
import AssignmentCorrection from "../../components/Teachers/Assignment/AssignmentCorrection";
import {
  fetchAssignments,
  fetchAssignmentsById,
  getPendingQuestions,
  testSubmitByTeacher,
} from "../../services/Teacher/AssignmentService";

import {
  userCurriculumCode,
  teacherSubject,
  userID,
  userInfo,
} from "../../services/Authentication/AuthService";

class AssignmentContainer extends Component {
  state = {
    assignmentList: [],
    assignmentIndividual: [],
    pendingQuestions: [],
    manualCorrectionData: [],
  };

  getAllAssignments = async () => {
    const payload = {
      curriculumCode: userCurriculumCode(),
      userID: userID(),
      subject: teacherSubject(),
    };

    fetchAssignments(payload)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ assignmentList: res.data.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  getAllAssignmentsById = async () => {
    // console.log("this.props.match", this.props.match.params);
    const payload = {
      curriculumCode: this.props.match.params.cc,
      testID: this.props.match.params.id,
    };

    fetchAssignmentsById(payload)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ assignmentIndividual: res.data.data[0] });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  getPendingQuestions = async () => {
    // console.log("this.props.match", this.props.match.params);
    const payload = {
      userID: userID(),
      testID: this.props.match.params.id,
    };

    getPendingQuestions(payload)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            pendingQuestions: res.data.data,
            manualCorrectionData: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  manualMarks = (e, q, type) => {
    let newArray = [...this.state.manualCorrectionData];
    let value = e.target.value;
    const elementsIndex = newArray.findIndex(
      (element) => element.questionID == q.questionID
    );
    if (type === "marks") {
      console.log('e', typeof value, typeof q.questionmarks);
      if (Number(value) <= Number(q.questionmarks)) {
        let valid;
        if (e.target.value == 0) {
          valid = false;
        } else {
          valid = true;
        }
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          acheivedMarks: value,
          valid: valid,
        };
      } else {
        alert("Entered marks should be lessthan or equal to Question Marks");
        e.target.value = "";
      }
    } else if (type === "comments") {
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        comments: value,
      };
    }

    this.setState({ manualCorrectionData: newArray });
  };

  submitCorrection = () => {
    const assignmentDetails = this.state.assignmentIndividual;
    const queObj = this.state.manualCorrectionData;
    const userData = userInfo();
    userData["testID"] = this.props.match.params.id;
    // console.log("queObj", queObj);
    // console.log("queObj--length", queObj.length);

    const quesLength = this.state.manualCorrectionData?this.state.manualCorrectionData.length:0;

    let totalMarks = 0;
    let score = 0;
    let correctQues = 0;
    let wrongQues = 0;
    let temp = {};
    let optionQues = [];
    if(this.state.manualCorrectionData){
      this.state.manualCorrectionData.map((r, i) => {
        totalMarks += parseInt(r.acheivedMarks);
        if (r.valid === true) {
          if (assignmentDetails.assignmentMode === "Optional") {
            optionQues.push(parseInt(r.acheivedMarks));
            correctQues += 1;
            optionQues.sort(function (a, b) {
              return b - a;
            });
  
            if (assignmentDetails.optionalQuestions < optionQues.length) {
              optionQues.splice(assignmentDetails.optionalQuestions);
            }
  
            score = optionQues.reduce(function (a, b) {
              return a + b;
            }, 0);
          } else {
            score += parseInt(r.acheivedMarks);
            correctQues += 1;
          }
        } else {
          wrongQues += 1;
        }
        temp.score = score;
        temp.correctQues = correctQues;
        temp.wrongQues = wrongQues;
        temp.totalMarks = totalMarks;
        temp.student = r.userID
        return temp;
      });
    }
    

    const resultObj = {
      assessmentName: assignmentDetails.assessmentName,
      createDate: assignmentDetails.createDate,
      curriculum: assignmentDetails.curriculum,
      curriculumCode: assignmentDetails.curriculumCode,
      enddate: assignmentDetails.enddate,
      startdate: assignmentDetails.startdate,
      subject: assignmentDetails.subject,
      testID: assignmentDetails.testID,
      type: assignmentDetails.type,
      correctionMode: assignmentDetails.correctionMode,
      totalQuestions: quesLength,
      correctAnsweredQues: temp.correctQues,
      wrongAnsweredQues: temp.wrongQues,
      totalMarks: assignmentDetails.maxWeightage,
      achievedMarks: temp.score,
      userID: userID(),
      studentId: temp.student
    };

    const payload = {
      globalInfo: userData,
      transaction: this.state.manualCorrectionData,
      result: resultObj,
    };

   
    testSubmitByTeacher(payload)
      .then((res) => {
        this.props.history.replace(`/teacher/dashboard`);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  componentDidMount() {
    this.getAllAssignments();
    this.getAllAssignmentsById();
    this.getPendingQuestions();
  }

  render() {
    const path = this.props.match.path;
    const { assignmentIndividual, assignmentList, pendingQuestions } =
      this.state;
    return (
      <React.Fragment>
        {path === "/teacher/assignments-list" && (
          <Assignment assignmentList={assignmentList} />
        )}

        {path === "/teacher/assignment-correction/:id/:cc" && (
          <AssignmentCorrection
            assignmentIndividual={assignmentIndividual}
            pendingQuestions={pendingQuestions}
            manualMarks={this.manualMarks}
            submitCorrection={this.submitCorrection}
          />
        )}
      </React.Fragment>
    );
  }
}

export default AssignmentContainer;
