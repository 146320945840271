import VerifyContainer from './verifyContainer'

export default [

    {
        path: "",
        component: VerifyContainer,
    },


];