import React, { Component } from "react";
import LoadingModal from "../../components/Share/LoadingModal";
import Template from "../../components/admin/templates/template";
import AddTemplate from "../../components/admin/templates/addTemplate";
import ViewTemplate from "../../components/admin/templates/viewTemplate";
import { getQuestion } from "../../services/Admin/questionService";
import {
  saveTemplate,
  getTemplateList,
  getTemplateQuestions,
} from "../../services/Admin/templateService";

import { getCurriculumCodeFun } from "../../utils/utils";

import {
  getAllCurriculumList,
  getAllSubjectList,
  getAllTopicsList,
  getAllChaptersList,
} from "../../components/Share/GetCurriculumDetails";

import { userRole, userID } from "../../services/Authentication/AuthService";

class TemplateContainer extends Component {
  state = {
    mode: "",
    curriculumList: [],
    subjectsList: [],
    topicsList: [],
    chaptersList: [],
    templatesList: [],
    templatesListAll: [],
    search: '',
    templateIndividualList: [],
    questionsList: [],
    templateData: {
      templateName: "",
      templateType: "",
      curriculum: "",
      curriculumCode: "",
      subject: "",
      topic: "",
      chapter: "",
      questions: [],
    },
    submitFalse: true,
    isLoading: false,

  };

  loadDefaultValues = () => {
    const pathName = this.props.match.path;
    if (pathName === "/admin/add-template") {
      this.setState({ mode: "Add" });
    } else if (pathName === "/admin/edit-template/:id") {
      this.setState({ mode: "Edit" });
    } else if (pathName === "/admin/view-template/:id") {
      this.setState({ mode: "View" });
    } else if (pathName === "/admin/template") {
      this.setState({ mode: "List" });
    }
  };

  handleInput = async ({ currentTarget: input }, name, values) => {
    const data = { ...this.state.templateData };
    const { options, value, selectedIndex } = input;
    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;
      data[input.name] = optValue;
    } else {
      data[input.name] = input.value;
    }

    if (name === "curriculum") {
      data.subject = "";
      data.topic = "";
      data.chapter = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      data["curriculumCode"] = curriculumCodeValue;
      let subjectsList = await getAllSubjectList(curriculumCodeValue);
      this.setState({ subjectsList });
    }

    if (name === "subject") {
      data.topic = "";
      data.chapter = "";
      let topicsList = await getAllTopicsList(data.curriculumCode, input.value);
      this.setState({ topicsList });
    }

    if (name === "topic") {
      let chaptersList = await getAllChaptersList(
        data.curriculumCode,
        data.subject,
        input.value
      );
      this.setState({ chaptersList });
    }

    this.setState({ templateData: data });
  };

  handleGetQuestions = async () => {
    let templateData = this.state.templateData;
    
     let  query= {
        curriculumCode: templateData.curriculumCode,
        subject: templateData.subject,
       
       
        role: "Admin", //sessionStorage.getItem("role"),
      }
    
    //console.log('temp', templateData)
    if (templateData.topic){
      
     
        query['topic']= templateData.topic
      
    }
    if (templateData.chapter){
      
        query['chapter']= templateData.chapter
        
      
    }
    let payload = { query}

    getQuestion(payload)
      .then((res) => {
        if (res.data.status === true) {
        this.setState({ submitFalse: false });
        } else {
          this.setState({ submitFalse: true });
        }
        this.setState({ questionsList: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  optioncheckbox = (e, qID) => {
    const questions = this.state.templateData.questions
      ? this.state.templateData.questions
      : [];

    if (e.target.checked) {
      questions.push(qID);
    } else {
      let index = questions.indexOf(qID);
      questions.splice(index, 1);
    }
  };

  handleSubmit = async () => {
    const templateId = this.props.match.params.id;
    const templateData = this.state.templateData;
    const payload = templateData;
    if (templateId) {
      payload.templateID = templateId;
      payload.updateDate = new Date();
      payload.updatedBy = userID();
    } else {
      payload.createDate = new Date();
      payload.createdBy = userID();
    }
 
    this.setState({ isLoading: true });
    saveTemplate(payload, templateId)
      .then((res) => {
        this.props.history.push("/admin/template");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  getAllTemplates = async () => {
    const payload = {};
    getTemplateList()
      .then((res) => {
        this.setState({ templatesList: res.data.data, templatesListAll: res.data.data });
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  editTemplate = async () => {
    const templateId = this.props.match.params.id;
    const payload = {
      templateID: templateId,
    };

    getTemplateQuestions(payload)
      .then((res) => {
        const data = this.state.templateData;
        const temData = res.data.templateData[0];
        const totData = res.data.totalData[0];
        data.templateName = temData.templateName;
        data.templateType = temData.templateType;
        data.curriculum = temData.curriculum;
        data.curriculumCode = temData.curriculumCode;
        data.subject = temData.subject;
        data.topic = totData.topic;
        data.chapter = totData.chapter;
        this.setState({
          templateData: data,
          questionsList: res.data.totalData,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  getTemplateQuestionsList = async () => {
    const templateId = this.props.match.params.id;
    const payload = {
      templateID: templateId,
    };
    try {
      const templateIndividualList = await getTemplateQuestions(payload);
      this.setState({
        templateData: templateIndividualList.data.templateData[0],
        questionsList: templateIndividualList.data.totalData,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        this.props.history.replace("/not-found");
      }
    }
  };

  async componentDidMount() {
    await this.loadDefaultValues();
    if (this.state.mode === "Add") {
      let data = await getAllCurriculumList();
      this.setState({ curriculumList: data });
    } else if (this.state.mode === "Edit") {
      console.log("this.state.templateData", this.state.templateData);
      this.editTemplate();
      const data = this.state.templateData;
      let curriculumList = await getAllCurriculumList();
      let subjectsList = await getAllSubjectList(data.curriculumCode);
      let topicsList = await getAllTopicsList(
        data.curriculumCode,
        data.subject
      );
      let chaptersList = await getAllChaptersList(
        data.curriculumCode,
        data.subject,
        data.topic
      );
      this.setState({
        curriculumList,
        subjectsList,
        topicsList,
        chaptersList,
        templateData: data,
      });
    } else if (this.state.mode === "List") {
      this.getAllTemplates();
    } else if (this.state.mode === "View") {
      this.getTemplateQuestionsList();
    }
  }
  searchTemplate = (event) => {
    this.setState({ search: event.target.value })
    let val = String(event.target.value).toLowerCase()
    if (!val) {
      this.getAllTemplates()
      return
    }
    let filter = this.state.templatesListAll.filter(q => {

     
      if (q.templateName.toLowerCase().indexOf(val)!==-1){
        return q
      }
      else if (q.curriculum.toLowerCase().indexOf(val)!==-1){
        return q
      }
      else if (q.subject.toLowerCase().indexOf(val)!==-1){
        return q
      }
    })
    // console.log('filter-' + JSON.stringify(filter))
    this.setState({ templatesList: [...filter] })

  }
  render() {
    let path = this.props.match.path;
    const {
      mode,
      curriculumList,
      subjectsList,
      topicsList,
      chaptersList,
      templateData,
      questionsList,
      templatesList,
      templateIndividualList,
      submitFalse,
    } = this.state;
    return (
      <React.Fragment>
        {path === "/admin/template" && (
          <Template templatesList={templatesList} search={this.state.search} searchTemplate={this.searchTemplate} />
        )}

        {(path === "/admin/add-template" ||
          path === "/admin/edit-template/:id") && (
            <AddTemplate
              mode={mode}
              curriculumList={curriculumList}
              subjectsList={subjectsList}
              topicsList={topicsList}
              chaptersList={chaptersList}
              templateData={templateData}
              questionsList={questionsList}
              submitFalse={submitFalse}
              handleGetQuestions={this.handleGetQuestions}
              optioncheckbox={this.optioncheckbox}
              handleInput={this.handleInput}
              handleSubmit={this.handleSubmit}
            />
          )}
        {path === "/admin/view-template/:id" && (
          <ViewTemplate
            templateData={templateData}
            questionsList={questionsList}
          />
        )}
        <LoadingModal visible={this.state.isLoading} />
      </React.Fragment>
    );
  }
}

export default TemplateContainer;
