import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CopyCurriculumm,
  CopyCurrSubCourseQuestion,
} from "../../../services/Admin/curriculumService";

const Curriculum = ({ curriculumList, searchCurr, search }) => {
  let [st, setst] = useState(true);

  return (
    <div className="row">
      <h1 className="form-heading">Curriculum List</h1>
      <div className="add-btn-div">
        <Link
          to={`/admin/add-curriculum`}
          className="btn btn-sm btn-primary add-btn"
        >
          Add Curriculum
        </Link>
      </div>
      <div class="input-group row col-12   mb-2 ">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "40%" }}
            />
          </div>
        </div>
        <input
          type="text"
          value={search}
          onChange={(text) => searchCurr(text)}
          class="form-control"
          placeholder="Search Curriculum"
        />
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Curriculum Name</th>
                  {/* <th scope="col">Curriculum Award</th> */}
                  {/* <th scope="col">Short Name</th>  */}
                  <th scope="col" className="w-25">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {curriculumList.length > 0 &&
                  curriculumList.map((c) => {
                    return (
                      <tr key={c._id}>
                        <td>{c.curriculum}</td>
                        <td style={{display:'flex',width:'100%'}}>
                          
                          <Link to={`/admin/edit-curriculum/${c._id}`}>
                            <button className="btn btn-sm btn-success mr-2">
                              Edit
                            </button>
                          </Link>
                          <Link to={`/admin/view-curriculum/${c._id}`}>
                            <button className="btn btn-sm btn-info">
                              View
                            </button>
                          </Link>

                          {c.curriculum[c.curriculum.length - 1] ===
                          ")" ? null : (
                            <span className="copy_btn">
                              <span
                                className="threedotbtn border px-2">
                                ⋮
                              </span>

                              <span className="copy_menu">
                                <ul>
                                  <li
                                    onClick={() => {
                                      document
                                        .querySelectorAll(".copy_menu")
                                        .forEach((e) => {
                                          e.style.display = "none";
                                        });
                                      async function test() {
                                        await CopyCurriculumm(c._id);
                                        window.location.reload();
                                      }
                                      test();
                                    }}
                                  >
                                    Copy curriculum
                                  </li>
                                  <li
                                    onClick={() => {
                                      document
                                        .querySelectorAll(".copy_menu")
                                        .forEach((e) => {
                                          e.style.display = "none";
                                        });
                                      async function test1() {
                                        await CopyCurrSubCourseQuestion(c._id);
                                        window.location.reload();
                                      }
                                      test1();
                                    }}
                                  >
                                    Copy curriculum+Subject+Course+Question
                                  </li>
                                </ul>
                              </span>
                            </span>
                          )}

                        </td>


                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Curriculum;
