import React, { Component } from "react";
import AssignmentType from "../../components/Students/Assignments/AssignmentType";
import FormalAssignments from "../../components/Students/Assignments/FormalAssignments";
import InformalAssignments from "../../components/Students/Assignments/InformalAssignments";
import AssignmentPreview from "../../components/Students/Assignments/AssignmentPreview";
import TestView from "../../components/Students/Assignments/TestView";
import TestResult from "../../components/Students/Assignments/TestResult";
import {getUserCurriculum} from "../../services/Student/CurriculumStudentService"

import fscreen from 'fscreen';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import {
  getAssignments,
  getTestsList,
  getAssignmentByID,
  getTestQuesList,
  testSubmit,
  getResult,
  getAssignmentResult,
  getMeeting,
} from "../../services/Student/AssignmentService";

import {
  userCurriculumCode,
  userInfo,
  userID,
  userEmail,
} from "../../services/Authentication/AuthService";
import AssignmentResult from "../../components/Students/Assignments/AssignmentResult";

class AssignmentsContainer extends Component {
  state = {
    isType: "formal",
    assignmentList: [],
    assignmentIndividual: {},
    questions: [],
    queAnsObj: [],
    testResult: [],
    showResult: {},
    meetingLink: "",
    show: true
  };

  assignmentType = (type) => {
    if (type === "formal") {
      this.setState({ isType: "formal" });
    } else if (type === "informal") {
      this.setState({ isType: "informal" });
    }
  };


  getAllAssignments = async () => {
    getUserCurriculum(userID()).then((res)=>{
       var data=res.data.data
      let code=[]
      data.map((e)=>{
        code.push(e.curriculumCode)
      })
      const payload = {
        curriculumCode:code

      };
      getAssignments(payload)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ assignmentList: res.data.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    })

  };
  getMeetingLink = async () => {
    const id = this.props.match.params.id;
    const payload = {
      curriculumCode: userCurriculumCode(),
      testID: id,
      studentId: userID(),
      email: userEmail()

    };

    getMeeting(payload).then(res => {
      if (res.status === 200) {
        console.log('err', res.data.data.joinUrl)
        this.setState({ meetingLink: res.data.data.joinUrl })
        this.setState({ show: false })
        window.open(res.data.data.joinUrl, "_blank")
      }
    }).catch(err => {
      console.log('err', err)
    })
  }
  assignmentPreview = async () => {
    const id = this.props.match.params.id;
    const payload = {
      curriculumCode: userCurriculumCode(),
      testID: id,
    };
    getAssignmentByID(payload)
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("assigDet", JSON.stringify(res.data.data[0]));
          console.log('sa', res.data.data[0])
          if (res.data.data[0].correctionMode ==='automatic'){
            let payload={
              testID: res.data.data[0].testID,
              userID: userID(),
            }
            getResult(payload).then(res=>{
              let data
              if(res.status===200){
                
                data = res.data.data[0]
                data['status'] = true
                this.setState({ showResult: data })
              }else{
                data['status'] = false
                this.setState({ showResult: res.data.data[0] })
              }
           
            }).catch(err=>{

            })
          }else{
            this.getResultAssignment()
          }
          this.setState({ assignmentIndividual: res.data.data[0] });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  getResultAssignment = async () => {
    const id = this.props.match.params.id;
    const payload = {
      testID: id,
      userID: userID()
    };
    getAssignmentResult(payload).then(res => {
      if (res.data.status) {
        let data = res.data.data
        data['status'] = true
        this.setState({ showResult: data })
      } else {
        let data = {}
        data['status'] = false
        this.setState({ showResult: data })
      }
    }).catch(err => {
      let data = {}
      data['status'] = false
      this.setState({ showResult: data })
      console.log('err');
    })
  }

  getQuestions = async () => {
    const id = this.props.match.params.id;
    const payload = {
      testID: id,
    };
    getTestQuesList(payload)
      .then((res) => {
        this.setState({ questions: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleOption = (e, question) => {
    const queAnsObj = this.state.queAnsObj;
    const queObj = { ...question };
    const valid = question.correctanswer === e.target.value;
    queObj.valid = valid;
    queObj.response = e.target.value;
    queObj.responsedate = new Date();
    queAnsObj.push(queObj);
    this.setState({ queAnsObj });
  };

  handleOptionText = (data, question) => {
    // console.log("data", data, "question", question);
    const queAnsObj = this.state.queAnsObj;
    const queObj = { ...question };
    queObj.response = data;
    queObj.correctanswer = data;
    queObj.responsedate = new Date();

    queAnsObj.push(queObj);
    this.setState({ queAnsObj });
  };

  submitTest = async (questions) => {
    const assignmentDetails = JSON.parse(sessionStorage.getItem("assigDet"));
    const id = this.props.match.params.id;
    const queAnsObj = this.state.queAnsObj;
    const userData = userInfo();
    userData["testID"] = id;

    function getUniqueListBy(queAnsObj, key) {
      return [...new Map(queAnsObj.map((item) => [item[key], item])).values()];
    }

    const uniqQueObj = getUniqueListBy(queAnsObj, "_id");

    const quesLength = questions.length;

    // if (
    //   assignmentDetails.assignmentMode !== "Optional" &&
    //   submitQuestions !== quesLength
    // ) {
    //   alert("All Questions are mandatory");
    //   return false;
    // }

    let totalMarks = 0;
    let score = 0;
    let correctQues = 0;
    let wrongQues = 0;
    let temp = {};
    let optionQues = [];
    uniqQueObj.map((r, i) => {
      totalMarks += parseInt(r.questionmarks);
      if (r.valid === true) {
        if (assignmentDetails.assignmentMode === "Optional") {
          optionQues.push(parseInt(r.questionmarks));
          correctQues += 1;
          optionQues.sort(function (a, b) {
            return b - a;
          });

          if (assignmentDetails.optionalQuestions < optionQues.length) {
            optionQues.splice(assignmentDetails.optionalQuestions);
          }

          score = optionQues.reduce(function (a, b) {
            return a + b;
          }, 0);
        } else {
          score += parseInt(r.questionmarks);
          correctQues += 1;
        }
      } else {
        wrongQues += 1;
      }
      temp.score = score;
      temp.correctQues = correctQues;
      temp.wrongQues = wrongQues;
      temp.totalMarks = totalMarks;
      return temp;
    });

    const resultObj = {
      assessmentName: assignmentDetails.assessmentName,
      createDate: assignmentDetails.createDate,
      curriculum: assignmentDetails.curriculum,
      curriculumCode: assignmentDetails.curriculumCode,
      enddate: assignmentDetails.enddate,
      startdate: assignmentDetails.startdate,
      subject: assignmentDetails.subject,
      testID: assignmentDetails.testID,
      type: assignmentDetails.type,
      correctionMode: assignmentDetails.correctionMode,
      totalQuestions: quesLength,
      correctAnsweredQues: temp.correctQues,
      wrongAnsweredQues: temp.wrongQues,
      totalMarks: assignmentDetails.maxWeightage,
      achievedMarks: temp.score,
      userID: userID(),
    };

    const payload = {
      globalInfo: userData,
      transaction: uniqQueObj,
      result: resultObj,
    };

    testSubmit(payload)
      .then((res) => {
        this.props.history.replace(`/student/test-result/${id}`);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  getResult = async () => {
    const id = this.props.match.params.id;
    const payload = {
      userID: userID(),
      testID: id,
    };

    getResult(payload)
      .then((res) => {
        this.setState({ testResult: res.data.data[0] });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  closeModal = () => {
    this.setState({ show: false })
    this.props.history.push('/student/assignments')
  }
  async componentDidMount() {
    // console.log("fxreen" + fscreen.fullscreenEnabled)

    this.getAllAssignments();
    if (this.props.match.path === "/student/assignment-preview/:id") {
      // this.getMeetingLink()
      this.assignmentPreview();
    } else if (this.props.match.path === "/student/test-view/:id") {
      this.getQuestions();
    } else if (this.props.match.path === "/student/test-result/:id") {
      this.getResult();
    } else if (this.props.match.path === "/student/assignment-result/:id") {
      this.assignmentPreview();
    
    }
  }
  getTime = () => {
    let date = new Date()
    let hours = date.getHours()
    let min = date.getMinutes()
    return { hours, min }
  }

  render() {
    const path = this.props.match.path;
    const {
      isType,
      assignmentList,
      assignmentIndividual,
      questions,
      testResult,
    } = this.state;
    return (
      <React.Fragment>
        {path === "/student/assignments" && (
          <React.Fragment>
            <AssignmentType
              isType={isType}
              assignmentType={this.assignmentType}
            />
            {isType === "formal" && (
              <FormalAssignments
                assignmentList={assignmentList}
                assignmentPreview={this.assignmentPreview}
              />
            )}
            {isType === "informal" && (
              <InformalAssignments assignmentList={assignmentList} />
            )}
          </React.Fragment>
        )}
        {path === "/student/assignment-preview/:id" && (
          <AssignmentPreview assignmentIndividual={assignmentIndividual} closeModal={this.closeModal} show={this.state.show} getMeetingLink={this.getMeetingLink} meetingLink={this.state.meetingLink} getTime={this.getTime} />
        )}
        {path === "/student/assignment-result/:id" && (
          <AssignmentResult assignmentIndividual={assignmentIndividual} showResult={this.state.showResult} getTime={this.getTime} />
        )}
        {path === "/student/test-view/:id" && (
          <TestView
            questions={questions}
            submitTest={this.submitTest}
            handleOption={this.handleOption}
            handleOptionText={this.handleOptionText}
          />
        )}

        {path === "/student/test-result/:id" && (
          <TestResult testResult={testResult} />
        )}
      </React.Fragment>
    );
  }
}

export default AssignmentsContainer;
