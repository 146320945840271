import React from "react";
import StudentTraining from "../../components/Students/StudentTraining/StudentTraining";

export default function StudentTrainingContainer() {
   return (
     <React.Fragment>
       <StudentTraining />
     </React.Fragment>
   );
}

