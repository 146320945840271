import React, { Component } from "react";
import AddOragnization from "../../components/OrgAdmin/AddOragnization/AddOragnization";
import { userID } from "../../services/Authentication/AuthService";

class AddOragnizationContainer extends Component {
  state = {};

  componentDidMount() {}
  render() {
    return (
      <React.Fragment>
        <AddOragnization />
      </React.Fragment>
    );
  }
}
export default AddOragnizationContainer;
