import React from "react";
import { Link } from "react-router-dom";
import { copySubject } from "../../../services/Admin/subjectService";

const Subject = (props) => {
  const { subjectList, search, searchSubject } = props;
  return (
    <div className="row">
      <h1 className="form-heading">Subject List</h1>
      <div className="add-btn-div">
        <Link
          to={`/admin/add-subject`}
          className="btn btn-sm btn-primary add-btn"
        >
          Add Subject
        </Link>
      </div>
      <div class="input-group row col-12   mb-2 ">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <img src='https://img.icons8.com/search' style={{ maxWidth: '40%' }} />
          </div>
        </div>
        <input type="text" value={search} onChange={text => searchSubject(text)} class="form-control" placeholder="Search Curriculum/Chapter " />
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Curriculum Name</th>
                  <th>Subject Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {subjectList &&
                  subjectList.map((c) => {
                    return (
                      <tr key={c._id}>
                        <td>{c.curriculum}</td>
                        <td>{c.subject}</td>
                        <td className="w-25">
                       
                          <Link to={`/admin/edit-subject/${c._id}`}>
                            <button className="btn btn-sm btn-success mr-2">
                              Edit
                            </button>
                          </Link>
                          <Link to={`/admin/view-subject/${c._id}`}>
                            <button className="btn btn-sm btn-info mr-2">
                              View
                            </button>
                          </Link>
                          {c.subject[c.subject.length - 1] === ")" ? null :
                            <button onClick={() => {
                             async function test() {
                               await copySubject(c._id)
                               window.location.reload();
                                
                              }
                              test();
                            
                            }
                            } className="btn btn-sm btn-success ">
                              Copy
                            </button>
                          }
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subject;
