import { GET_LOGO, LOGO } from '../actions/logoImage'
const initialState = {
    logo: ''
}


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_LOGO:
            return { logo: action.logo }
    }
    return state
}