import React from "react";
import { Link } from "react-router-dom";
import { copyTemplate } from "../../../services/Admin/templateService";

const Template = ({ templatesList, search, searchTemplate }) => {
  return (
    <div className="row">
      <h1 className="form-heading">Templates</h1>
      <div className="add-btn-div">
        <Link
          to={`/admin/add-template`}
          className="btn btn-sm btn-primary add-btn"
        >
          Add Template
        </Link>
      </div>
      <div class="input-group row col-12   mb-2 ">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "40%" }}
            />
          </div>
        </div>
        <input
          type="text"
          value={search}
          onChange={(text) => searchTemplate(text)}
          class="form-control"
          placeholder="Search Curriculum/Subject/Template Name"
        />
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Template ID</th>
                  <th scope="col">Template Name</th>
                  <th scope="col">Curriculum</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Template Type</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {templatesList &&
                  templatesList.length > 0 &&
                  templatesList.map((q) => {
                    return (
                      <tr key={q._id}>
                        <td>{q.templateID}</td>
                        <td>{q.templateName} </td>

                        <td>{q.curriculum}</td>
                        <td>{q.subject}</td>
                        <td>{q.templateType}</td>
                        <td className="w-25">

                          <Link to={`/admin/edit-template/${q.templateID}`}>
                            <button className="btn btn-sm btn-success mr-2">
                              Edit
                            </button>
                          </Link>
                          <Link to={`/admin/view-template/${q.templateID}`}>
                            <button className="btn btn-sm btn-info mr-2">
                              View
                            </button>
                          </Link>
                          {q.templateName[q.templateName.length - 1] ===
                            ")" ? null : (
                            <button
                              onClick={() => {
                                async function test() {
                                  await copyTemplate(q._id);
                                  window.location.reload();
                                }
                                test();
                              }}
                              className="btn btn-sm btn-success"
                            >
                              Copy
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template;
