import React from "react";
import parse from "html-react-parser";
import DisplayErrorMessage from "../../Share/DisplayErrorMessage";
import styles from "./MyQueries.module.css";
import quer from "../../../assets/Banners/Banner3.png";

const OpenQueries = (props) => {
  const { openQueries } = props;

  if (openQueries && openQueries.length === 0) {
    return (
      <DisplayErrorMessage message="No open queries found" errorSize="big" />
    );
  }

  return (
    <React.Fragment>
      {/* <div className={`col-md-12 ${styles.dashimg} mb-2 `}>
        <img src={quer} style={{ width: "100%", height: "100%" }} />
      </div> */}
       
          
        {/* <span><h1>JNU-Certificate-cr</h1></span> */}
      {openQueries.length > 0 &&
        openQueries.map((q) => {
          if (q.status === 0) {
            return (
                <div className={`cardDiv1 ${styles.queryDiv}`} key={q._id}>
                  <h5 title="Curriculum" className={styles.curriculum}>
                    {q.curriculum} {"  -"}
                  </h5>
                  <div title="Subjet" className={styles.subject}>
                    {q.subject} {"-"}
                  </div>
                  <div title="Topic" className={styles.topic}>
                    {q.topic} {"-"}
                  </div>
                  <div title="Chapter" className={styles.chapter}>
                    {q.chapter}
                  </div>

                  <hr className={styles.hr} />
                  {q.messages &&
                    q.messages.map((m) => {
                      return <div className={styles.cardDiv3} > <p  key={m.createdAt} >{parse(m.message)}</p></div>;
                    })}
                </div>


            );
            
          }
          
        
        })}
        
        
    </React.Fragment>
  );
};

export default OpenQueries;
