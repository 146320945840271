import React, { useEffect, useState } from "react";
import moment from "moment";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import styles from "./Dashboard.module.css";
import DisplayErrorMessage from "../../Share/DisplayErrorMessage";
import { userCurriculum } from "../../../services/Authentication/AuthService";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import dash from "../../../assets/Banners/Banner1.png";
import upcomingimg from "../../../assets/Icons/IconAssignments.png";
import assignmentimg from "../../../assets/Icons/IconAssignments.png";
import upcomingsessionimg from "../../../assets/Icons/Iconupcomingsession.png";
import Iconmyqueries from "../../../assets/Icons/Iconmyqueries.png";
import { getUserCurriculum } from "../../../services/Student/CurriculumStudentService";
import image from "../../..//assets/Images/group2.png";
const Dashboard = ({
  assignmentList,
  allQueries,
  allSessions,
  upcomingSession,
  upcomingSessionLIst,
  userInfo,
  userCurriculumsData,
}) => {
  const handle = useFullScreenHandle();
  const [upComming, setUpComming] = useState();
  const [latest, setLatest] = useState(null);

  //  let x=93;
  //   const dataaaaa = getUserCurriculum(x).then((res) => {
  //     console.log(res.data, "curdatatdataatdad");
  //   }).catch((err) => console.log(err))
  console.log("---", userCurriculumsData, "CUR dataaaaaaaaaaa");
  const recentDate = () => {
    let max;
    let date = upcomingSession;
    // console.log('data' + JSON.stringify(date))
    try {
      if (allSessions) {
        const value = date.sort((a, b) => {
          return new Date(b.start) - new Date(a.start);
          // if (a.start > b.start) {
          //   return b
          // } else {
          //   return a
          // }
        });
        let rev = value.reverse();
        setUpComming(rev);
        if (rev.length > 0) {
          setLatest(rev[0]);
        }
      }
    } catch (err) {
      console.log("errorr");
    }
  };
  useEffect(() => {
    recentDate();
  }, [upcomingSession]);
  return (
    <React.Fragment>
      <div className={`col-md-12 ${styles.dashimg} mb-2 `}>
        <img src={image} style={{ width: "100%", height: "100%" }} />
      </div>
      <div className="col-md-12 pad-0">
        <div className="col-md-4">
          <h5>Current Curriculum</h5>
        </div>
        <div className="row1">
          {userCurriculumsData
            ? userCurriculumsData.map((e) => {
                return (
                  <div className="home">
                    <div
                      className="cardDivtop"
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className={styles.topCardRight}>
                        {!e.location && (
                          <img
                            src="https://gambolthemes.net/html-items/cursus_demo_f12/images/dashboard/graduation-cap.svg"
                            alt=""
                          />
                        )}

                        {e.location && <img src={userInfo.location} alt="" />}
                      </div>
                      <div className={styles.topCardLeft1}>
                        <h2 style={{ fontSize: "14px", marginLeft: "27px" }}>
                          {e.curriculum}
                        </h2>
                        <span>
                          {e.startDate && e.startDate.split("T")[0]}-{" "}
                          {e.startDate && e.endDate.split("T")[0]}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
          {/* {userCurriculumsData === [] ? (
            <div className="mt-10">
              <Link to={`/Subscription`} className="btn btn-sm btn-primary ">
                Buy Curriculum
              </Link>
            </div>
          ) : (
            <div className="">
              <Link to={`/Subscription`} className="btn btn-sm btn-primary ">
                Checkout More Curriculum
              </Link>
            </div>
          )} */}

          {/* <div className="col-md-4">
            <div className="cardDiv">
              <div className={styles.topCardLeft}>
                <h5>Next Scheduled Calss</h5>
                <h2>Physics</h2>
                <span>18-01-2021</span>
              </div>
              <div className={styles.topCardRight}>
                <img
                  src="https://www.flaticon.com/svg/static/icons/svg/2631/2631392.svg"
                  alt=""
                />
              </div>
            </div>
          </div> */}
        </div>
        <br />
        <div className="col-md-12 pad-0">
          <div className="row">
            <h4 className="text-heading">
              {/* <img src={assignmentimg} style={{ height: 24 }} /> */}
              Assignments
            </h4>
            <p className="viewMore">
              <Link to={"/student/assignments"}>
                View More <i className="las la-arrow-circle-right"></i>
              </Link>
            </p>
          </div>
          <div className="row">
            {assignmentList && assignmentList.length === 0 && (
              <div className="col-md-12">
                <DisplayErrorMessage message="No assignments found" />
              </div>
            )}
            {assignmentList.length > 0 &&
              assignmentList.slice(0, 4).map((a) => {
                return (
                  <div className="col-md-3" key={a._id}>
                    <div className={`cardDivAss ${styles.assignmentDiv1}`}>
                      <Link to={`/student/assignment-preview/${a.testID}`}>
                        <h5
                          style={{
                            marginTop: "45px",
                          }}
                        >
                          {a.assessmentName}
                        </h5>
                      </Link>
                      {/* <h5>{a.assessmentName}</h5> */}
                      <p>
                        Start Date - {moment(a.startdate).format("DD-MM-YYYY")}
                      </p>
                      <p>End Date - {moment(a.enddate).format("DD-MM-YYYY")}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <br />

        <div className="col-md-12 pad-0">
          <div className="row">
            <h4 className="text-heading">
              {" "}
              {/* <img src={upcomingsessionimg} style={{ height: 24 }} />  */}
              Upcoming Sessions
            </h4>
            <p className="viewMore">
              <Link to={"/student/sessions"}>
                View More <i className="las la-arrow-circle-right"></i>
              </Link>
            </p>
          </div>
          <div className="row">
            {upcomingSessionLIst && upcomingSessionLIst.length === 0 && (
              <div className="col-md-12">
                <DisplayErrorMessage message="No sessions found" />
              </div>
            )}
            {upcomingSessionLIst &&
              upcomingSessionLIst.length > 0 &&
              upcomingSessionLIst.map((as) => {
                // if (as.status === "PENDING") {
                //   return
                // }
                console.log("upcon000000000000000", as);
                return (
                  <div className="col-md-3">
                    <div className={`cardDivSess ${styles.sessionsDiv}`}>
                      <Link to={`/student/session-preview/${as.sessionID}`}>
                        <h2
                          style={{
                            marginTop: "45px",
                            color: "#fff",
                          }}
                        >
                          {as.subject}
                        </h2>
                      </Link>
                      <p>{as.topic}</p>
                      <br></br>
                      <span style={{ backgroundColor: "none" }}>
                        {moment(as.start).format("DD-MM-YYYY")}
                      </span>{" "}
                      <br />
                      {/* <br /> */}
                      <span>{moment(as.start).format("hh:mm A")}</span>
                      <Link to={`/student/session-preview/${as.sessionID}`}>
                        <button
                          className="btn btn-sm"
                          style={{ height: "24px", marginTop: "90px" }}
                        >
                          View
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <br />
        <div className="row">
          <h4 className="text-heading">
            {/* <img src={Iconmyqueries} style={{ height: 24 }} /> */}
            My Queries
          </h4>
          <p className="viewMore">
            <Link to={"/student/my-queries/open"}>
              View More <i className="las la-arrow-circle-right"></i>
            </Link>
          </p>
        </div>
        <div className="row">
          {allQueries && allQueries.length === 0 && (
            <div className="col-md-12">
              <DisplayErrorMessage message="No queries found" />
            </div>
          )}
          {allQueries.length > 0 &&
            allQueries.slice(0, 4).map((q) => {
              return (
                <div className="col-md-3" key={q._id}>
                  <div className={`cardDivMy ${styles.queryDiv}`}>
                    <div className="row">
                      <div title="Subjet" className={styles.subject}>
                        {q.subject}
                      </div>
                      <br />
                      <div title="Topic" className={styles.topic}>
                        {q.topic}
                      </div>
                    </div>
                    <hr
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        borderTop: "1px solid #e8e8e8",
                      }}
                    />
                    <div
                      className="row"
                      style={{
                        lineHeight: "1.5em",
                        height: "3em",
                        overflow: "hidden",
                      }}
                    >
                      {parse(q.messages[0].message)}{" "}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
