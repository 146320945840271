import React, { useState, useEffect, Fragment, use } from "react";
import Logo from "../../assets/Images/logo.png";
import { useParams } from 'react-router-dom'
import styles from './verify.module.css'
import { Link } from "react-router-dom";
import { verifyEmail } from '../../services/Authentication/AuthService'
import LoadingModal from "../../components/Share/LoadingModal";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getlogo } from "../../store/actions/logoImage";

const EmailVerify = props => {
    let history = useHistory();
    const [logoImg, setLogoImg] = useState('')
    const dispatch = useDispatch()

    let image = useSelector(state => {
        localStorage.setItem('logo', state.logo.logo)
        return state.logo.logo
    })
    useEffect(() => {
        console.log('image logo', image)
        if (!image) {
            setLogoImg(localStorage.logo)
        } else {
            setLogoImg(image)
        }

    }, [image])
    const params = useParams()
    const [verified, setVerified] = useState(false)
    const [loading, setLoading] = useState(true)
    // console.log('[ae=' + JSON.stringify(params))
    useEffect(() => {
        dispatch(getlogo())
        setLoading(true)
        if (params.id && params.emailToken) {
            verifyEmail({ id: params.id, emailToken: params.emailToken }).then(res => {
                if (res.status === 200) {
                    setVerified(true)
                } else {
                    setVerified(false)
                }
            })
        }
        setLoading(false)
    }, [])
    if (loading) {
        <LoadingModal visible={loading} />
    }
    return <Fragment>
        <div className={styles.cardContainer}>
            <div className="card" className={styles.card}>
                {logoImg && <img src={logoImg} height="170px"
                    className={styles.image} alt="logo" />}
                {verified && <div className="card-body">
                    <div className="mb-3">
                        <p className="form-label">Email verification successfull</p>
                        <p className="form-label">Wait for admin to accept student detail</p>

                        <Link to={`/`}>
                            <button type="submit" className="btn btn-primary mt-3 w-100">Go to Login</button>
                        </Link>
                    </div>
                </div>}
                {!verified &&
                    <div className="card-body">
                        <div className="mb-3">
                            <p for="exampleInputEmail1" className="form-label">Email verification failed</p>

                            <Link to={`/`}>
                                <button type="submit" className="btn btn-primary mt-3 w-100">Go to Login</button>
                            </Link>
                        </div>
                    </div>
                }
            </div>
        </div>
    </Fragment>
}
// const style = {
//     card: {
//         width: "50%",
//     },
//     cardImg: {
//         height: 100
//     }
// }

export default EmailVerify