import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { toCapitalWord } from "../../../utils/utils";
import { copyQuestion } from "../../../services/Admin/questionService";

const Curriculum = ({ questionsList, search, searchQuestion}) => {
  return (
    <div className="row">
      <h1 className="form-heading">Question Library</h1>
      <div className="add-btn-div">
        <Link
          to={`/admin/add-question`}
          className="btn btn-sm btn-primary add-btn"
        >
          Add Question
        </Link>
      </div>
      <div class="input-group row col-12   mb-2 ">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <img src='https://img.icons8.com/search' style={{ maxWidth: '40%' }} />
          </div>
        </div>
        <input type="text" value={search} onChange={text => searchQuestion(text)} class="form-control" placeholder="Search Curriculum/Subject/Question" />
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Question ID</th>
                  <th scope="col">Question</th>
                  <th scope="col">Question Type</th>
                  <th scope="col">Curriculum</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Marks</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {questionsList &&
                  questionsList.length > 0 &&
                  questionsList.map((q) => {
                    return (
                      <tr key={q._id}>
                        <td>{q.questionID}</td>
                        <td> {q.question ? parse(q.question) : ""} </td>
                        <td>
                          {q.questionType ? toCapitalWord(q.questionType) : ""}
                        </td>
                        <td>{q.curriculum}</td>
                        <td>{q.subject}</td>
                        <td> {q.questionmarks} </td>
                        <td className="w-25">
              
                          <Link to={`/admin/edit-question/${q.questionID}`}>
                            <button className="btn btn-sm btn-success mr-2">
                              Edit
                            </button>
                          </Link>
                          <Link to={`/admin/view-question/${q.questionID}`}>
                            <button className="btn btn-sm btn-info mr-2">
                              View
                            </button>
                          </Link>
                          {q.question[q.question.length - 1] === ")" ? null :
                            <button onClick={() => {
                             async function test() {
                               await copyQuestion(q._id)
                               window.location.reload();
                                
                              }
                              test();
                            
                            }
                            } className="btn btn-sm btn-success">
                              Copy
                            </button>
                          }
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Curriculum;
