import React, { Component } from "react";
import moment from "moment";
import {getUserCurriculum} from "../../services/Student/CurriculumStudentService"
import RaiseRequest from "../../components/Students/HelpFromTutor/RaiseRequest";
import RequestType from "../../components/Students/HelpFromTutor/RequestType";
import OpenRequests from "../../components/Students/HelpFromTutor/OpenRequests";
import ScheduledRequests from "../../components/Students/HelpFromTutor/ScheduledRequests";
import CompletedRequests from "../../components/Students/HelpFromTutor/CompletedRequests";

import { getCurriculumCodeFun } from "../../utils/utils";

import {
  sessionInsert,
  sessionUpdate,
  getAllSessions,
  getAllTeachers,
  getOpenSessions,
} from "../../services/Student/SessionService";

import {
  getAllSubjectList,
  getAllTopicsList,
  getAllChaptersList,
} from "../../components/Share/GetCurriculumDetails";

import {
  userID,
  userEmail,
  userCurriculumCode,
  userCurriculumsList,
  userRole,
} from "../../services/Authentication/AuthService";

import { sessionValues } from "../../constants/StudentInitialValues";

class HelpTutorContainer extends Component {
  state = {
    isRequest: false,
    isFeedback: false,
    curriculumList: [],
    subjectsList: [],
    topicsList: [],
    chaptersList: [],
    openRequests: [],
    scheduledRequests: [],
    completedRequests: [],
    sessionData: sessionValues,
    feedbackMessage: "",
    selectedRequest: "",
    rating: "",
    teachersList: [],
    requestedTeacher: [],
    topicData: [],
    messageError: "",
    message: "",
  };

  raiseRequest = () => {
    this.props.history.push("/student/help-from-tutor/request-session");
  };

  backRequest = () => {
    this.props.history.push("/student/help-from-tutor/open");
  };

  feedbackRequest = (index) => {
    this.setState({ isFeedback: true, selectedRequest: index });
  };

  requestType = (type) => {
    if (type === "open") {
      this.props.history.push("/student/help-from-tutor/open");
    } else if (type === "scheduled") {
      this.props.history.push("/student/help-from-tutor/scheduled");
    } else if (type === "completed") {
      this.props.history.push("/student/help-from-tutor/completed");
    }
  };

  handleInput = async ({ currentTarget: input }, name) => {
    const data = { ...this.state.sessionData };
    const { options, selectedIndex } = input;

    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;
      data[input.name] = optValue;
    } else {
      data[input.name] = input.value;
    }
    this.setState({ sessionData: data });

    if (name === "curriculum") {
      data.subject = "";
      data.topic = "";
      data.chapter = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      data["curriculumCode"] = curriculumCodeValue;
      let subjectsList = await getAllSubjectList(curriculumCodeValue);
      this.setState({ subjectsList });
    }

    if (name === "subject") {
      data.topic = "";
      data.chapter = "";
      let topicData = [];
      let topicsList = await getAllTopicsList(data.curriculumCode, input.value);
      topicsList.map((t) => {
        return topicData.push(t.topic);
      });
      this.getAllTeachers(data.curriculumCode, input.value);
      this.setState({ topicsList, topicData: topicData });
    }

    if (name === "topic") {
      let chaptersList = await getAllChaptersList(
        data.curriculumCode,
        data.subject,
        input.value
      );
      this.setState({ chaptersList });
    }
  };

  handleInputMessage = (data, values) => {
    if (data) {
      this.setState({ message: data, messageError: false });
    } else {
      this.setState({ messageError: true });
    }
  };

  handleInputFeedback = (event) => {
    this.setState({ feedbackMessage: event.target.value });
  };

  getAllTeachers = (curriculumCode, subject) => {
    const payload = {
      curriculumCode: curriculumCode,
      subject: subject,
    };

    getAllTeachers(payload)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ teachersList: res.data.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleSubmitQuery = async (formik) => {
    const values = this.state.sessionData;
    if (this.state.message === "") {
      this.setState({ messageError: true });
    }

    var date = new Date();
    date.setDate(date.getDate() + 1);

    const data_str = moment(date).format("YYYY-MM-DD");
    const start_str = data_str + " " + "12:00";
    const end_str = data_str + " " + "13:00";

    let payload = {
      title: "Requested session on " + values.subject,
      requestedTeacher: this.state.teachersList,
      curriculum: values.curriculum,
      curriculumCode: values.curriculumCode,
      subject: values.subject,
      topics: this.state.topicData,
      desc: this.state.message,
      start: start_str,
      end: end_str,
      createDate: new Date(),
      createdBy: userID(),
      role: userRole(),
    };

    if (formik.isValid && !this.state.messageError) {
      sessionInsert(payload)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              isRequest: false,
              sessionData: {
                curriculum: "",
                curriculumCode: "",
                subject: "",
                topic: "",
                chapter: "",
                message: "",
              },
            });
            this.getAllSessionsList();
            sessionStorage.removeItem("curriculumDataSession");
            this.props.history.push("/student/help-from-tutor/open");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  handleFeedbackRequest = (data) => {
    const payload = {
      sessionID: data.sessionID,
      sender_id: userID(),
      feedback: this.state.feedbackMessage,
      rating: this.state.rating,
    };

    // console.log("payload", payload);

    sessionUpdate(payload)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            isRequest: false,
            isReplay: false,
            isFeedback: false,
            feedbackMessage: "",
          });
          this.getAllSessionsList();
          this.props.history.push("/student/help-from-tutor/open");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  getOpendata = () => {
    const payload = {
      role: "student",
      createdBy: userID(),
      status: "PENDING"
    };
    getOpenSessions(payload).then(res => {
      let data = res.data.data
      console.log(data)
      this.setState({ openRequests: data });

    }).catch(err => {
      console.log('ee', err)
    })

  }
  getsheduleddata = () => {
    const payload = {
      role: "student",
      curriculumCode: userCurriculumCode(),
      createdBy: userID(),
      status: "ACCEPTED"
    };
    getOpenSessions(payload).then(res => {
      let data = res.data.data
      console.log(data)
      this.setState({ scheduledRequests: data });

    }).catch(err => {
      console.log('ee', err)
    })

  }
  getAllSessionsList = async () => {
    const payload = {
      role: "student",
      curriculumCode: userCurriculumCode(),
      email: userEmail(),
    };
    getAllSessions(payload)
      .then((res) => {
        const data = res.data.data;
        // console.log("res.data.data" + JSON.stringify(data));
        // this.setState({ openRequests: data });
        // this.setState({ scheduledRequests: data });
        let shedule = [], open = [], comp = []
        data &&
          data.length > 0 &&
          data.map((q) => {
            // if (q.start) {
            //   let startdate = new Date(q.start).getTime()
            //   let today = new Date.now()
            //   console.log('sat', startdate);
            //   if (startdate > today) {

            //   } else {

            //   }
            // }

            console.log('ss-', q.start)
            if (q.status === "PENDING" && q.role === 'student') {
              open.push(q)
            } else if (q.status === "ACCEPTED" && q.role === 'student') {
              if (q.start) {
                let startdate = new Date(q.start).getTime()
                let today = Date.now()
                shedule.push(q)

                // if (startdate > today){
                //   shedule.push(q)

                // }else{
                //   comp.push(q)
                // }
              }
            }
          });
        // this.setState({ openRequests: open });
        this.setState({ CompletedRequests: comp });

      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleRating = (e) => {
    this.setState({ rating: e });
  };
  getUserCurriculums=()=>{
    getUserCurriculum(userID()).then((res)=>{
      this.setState({ curriculumList: res.data.data });

   
     })
  };


  async componentDidMount() {
    this.getUserCurriculums()
    // let curr = userCurriculumsList();
    // this.setState({ curriculumList: curr });
    // this.getAllSessionsList();
    this.getOpendata()
    this.getsheduleddata()
    let data = JSON.parse(sessionStorage.getItem("curriculumDataSession"));
    if (data) {
      let subjectsList = await getAllSubjectList(data.curriculumCode);
      let topicsList = await getAllTopicsList(
        data.curriculumCode,
        data.subject
      );
      let chaptersList = await getAllChaptersList(
        data.curriculumCode,
        data.subject,
        data.topic
      );
      this.setState({
        subjectsList,
        topicsList,
        chaptersList,
        sessionData: data,
      });
    }
  }

  render() {
    const path = this.props.match.path;
    const {
      isFeedback,
      sessionData,
      openRequests,
      scheduledRequests,
      completedRequests,
      curriculumList,
      subjectsList,
      topicsList,
      chaptersList,
      feedbackMessage,
      messageError,
    } = this.state;

    return (
      <React.Fragment>
        {path !== "/student/help-from-tutor/request-session" && (
          <RequestType
            requestType={this.requestType}
            raiseRequest={this.raiseRequest}
          />
        )}

        {path === "/student/help-from-tutor/request-session" && (
          <RaiseRequest
            curriculumList={curriculumList}
            subjectsList={subjectsList}
            topicsList={topicsList}
            chaptersList={chaptersList}
            messageError={messageError}
            backRequest={this.backRequest}
            sessionData={sessionData}
            handleSubmitQuery={this.handleSubmitQuery}
            handleInput={this.handleInput}
            handleInputMessage={this.handleInputMessage}
          />
        )}

        <div className="col-md-12">
          {path === "/student/help-from-tutor/open" && (
            <OpenRequests openRequests={openRequests} />
          )}

          {path === "/student/help-from-tutor/scheduled" && (
            <ScheduledRequests scheduledRequests={scheduledRequests} />
          )}

          {/* {path === "/student/help-from-tutor/completed" && (
            <CompletedRequests
              feedbackMessage={feedbackMessage}
              completedRequests={completedRequests}
              isFeedback={isFeedback}
              rating={this.state.rating}
              handleInputFeedback={this.handleInputFeedback}
              feedbackRequest={this.feedbackRequest}
              cancelRequest={this.cancelRequest}
              handleFeedbackRequest={this.handleFeedbackRequest}
              selectedRequest={this.state.selectedRequest}
              handleRating={this.handleRating}
            />
          )} */}
        </div>
      </React.Fragment>
    );
  }
}

export default HelpTutorContainer;
