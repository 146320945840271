import React, { Component } from "react";
import ClassroomScheduling from "../../components/OrgAdmin/ClassroomScheduling/ClassroomScheduling";
import { userID } from "../../services/Authentication/AuthService";

class ClassroomSchedulingContainer extends Component {
  state = {};

  componentDidMount() {}
  render() {
    return (
      <React.Fragment>
        <ClassroomScheduling />
      </React.Fragment>
    );
  }
}
export default ClassroomSchedulingContainer;
