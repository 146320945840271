import React from "react";

const LabType = ({ isType, labType }) => {
  return (
    <React.Fragment>
      <div className="col-md-12 row mb-35">
        <div
          className={`tabItem ${isType === "formal" ? "tabItemActive" : ""}`}
          onClick={() => labType("formal")}
        >
          Formal Lab
        </div>
        <div
          className={`tabItem ${isType === "informal" ? "tabItemActive" : ""}`}
          onClick={() => labType("informal")}
        >
          Informal Lab
        </div>
      </div>
    </React.Fragment>
  );
};

export default LabType;
