import React, { Component } from "react";
import Dashboard from "../../components/Teachers/Dashboard/Dashboard";
import { userID } from "../../services/Authentication/AuthService";

class DashboardContainer extends Component {
  state = {};

  componentDidMount() {}
  render() {

    return (
      <React.Fragment>
        <Dashboard />
      </React.Fragment>
    );
  }
}

export default DashboardContainer;
