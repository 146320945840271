import React from "react";

const Select = ({
  name,
  optname,
  label,
  value,
  options,
  error,
  placeholder,
  ...rest
}) => {
  return (
    <div className="form-group">
      {/* <label className="label-heading" htmlFor={name}>
        {label}
      </label> */}
      <select
        name={name}
        optname={optname}
        id={name}
        value={value}
        {...rest}
        className="form-control"
      >
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={option._id} value={option.name}>
            {optname ? option[optname] : option.name}
          </option>
        ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select;
