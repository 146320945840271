import DashboardContainer from "./DashboardContainer";
import Calenders from "../../components/Schedule/Calender";
import AssignmentContainer from "./AssignmentContainer";
import TecherProfileConatainer from "./TeacherProfileContainer";
import TeacherQueriesContainer from "./TeacherQueriesContainer";
import TeacherSession from "./TeacherSession";

export default [
  {
    path: "dashboard",
    component: DashboardContainer,
  },
  {
    path: "calender",
    component: Calenders,
  },
  {
    path: "assignment-correction/:id/:cc",
    component: AssignmentContainer,
  },
  {
    path: "assignments-list",
    component: AssignmentContainer,
  },
   {
    path: 'profile',
    component: TecherProfileConatainer

  }
  , {
    path: 'queries-list',
    component: TeacherQueriesContainer

  }, {
    path: 'queries-inprogess',
    component: TeacherQueriesContainer
  }, {
    path: 'queries-closed',
    component: TeacherQueriesContainer
  },
  {
    path: 'teacher-session',
    component: TeacherSession
  }
];
