import React, { useEffect, useState } from "react";
import UserInfo from '../../components/admin/newUser/userInfo'
import { useSelector, useDispatch } from 'react-redux';
import { postActiveUser, postRejectUser } from '../../services/Admin/commonService';
import { useHistory } from "react-router-dom";
import * as Inactive from '../../store/actions/inactiveUser';

const InactiveUserContainer = (props) => {
    const dispatch = useDispatch()

    const [param, setParam] = useState('')
    const [user, setUser] = useState();
    let lis = useSelector(state => {
        return state.headUser.userList
    })
    const history = useHistory();

    let getUser;
    let para = props.match.params.id
    // useEffect(() => {
    //     let getUser = lis.find(item => {
    //         console.log('id-' + item.userID)
    //         console.log(('params' + props.match.params.id))
    //         return item.userID === props.match.params.id
    //     })
    //     console.log('id-' + props.match.params.id)
    // },[para])
    const postActivate = (data) => {

        if (data.isActive) {
            postActiveUser(data).then(result => {
                if (result.status=== 200) {
                    dispatch(Inactive.getList())
                    setTimeout(() => {
                        history.replace('/admin')

                    }, 2000);
                } else {
                    console.log('error')
                }

            }).catch(err => console.log('err-' + err))
        }
        else{
            postRejectUser(data).then(result => {
                if (result.status=== 200) {
                    dispatch(Inactive.getList())
                    setTimeout(() => {
                        history.replace('/admin')

                    }, 2000);
                } else {
                    console.log('error')
                }

            }).catch(err => console.log('err-' + err))
        }

    }

    const [info, setInfo] = useState();
    useEffect(() => {
        setParam(props.match.params.id)
        // setUser(JSON.parse(sessionStorage.getItem('inactiveUser')))
        let getUser = lis.find(item => Number(item.userID) === Number(props.match.params.id))
        setUser(getUser)

    }, [props.match.params.id])







    return (<>
        {user && <UserInfo param={param} user={user} active={postActivate} />}
    </>)

}


export default InactiveUserContainer;