import React, { useState, useEffect, Fragment } from "react";
import Certificate from "../../components/Students/Certificatee/certificatee";
import { PDFViewer } from '@react-pdf/renderer';
import Profile from "../../components/Share/Profile"
import { userID } from "../../services/Authentication/AuthService";
import { getUserInfo, } from "../../services/Admin/commonService";

const CertificateContainer = () => {
    const [userInfo, setUserInfo] = useState('')
    useEffect(() => {
        getUser()
    }, [])

    const getUser = () => {
        getUserInfo(+userID()).then(res => {
            if (res.status === 200) {
                if (res.data.status) {
                    setUserInfo(res.data.userInfo)
                }
            } else {
                setUserInfo('')
            }
        }).catch(err => { console.log('err') })
    }
    return <PDFViewer style={{ width: '100%', height: '1000px' }}><Certificate userInfo={userInfo} /></PDFViewer>
}

export default CertificateContainer