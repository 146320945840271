import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./Sessions.module.css";

const SessionPreview = ({ sessionIndividual }) => {
  const data = sessionIndividual;
  const height = window.innerHeight - 100;
  return (
    <div className="row">
      <div className="col-md-12 text-right">
        <Link to={`/student/sessions`}>
          <button
            className={`btn btn-sm btn-secondary mr-0 ${styles.testBack}`}
          >
            Back
          </button>
        </Link>
      </div>
      <div className="col-md-6">
        <div
          className={`${styles.sessionPreviewDiv}`}
          style={{ height: height }}
        >
          <h2>Session Details</h2>
          <table className="table">
            <tr>
              <th>Curriculum</th>
              <td>{data.curriculum}</td>
            </tr>
            <tr>
              <th>Subject</th>
              <td>{data.subject}</td>
            </tr>
            {/* <tr>
              <th>Topic</th>
              <td>{data.topic}</td>
            </tr> */}
            {/* <tr>
              <th>Chapter</th>
              <td>{data.chapter}</td>
            </tr> */}
            <tr>
              <th style={{ backgroundColor: "none" }}>Start Date &amp; Time</th>
              <td>
                {moment
                  .parseZone(data.start)
                  .local()
                  .format("DD/MM/YYYY HH:mm")}
              </td>
            </tr>
            <tr>
              <th>End Date &amp; Time</th>
              <td>
                {moment.parseZone(data.end).local().format("DD/MM/YYYY HH:mm")}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SessionPreview;
