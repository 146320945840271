import React from "react";
import parse from "html-react-parser";
import { deleteCourse } from "../../../services/Admin/courseService"

const ViewCourseContent = ({ courseListIndividual, closeViewCourse }) => {
  const data = courseListIndividual ? courseListIndividual : [];

  return (
    <div className="row justify-content-center">
      <div className="closePage">
        {/* <h3>View Course Content</h3> */}
        <h3>View Chapter</h3>
        <button className="btn btn-sm btn-danger" onClick={()=>{deleteCourse(data._id); closeViewCourse();}}>
          Delete
        </button>
        <button className="btn btn-sm btn-secondary mr-2" onClick={closeViewCourse}>
          Close
        </button>
      </div>
      <div className="col-md-12">
        <div >
          <div className="card">
            <div className="card-body">
              <div className="text-view">
                <label>Curriculum Name</label>
                <span>{data && data.curriculum}</span>
              </div>
              <div className="text-view">
                <label>Subject</label>
                <span>{data && data.subject}</span>
              </div>
              <div className="text-view">
                <label>Subject Info</label>
                <span>{data.subjectInfo ? parse(data.subjectInfo) : ""}</span>
              </div>
              <div className="text-view">
                <label>Min Credits</label>
                <span>{data && data.minCredits}</span>
              </div>
              <div className="text-view">
                <label>Max Credits</label>
                <span>{data && data.maxCredits}</span>
              </div>
              <div className="text-view">
                <label>Practical Session</label>
                <span>{data && data.practicalSession}</span>
              </div>
              <div className="text-view">
                <label>Topic Name</label>
                <span>{data && data.topic}</span>
              </div>
              <div className="text-view">
                <label>Topic Info</label>
                <span>{data.topicInfo ? parse(data.topicInfo) : ""}</span>
              </div>
            </div>

            <div className="col-md-12 mb-3" style={{ padding: "0px 22px" }}>
              <h5 style={{ fontSize: "18px" }}>Chapters Details</h5>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Chapter Name</th>
                    <th>Chapter Info</th>
                    <th>Chapter Video</th>
                  </tr>
                </thead>
                <tbody>
                  {data.chapters &&
                    data.chapters.map((ch, index) => {
                      return (
                        <tr>
                          <td> {ch.chapter} </td>
                          {/* <td>{ch.chapterInfo ? parse(ch.chapterInfo) : ""}{ch.chapterInfo.split("oembed")[1].split(">")[0]}</td> */}
                          {/* {console.log(ch.chapterInfo.split('oembed')[1])}  */}
                          <td> {ch.chapterInfo ? parse(ch.chapterInfo) : ""} {ch.chapterInfo.split('oembed')[1] !==undefined ? ch.chapterInfo.split("oembed")[1].split(">")[0] :null} </td>
                          <td style={{ width: "70%" }}>
                            {ch.fileLocation ? ch.fileLocation : ""}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCourseContent;
