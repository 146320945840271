import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import parse from "html-react-parser";
import {
  InputComponent,
  SelectComponent,
  DatepickerComponent,
  Button,
  TimeInputComponent
} from "../../../common/FormComponents";
import { toCapitalWord } from "../../../utils/utils";
import { assignmentValidation } from "../../../validations/AdminValidations";
import '../../../common/Formik/Common.css'
import Autocomplete1 from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import TextError from "../../../common/Formik/TextError";
import {
  curriculumListFun,
  subjectListFun,
  topicListFun,
  chapterListFun,
} from "../../../utils/utils";
import { useHistory } from "react-router-dom";

const AddAssessment = (props) => {
  const history = useHistory();

  const {
    data,
    curriculumList,
    subjectsList,
    topicsList,
    chaptersList,
    templatesList,
    questionsList,
    handleInput,
    handleDates,
    handleInputLab,
    handleGetList,
    handleGetTemplateQuestions,
    handleSubmit,
    handleInput1,
    mode,
    time,
    saveTime

  } = props;

  const [maxQues, setMaxQues] = useState(false);

  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);
  let topicOptions = topicListFun(topicsList);
  let chapterOptions = chapterListFun(chaptersList);

  let questionIds = [];
  let questionTypes = [];
  let correctionModeValue;
  let totalMarks = 0;
  const optioncheckbox = (e, qID, marks, questionType) => {
    if (e.target.checked) {
      questionIds.push({ questionID: qID });
      questionTypes.push(questionType);
      totalMarks += parseInt(marks);
    } else {
      let index = questionIds.findIndex((x) => x.questionID === qID);
      let index1 = questionTypes.indexOf(questionType);
      questionIds.splice(index, 1);
      questionTypes.splice(index1, 1);
      totalMarks -= parseInt(marks);
    }
    if (data.assignmentMode === "Mandatory") {
      if (
        questionIds.length <= data.maxQuestions &&
        totalMarks <= data.maxWeightage
      )
      {
        // alert("add questions");
      } else {
        e.target.checked = false;
        let index = questionIds.findIndex((x) => x.questionID === qID);
        questionIds.splice(index, 1);
        totalMarks -= parseInt(marks);
        alert("max questions reached");
      }
    } else {
      if (questionIds.length <= data.maxQuestions) {
        // alert("add questions optional");
      } else {
        e.target.checked = false;
        let index = questionIds.findIndex((x) => x.questionID === qID);
        questionIds.splice(index, 1);
        totalMarks -= parseInt(marks);
        alert("max questions reached optional");
      }
    }

    function checkQuesionType(qt) {
      return qt === "Text";
    }

    let correctionMode = questionTypes.some(checkQuesionType);

    if (correctionMode == true) {
      correctionModeValue = "manual";
    } else {
      correctionModeValue = "automatic";
    }
  };

  const assignmentMode = (e) => {
    let value = e.target.value;
    data.optionalQuestions = "";
    data.optQuestionMarks = "";
    data.maxWeightage = "";
    if (value === "Mandatory") {
      setMaxQues(false);
    } else {
      setMaxQues(true);
    }
  };

  const optionalMaxWeitage = (e, name) => {
    if (name === "optionalQuestions") {
      data.maxWeightage = e.target.value * data.optQuestionMarks;
    } else if (name === "optQuestionMarks") {
      data.maxWeightage = data.optionalQuestions * e.target.value;
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="d-flex flex-row">
            <h1 className="form-heading">Add Assessment</h1>
            <button
              className="btn btn-sm btn-danger mb-4"
              onClick={() => history.goBack()}
            >
              Close
            </button>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={data}
                validationSchema={assignmentValidation}
                validateOnMount
                enableReinitialize
                validateOnChange={false} // turn off fields validation
                validateOnBlur={false} // turn off fields validation
              >
                {(props) => {
                  const { isValid } = props;
                  return (
                    <React.Fragment>
                      <Form>
                        <div className="row">
                          <Field
                            col="col-md-6"
                            label="Assessment Name"
                            name="assessmentName"
                            component={InputComponent}
                            onChange={(e) => handleInput(e)}
                            value={
                              data.assessmentName ? data.assessmentName : ""
                            }
                          />

                          <Field
                            col="col-md-6"
                            label="Assessment Type"
                            name="type"
                            value={data.type ? data.type : ""}
                            options={[
                              { _id: "1", name: "Public" },
                              { _id: "2", name: "Private" },
                            ]}
                            component={SelectComponent}
                            onChange={(e) => handleInput(e)}
                          />

                          <Field
                            col="col-md-6"
                            label="Curriculum"
                            name="curriculum"
                            value={data.curriculum ? data.curriculum : ""}
                            optname="curriculum"
                            options={curriculumOptions}
                            component={SelectComponent}
                            onChange={(e) => handleInput(e, "curriculum")}
                          />
                          {/* <div className="form-group col-md-6">
                            <label className="label-heading" htmlFor="curriculum">
                              curriculums
                            </label>

                            {mode === 'Add' && <Autocomplete1
                              id="combo-box-demo"
                              // className="form-control"
                              options={curriculumOptions}
                              getOptionLabel={(option) => option.curriculum}
                              onChange={(event, value) => {
                                if (value === null) {
                                  handleInput1('', "curriculum")
                                  return
                                }
                                handleInput1(value.curriculum, "curriculum")
                              }}
                              disableClearable={true}

                              style={{ width: '100%', height: '38px' }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />}
                            {mode === 'Edit' && data.curriculum && <Autocomplete1
                              id="combo-box-demo"
                              // className="form-control"
                              options={curriculumOptions}
                              getOptionLabel={(option) => option.curriculum}
                              onChange={(event, value) => {
                                if (value === null) {
                                  handleInput1('', "curriculum")
                                  return
                                }
                                handleInput1(value.curriculum, "curriculum")
                              }}
                              disableClearable={true}
                              defaultValue={{ curriculum: data.curriculum ? data.curriculum : '' }}
                              style={{ width: '100%', height: '38px' }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />}
                            <ErrorMessage component={TextError} name="curriculum" />
                          </div> */}

                          <Field
                            col="col-md-6"
                            name="subject"
                            label="Subject Name"
                            optname="subject"
                            value={data.subject ? data.subject : ""}
                            options={subjectOptions}
                            component={SelectComponent}
                            onChange={(e) => handleInput(e, "subject")}
                          />
                          {/* <div className="form-group col-md-6">
                            <label className="label-heading" htmlFor="curriculum">
                              subject
                            </label>

                            {mode === 'Add' && <Autocomplete1
                              id="combo-box-demo"
                              // className="form-control"
                              options={subjectOptions}
                              getOptionLabel={(option) => option.subject}
                              onChange={(event, value) => {
                                if (value === null) {
                                  handleInput1('', "subject")
                                  return
                                }
                                handleInput1(value.subject, "subject")
                              }}

                              style={{ width: '100%', height: '38px' }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />}
                            {mode === 'Edit' && data.subject && <Autocomplete1
                              id="combo-box-demo"
                              // className="form-control"
                              options={subjectOptions}
                              getOptionLabel={(option) => option.subject}
                              onChange={(event, value) => {
                                if (value === null) {
                                  handleInput1('', "subject")
                                  return
                                }
                                handleInput1(value.subject, "subject")
                              }}
                              disableClearable={true}
                              defaultValue={{ subject: data.subject ? data.subject : '' }}
                              style={{ width: '100%', height: '38px' }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />}
                            <ErrorMessage component={TextError} name="subject" />
                          </div> */}

                          {/* <Field
                            col="col-md-6"
                            name="topic"
                            label="Topic Name"
                            optname="topic"
                            value={data.topic ? data.topic : ""}
                            options={topicOptions}
                            component={SelectComponent}
                            onChange={(e) => handleInput(e, "topic")}
                          /> */}
                          {/* <div className="form-group col-md-6">
                            <label className="label-heading" htmlFor="curriculum">
                              topic
                            </label>

                            {mode === 'Add' && <Autocomplete1
                              id="combo-box-demo"
                              // className="form-control"
                              options={topicOptions}
                              getOptionLabel={(option) => option.topic}
                              onChange={(event, value) => {
                                if (value === null) {
                                  handleInput1('', "topic")
                                  return
                                }
                                handleInput1(value.topic, "topic")
                              }}

                              style={{ width: '100%', height: '38px' }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />}
                            {mode === 'Edit' && data.topic && <Autocomplete1
                              id="combo-box-demo"
                              // className="form-control"
                              options={topicOptions}
                              getOptionLabel={(option) => option.topic}
                              onChange={(event, value) => {
                                if (value === null) {
                                  handleInput1('', "topic")
                                  return
                                }
                                handleInput1(value.topic, "topic")
                              }}
                              disableClearable={true}
                              defaultValue={{ topic: data.topic ? data.topic : '' }}
                              style={{ width: '100%', height: '38px' }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />}
                            <ErrorMessage component={TextError} name="topic" />
                          </div> */}
                          {/* <Field
                            col="col-md-6"
                            name="chapter"
                            label="Chapter Name"
                            optname="chapter"
                            value={data.chapter ? data.chapter : ""}
                            options={chapterOptions}
                            component={SelectComponent}
                            onChange={(e) => handleInput(e, "chapter")}
                          /> */}
                          {/* <div className="form-group col-md-6">
                            <label className="label-heading" htmlFor="curriculum">
                              chapter
                            </label>

                            {mode === 'Add' && <Autocomplete1
                              id="combo-box-demo"
                              // className="form-control"
                              options={chapterOptions}
                              getOptionLabel={(option) => option.chapter}
                              onChange={(event, value) => {
                                if (value === null) {
                                  handleInput1('', "chapter")
                                  return
                                }

                                handleInput1(value.chapter, "chapter")
                              }}

                              style={{ width: '100%', height: '38px' }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />}
                            {mode === 'Edit' && data.chapter && <Autocomplete1
                              id="combo-box-demo"
                              // className="form-control"
                              options={chapterOptions}
                              getOptionLabel={(option) => option.chapter}
                              onChange={(event, value) => {
                                if (value === null) {
                                  handleInput1('', "chapter")
                                  return
                                }
                                handleInput1(value.topic, "chapter")
                              }}
                              disableClearable={true}
                              defaultValue={{ chapter: data.chapter ? data.chapter : '' }}
                              style={{ width: '100%', height: '38px' }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />}
                            <ErrorMessage component={TextError} name="chapter" />
                          </div> */}

                          <DatepickerComponent
                            col="col-md-6"
                            name="startdate"
                            label="Start Date (MM/DD/YYYY)"
                            minDate={new Date()}
                            selected={data.startdate ? data.startdate : ""}
                            dateFormat="MM/dd/yyyy"
                            onChange={(date) => handleDates(date, "startdate")}
                          />

                          <DatepickerComponent
                            col="col-md-6"
                            name="enddate"
                            label="End Date (MM/DD/YYYY)"
                            startDate={data.startdate}
                            endDate={data.enddate}
                            minDate={data.startdate}
                            selected={data.enddate ? data.enddate : ""}
                            dateFormat="MM/dd/yyyy"
                            onChange={(date) => {
                              console.log('', data);
                              handleDates(date, "enddate")
                            }}
                          />

                          {/* 
                          <Field
                            col="col-md-6"
                            label="Start Time"
                            name="startTime"
                            component={TimeInputComponent}
                            onChange={(e) => saveTime(e.target.value, 'startTime')}

                            value={
                              time.startTime ? time.startTime : ""
                            }
                          />
                          <Field
                            col="col-md-6"
                            label="End Time"
                            name="endTime"
                            component={TimeInputComponent}
                            onChange={(e) => {
                              saveTime(e.target.value, 'endTime')
                            }}
                            value={
                              time.endTime ? time.endTime : ""
                            }
                          /> */}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="endTime">Start Time</label>
                              <input
                                type="time"
                                className="form-control"
                                name="startTime"
                                onChange={(e) => {
                                  console.log('eee', e.target.value)
                                  saveTime(e.target.value, 'startTime')
                                }}
                                value={
                                  time.startTime ? time.startTime : ""
                                }

                              />

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="endTime">End Time</label>
                              <input
                                type="time"
                                className="form-control"
                                name="endTime"
                                onChange={(e) => {
                                  console.log('eee', e.target.value)
                                  saveTime(e.target.value, 'endTime')
                                }}
                                value={
                                  time.endTime ? time.endTime : ""
                                }

                              />

                            </div>
                          </div>


                          <Field
                            col="col-md-6"
                            name="assignmentMode"
                            label="Assignment Mode"
                            value={
                              data.assignmentMode ? data.assignmentMode : ""
                            }
                            options={[
                              { _id: "1", name: "Mandatory" },
                              { _id: "2", name: "Optional" },
                            ]}
                            component={SelectComponent}
                            onChange={(e) => {
                              assignmentMode(e);
                              handleInput(e);
                            }}
                          />

                          <Field
                            col="col-md-6"
                            name="maxQuestions"
                            label="Max Questions"
                            value={data.maxQuestions ? data.maxQuestions : ""}
                            component={InputComponent}
                            onChange={(e) => handleInput(e)}
                          />

                          {data.assignmentMode === "Optional" && (
                            <Field
                              col="col-md-6"
                              type="text"
                              name="optionalQuestions"
                              label="Optional Questions"
                              value={data.optionalQuestions}
                              component={InputComponent}
                              onChange={(e) => {
                                optionalMaxWeitage(e, "optionalQuestions");
                                handleInput(e);
                              }}
                            />
                          )}

                          {data.assignmentMode === "Optional" && (
                            <Field
                              col="col-md-6"
                              label="Question Marks"
                              name="optQuestionMarks"
                              value={
                                data.optQuestionMarks
                                  ? data.optQuestionMarks
                                  : ""
                              }
                              options={[
                                { _id: "1", name: 1 },
                                { _id: "2", name: 2 },
                                { _id: "3", name: 3 },
                                { _id: "4", name: 5 },
                                { _id: "5", name: 10 },
                              ]}
                              component={SelectComponent}
                              onChange={(e) => {
                                optionalMaxWeitage(e, "optQuestionMarks");
                                handleInput(e);
                              }}
                            />
                          )}

                          <Field
                            col="col-md-6"
                            type="text"
                            name="maxWeightage"
                            label="Max Weightage"
                            value={data.maxWeightage ? data.maxWeightage : ""}
                            component={InputComponent}
                            onChange={(e) => handleInput(e)}
                            disabled={maxQues}
                          />

                          <Field
                            col="col-md-6"
                            name="labSession"
                            label="Lab Session"
                            value={
                              data.labSession === true
                                ? "Yes"
                                : data.labSession === false
                                  ? "No"
                                  : ""
                            }
                            options={[
                              { _id: "1", name: "Yes" },
                              { _id: "2", name: "No" },
                            ]}
                            component={SelectComponent}
                            onChange={(e) => handleInputLab(e)}
                          />

                          <Field
                            col="col-md-6"
                            name="assessmentType"
                            label="Select Category"
                            value={
                              data.assessmentType ? data.assessmentType : ""
                            }
                            options={[
                              { _id: "1", name: "Students" },
                              { _id: "2", name: "Use Template" },
                            ]}
                            component={SelectComponent}
                            onChange={(e) => handleInput(e)}
                          />

                          <Button
                            type="submit"
                            className="btn btn-sm btn-primary form-btn mb-4"
                            label="List"
                            disabled={!isValid}
                            onClick={() => handleGetList(props.values)}
                          />
                        </div>

                        {data.assessmentType === "Use Template" &&
                          templatesList &&
                          templatesList.length > 0 && (
                            <div className="col-md-12 row mt-3 mb-3 p-0">
                              {templatesList.length > 0 &&
                                templatesList.map((q) => {
                                  if (q.questionmarks === data.questionMarks) {
                                    return (
                                      <div className="col-md-6" key={q._id}>
                                        <input
                                          type="radio"
                                          name="templateRadio"
                                          onClick={() =>
                                            handleGetTemplateQuestions(
                                              q.templateID
                                            )
                                          }
                                        />{" "}
                                        &nbsp;
                                        {q.templateID} - {q.templateName}
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          )}

                        {questionsList && questionsList.length > 0 && (
                          <div className="col-md-12">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>ID</th>
                                  <th>Question</th>
                                  <th>Question Type</th>
                                  <th>Marks</th>
                                </tr>
                              </thead>
                              <tbody>
                                {questionsList.length > 0 &&
                                  questionsList.map((ql) => {
                                    if (
                                      data.assignmentMode === "Optional"
                                    ) {
                                      return (
                                        <tr key={ql._id}>
                                          <td
                                            style={{
                                              width: "50px",
                                              textAlign: "center",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              name="optioncheckbox"
                                              value
                                              onChange={(e) =>
                                                optioncheckbox(
                                                  e,
                                                  ql.questionID,
                                                  ql.questionmarks,
                                                  ql.questionType
                                                )
                                              }
                                            />
                                          </td>
                                          <td
                                            style={{
                                              width: "105px",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {ql.questionID}
                                          </td>
                                          <td>
                                            <span>{ql.question ? parse(ql.question) : ""} </span>
                                          </td>
                                          <td>
                                            {ql.questionType
                                              ? toCapitalWord(ql.questionType)
                                              : ""}
                                          </td>
                                          <td>
                                            {ql.questionmarks
                                              ? ql.questionmarks
                                              : ""}
                                          </td>
                                        </tr>
                                      );
                                    } else if (
                                      data.assignmentMode === "Mandatory"
                                    ) {
                                      return (
                                        <tr key={ql._id}>
                                          <td
                                            style={{
                                              width: "50px",
                                              textAlign: "center",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              name="optioncheckbox"
                                              value
                                              onChange={(e) =>
                                                optioncheckbox(
                                                  e,
                                                  ql.questionID,
                                                  ql.questionmarks,
                                                  ql.questionType
                                                )
                                              }
                                            />
                                          </td>
                                          <td
                                            style={{
                                              width: "105px",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {ql.questionID}
                                          </td>
                                          <td>
                                            <span>{ql.question ? parse(ql.question) : ""}</span>
                                          </td>
                                          <td>
                                            {ql.questionType
                                              ? toCapitalWord(ql.questionType)
                                              : ""}
                                          </td>
                                          <td>
                                            {ql.questionmarks
                                              ? ql.questionmarks
                                              : ""}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}
                              </tbody>
                            </table>
                            <Button
                              type="submit"
                              className="btn btn-primary form-btn mb-4"
                              label="Submit"
                              disabled={!isValid}
                              onClick={() =>
                                handleSubmit(
                                  props,
                                  questionIds,
                                  correctionModeValue
                                )
                              }
                              style={{ marginTop: "56px" }}
                            />
                          </div>
                        )}
                      </Form>
                    </React.Fragment>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAssessment;
