import React from "react";
import moment from "moment";
import styles from "./Lab.module.css";

const InformalLab = ({ assignmentList }) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-9 pad-0">
          <div className="row">
            <h4 className="text-heading">Ongoing Assignments</h4>
          </div>
          <div className="row">
            {assignmentList.length > 0 &&
              assignmentList.map((a) => {
                if (a.type === "Public") {
                  return (
                    <div className="col-md-4" key={a._id}>
                      <div className={`cardDiv ${styles.LabDiv}`}>
                        <h5>{a.assessmentName}</h5>
                        <p>
                          Start Date -{" "}
                          {moment(a.startdate).format("DD-MM-YYYY")}
                        </p>
                        <p>
                          End Date - {moment(a.enddate).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          <br />
          {/* 
          <div className="row">
            <h4 className="text-heading">Upcoming Assignments</h4>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className={`cardDiv ${styles.LabDiv}`}>
                <h5>ECE 1st Mid Term</h5>
                <p>Start Date - 21-12-2020</p>
                <p>End Date - 30-12-2020</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className={`cardDiv ${styles.LabDiv}`}>
                <h5>EEE 2nd Mid Term</h5>
                <p>Start Date - 21-12-2020</p>
                <p>End Date - 30-12-2020</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className={`cardDiv ${styles.LabDiv}`}>
                <h5>EEE 1st Sem</h5>
                <p>Start Date - 21-12-2020</p>
                <p>End Date - 30-12-2020</p>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <h4 className="text-heading">Completed Assignments</h4>
            <p className="viewMore">
              View More <i className="las la-arrow-circle-right"></i>
            </p>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className={`cardDiv ${styles.LabDiv}`}>
                <h5>ECE 1st Mid Term</h5>
                <p>Start Date - 21-12-2020</p>
                <p>End Date - 30-12-2020</p>
                <span className={`${styles.LabResult} ${styles.labPass}`}>
                  Pass - <small>70%</small>
                </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className={`cardDiv ${styles.LabDiv}`}>
                <h5>EEE 2nd Mid Term</h5>
                <p>Start Date - 21-12-2020</p>
                <p>End Date - 30-12-2020</p>
                <span className={`${styles.LabResult} ${styles.labFail}`}>
                  Fail
                </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className={`cardDiv ${styles.LabDiv}`}>
                <h5>EEE 1st Sem</h5>
                <p>Start Date - 21-12-2020</p>
                <p>End Date - 30-12-2020</p>
                <span className={`${styles.LabResult} ${styles.labPassAvg}`}>
                  Pass - <small>60%</small>
                </span>
              </div>
            </div>
          </div> */}
        </div>
        <div className="col-md-3">
          <h4 className="text-heading">Assignment Summary</h4>
          <div className="col-md-12 mb-3" style={{ overflow: "hidden" }}>
            <div className={styles.LabSummary}>
              <p>
                Taken
                <span class={`badge badge-primary ${styles.badge}`}>10</span>
              </p>
              <p>
                Pass
                <span class={`badge badge-success ${styles.badge}`}>16</span>
              </p>
              <p>
                Fail <span class={`badge badge-danger ${styles.badge}`}>4</span>
              </p>
            </div>
          </div>

          <br />

          <h4 className="text-heading">Subject Wise Tests</h4>
          <div className="col-md-12" style={{ overflow: "hidden" }}>
            <div className={styles.LabSummary}>
              <p>
                Physics
                <span class={`badge badge-primary ${styles.badge}`}>10</span>
              </p>
              <p>
                Maths
                <span class={`badge badge-success ${styles.badge}`}>6</span>
              </p>
              <p>
                English
                <span class={`badge badge-danger ${styles.badge}`}>4</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InformalLab;
