import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import QueriesList from "../../components/Teachers/Queries/Queries-List";
import QueriesInprogress from "../../components/Teachers/Queries/Queries-inprogess";
import QueriesClosed from "../../components/Teachers/Queries/Queries-cloased";
import QueryType from "../../components/Teachers/Queries/Queries-Type";
import { getUserInfo, } from "../../services/Admin/commonService";
import { getQueriesList, changeStatus, addQuery, updateRating} from "../../services/Teacher/QueriesService";
import {
    userID,
    userCurriculumCode,
    userCurriculumsList,
} from "../../services/Authentication/AuthService";
import DisplayErrorMessage from "../../components/Share/DisplayErrorMessage";

import { queryUpdate } from "../../services/Student/QueryService";


const TeacherQueriesContainer = (props) => {
    const [userInfo, setUserInfo] = useState('')
    const [queriesOpen, setQueriesOpen] = useState([]);
    const [QueriesInprogressList, setQueriesInprogressList] = useState([]);
    const [queriesClosed, setQueriesClosed] = useState([])
    const [error, setError] = useState('')
    const [isLoading, setIsloading] = useState(false)
    const [showeditor,setShowEditor]=useState(false)
    let location = useLocation();
    const queryType = (type) => {
        if (type === 'open') {
            props.history.push('/teacher/queries-list')
        }
        if (type === 'inprogress') {
            props.history.push('/teacher/queries-inprogess')
        }
        if (type === 'closed') {
            props.history.push('/teacher/queries-closed')
        }
    }
    useEffect(() => {
        let curr = userCurriculumsList();
        getUser()

    }, [])

    const newQuerry = () => {
        addQuery().then(res => {
            if (res.status === 200) {
                // console.log('success')
            }
        }).catch(err => console.log(err))
    }
    const insertNewQuery = (data, message) => {
        //console.log('newww-' + message)
        // console.log('nnnnn-' + JSON.stringify(data))
        setIsloading(true)
        if (data) {
            data.messages.push({
                message: message,
                author_id: userID(),
                createdAt: new Date().getTime(),
            })
            const payload = {
                chatID: data.chatID,
                sender_id: data.sender_id,
                curriculum: data.curriculum,
                curriculumCode: data.curriculumCode,
                subject: data.subject,
                topic: data.topic,
                chapter: data.chapter,
                updatedAt: new Date().getTime(),
                messages: data.messages,
            }
            queryUpdate(payload).then(res => {
                if (res.status === 200) {
                    // console.log('res' + JSON.stringify(res.data))
                    setIsloading(false)
                }
            }).catch(err => { console.log('err') })
        }
        setIsloading(false)

    }

    const getUser = () => {
        setIsloading(true)
        let open = []
        let closed = []
        let inprogress = []
        getUserInfo(+userID()).then(res => {
            if (res.status === 200) {
                if (res.data.status) {
                    //setUserInfo(res.data.userInfo)
                    // console.log('user-' + JSON.stringify(res.data))
                    getQueriesList({ curriculum: res.data.userInfo.curriculum, curriculumCode: res.data.userInfo.curriculumCode }).then(res => {
                        const data = res.data.data
                        if (res.data.status && data.length > 0) {
                            data.map(q => {
                                if (q.status === 0) {
                                    open.push(q)
                                    //  setQueriesOpen(state => [...state, q])
                                } if (q.status === 1) {
                                    inprogress.push(q)
                                    // setQueriesInprogressList(state => [...state, q])
                                }
                                if (q.status === 2) {
                                    closed.push(q)
                                    //setQueriesClosed(state => [...state, q])
                                }
                            })
                            setQueriesOpen(open)
                            setQueriesInprogressList(inprogress)
                            setQueriesClosed(closed)
                        }
                        // console.log('set' + JSON.stringify(queriesOpen))
                    }).catch(err => { console.log('err') })
                    setIsloading(false)
                }
            } else {
                setUserInfo('')
                setIsloading(false)
            }
        }).catch(err => { console.log('err') })
    }
    const statusChanged = (statusNo, chatId) => {
        //setIsloading(true)
        let payload = {
            chatID: chatId,
            status: statusNo
        }
        changeStatus(payload).then(res => {
            if (res.status === 200) {
                // console.log(res.data.message)
                getUser()
                setIsloading(false)
            } else {
                setError(res.data.message)
            }
            setIsloading(false)
        }).catch(err => console.log('error' + err))
    }
    // if (isLoading) {
    //     return (
    //         <DisplayErrorMessage
    //             message=" Loading queries "
    //             errorSize="big"
    //         />
    //     );
    // }
    if (location.pathname === '/teacher/queries-list') {
        return <Fragment >
            <QueryType queryType={queryType} />

            <QueriesList queriesOpen={queriesOpen} statchange={statusChanged} />
        </Fragment>
    }
    if (location.pathname === '/teacher/queries-inprogess') {
        return <Fragment >
            <QueryType queryType={queryType} />

            <QueriesInprogress QueriesInprogressList={QueriesInprogressList}
                statchange={statusChanged}
                addReplay={insertNewQuery} />
        </Fragment>
    }
    if (location.pathname === '/teacher/queries-closed') {
        return <Fragment >
            <QueryType queryType={queryType} />

            <QueriesClosed queriesClosed={queriesClosed} statchange={statusChanged} />
        </Fragment>
    }
    // return <p>text</p>
}

export default TeacherQueriesContainer;