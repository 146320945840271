import React, { useState, useEffect, Fragment } from "react";
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import pic from '../../../assets/Images/certificate.jpg'
import sign from '../../../assets/Images/signature.png'
import { Link } from "react-router-dom";
import { width } from "@material-ui/system";
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        width: '1700px',

        alignItems: 'center'

    },
    section: {
        padding: 10,
        position: 'absolute',
        // alignItems: 'center',
        right: '10%',
        // bottom: '100px',
        width: '50%',
        textAlign: 'center',
        // backgroundColor: 'red',



    }, image: {
        // objectFit: 'objectFit',
        height: '400px',
        width: '1700px',
    },
    head1: {
        fontSize: 26,
        marginTop: '30px'
    },
    head2: {
        fontSize: 18,
    },
    text1: {
        fontSize: 12,
        marginTop: '25px',
    },
    text2: {
        fontSize: 12,
        marginTop: '10px',
    },
    name: {
        marginTop: '5px',
        // width: '100px'

        textDecoration: 'underline'
    },
    sign: {
        marginTop: '10px',
        marginRight: '40px',
        fontSize: 12,
        textAlign: 'right'

    },
    imageSign: {
        height: '70px',
        width: '70px',
        marginTop: '50px',
        marginLeft: '180px',
        textDecoration: 'underline'



    }
});

const Certificate = (props) => {
    let name
    if (props.userInfo.firstName) {
        name = props.userInfo.firstName.toUpperCase() + ' ' + props.userInfo.middleName.toUpperCase() + ' ' + props.userInfo.lastName.toUpperCase()

    }
    return <Document >
        <Page size="A4" style={styles.page} >
            <Image style={styles.image} src={pic} />

            <View style={styles.section}>
                <Text style={styles.head1}>CERTIFICATE</Text>
                <Text style={styles.head2}>of Completion</Text>
                <Text style={styles.text1}>THE CERTIFICATE IS  PRODUDLY PRESTENTED TO </Text>
                <Text style={styles.name}>{name}</Text>
                <Text style={styles.text1}>Has successfully completed the {props.userInfo.curriculum} on date</Text>
                <Image style={styles.imageSign} src={sign} />

                <Text style={styles.sign}>Sign</Text>

            </View>

        </Page>
    </Document>
}

export default Certificate