import moment from "moment";

const toCapitalWord = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

const curriculumListFun = (data) => {
  if (data && data.length > 0) {
    return data;
  } else {
    return [];
  }
};

const getCurriculumCodeFun = (options, name) => {
  let curriculumCode;
  options.map((cc) => {
    if (cc.curriculum === name) {
      curriculumCode = cc.curriculumCode;
    }
  });
  return curriculumCode;
};

const subjectListFun = (data) => {
  if (data && data.length > 0) {
    return data;
  } else {
    return [];
  }
};

const topicListFun = (data) => {
  if (data && data.length > 0) {
    return data;
  } else {
    return [];
  }
};

const chapterListFun = (data) => {
  if (data && data.length > 0) {
    return data;
  } else {
    return [];
  }
};

const teacherListFun = (data) => {
  if (data && data.length > 0) {
    return data;
  } else {
    return [];
  }
};

const dateFormatMMDDYYYY = (date) => {
  return moment(date).format("MM/DD/YYYY");
};

const dateFormatDDMMYYYY = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export {
  toCapitalWord,
  curriculumListFun,
  getCurriculumCodeFun,
  subjectListFun,
  topicListFun,
  chapterListFun,
  teacherListFun,
  dateFormatMMDDYYYY,
  dateFormatDDMMYYYY,
};
