import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const getAllCurriculum = () => {
  return http.get(apiUrl + "/getAllCurriculum", {
    headers: headers,
  });
};
export const getUpdateCurriculum = (payload) => {
  return http.post(apiUrl + "/updateCurriculumUser",payload, {
    headers: headers,
  });
};

export const CopyCurriculumm = (id) => {
  return http.post(apiUrl + "/copycurriculum/"+id, {
    headers: headers,
  });
};

export const CopyCurrSubCourseQuestion = (id) => {
  return http.post(apiUrl + "/copycurriculumtest/"+id, {
    headers: headers,
  });
};

export function saveCurriculum(payload, curriculumId) {
  if (!curriculumId) {
    //Add Curriculum
    return http.post(apiUrl + "/createCurriculum", payload, {
      headers: headers,
    });
  } else {
    //Edit Curriculum
    const editpayload = {
      condition: { _id: curriculumId },
      updateData: payload,
    };
    delete payload._id;
    return http.post(apiUrl + "/updateCurriculum", editpayload, {
      headers: headers,
    });
  }
}

export const getCurriculum = (curriculumId) => {
  return http.post(apiUrl + "/getCurriculum", curriculumId, {
    headers: headers,
  });
};
export const deleteCurriculum = (payload) => {
  return http.post(apiUrl + "/removeCurriculum", payload, {
    headers: headers,
  });
};
