import { INAVTIVEUSER, GETLISTUSER, GETSESSION } from '../actions/inactiveUser'

const initialState = {
    userList: [],
    sessionList: []
};

export default (state = initialState, action) => {
    // console.log('check' + JSON.stringify(action.user) )
    switch (action.type) {
        case INAVTIVEUSER:
            const user = action.user
            return { ...state, userList: user }
        case GETLISTUSER:
            return { ...state, userList: action.userList }
        case GETSESSION:
            return { ...state, sessionList: action.sessionList }

    }
    return state;
};