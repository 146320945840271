import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import Button from "../common/button";
import Select from "../common/select";
import Input from "../common/input";
import Autocomplete1 from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import TextError from "../../../common/Formik/TextError";
import {
  curriculumListFun,
  subjectListFun,
  topicListFun,
  chapterListFun,
} from "../../../utils/utils";
import { useHistory } from "react-router-dom";

const AddVideo = (props) => {
  const history = useHistory();

  const {
    mode,
    videoData,
    curriculumList,
    subjectsList,
    topicsList,
    chaptersList,
    handleInput,
    handleFileChange,
    handleFileUpload,
    fileUpload,
    uploadFileChange,
    handleInput1,
  } = props;

  const data = videoData;
  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);
  let topicOptions = topicListFun(topicsList);
  let chapterOptions = chapterListFun(chaptersList);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="d-flex flex-row">

          <h1 className="form-heading">{mode} Video</h1>
          <button
            className="btn btn-sm btn-danger mb-4"
            onClick={() => history.goBack()}
          >
            Close
          </button>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {/* <Select
                    name="curriculum"
                    optname="curriculum"
                    label="Curriulum Name"
                    value={data.curriculum ? data.curriculum : ""}
                    options={curriculumOptions}
                    onChange={(e) => {
                      handleInput(e, "curriculum");
                    }}
                  /> */}
                  <div className="form-group">
                    <label className="label-heading" htmlFor="curriculum">
                      curriculums
                    </label>

                    {mode === 'Add' && <Autocomplete1
                       disablePortal
                      id="combo-box-demo"
                      // className="form-control"
                      options={curriculumOptions}
                      getOptionLabel={(option) => option.curriculum}
                      onChange={(event, value) => {
                        if (value === null) {
                          handleInput1('', "curriculum")
                          return
                        }
                        handleInput1(value.curriculum, "curriculum")
                      }}
                      disableClearable={true}

                      style={{ width: '100%', height: '38px' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />}
                    {mode === 'Edit' && data.curriculum && <Autocomplete1
                      disablePortal
                      id="combo-box-demo"
                      // className="form-control"
                      options={curriculumOptions}
                      getOptionLabel={(option) => option.curriculum}
                      onChange={(event, value) => {
                        if (value === null) {
                          handleInput1('', "curriculum")
                          return
                        }
                        handleInput1(value.curriculum, "curriculum")
                      }}
                      disableClearable={true}
                      defaultValue={{ curriculum: data.curriculum ? data.curriculum : '' }}
                      style={{ width: '100%', height: '38px'  }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />}
                    {/* <ErrorMessage component={TextError} name="curriculum" /> */}
                  </div>
                </div>

                <div className="col-md-6">
                  {/* <Select
                    name="subject"
                    optname="subject"
                    label="Subject Name"
                    value={data.subject ? data.subject : ""}
                    options={subjectOptions}
                    onChange={(e) => handleInput(e, "subject")}
                  /> */}
                  <div className="form-group ">
                    <label className="label-heading" htmlFor="curriculum">
                      subject
                    </label>

                    {mode === 'Add' && <Autocomplete1
                      disablePortal
                      id="combo-box-demo"
                      // className="form-control"
                      options={subjectOptions}
                      getOptionLabel={(option) => option.subject}
                      onChange={(event, value) => {
                        if (value === null) {
                          handleInput1('', "subject")
                          return
                        }
                        handleInput1(value.subject, "subject")
                      }}

                      style={{ width: '100%', height: '38px' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />}
                    {mode === 'Edit' && data.subject && <Autocomplete1
                      disablePortal
                      id="combo-box-demo"
                      // className="form-control"
                      options={subjectOptions}
                      getOptionLabel={(option) => option.subject}
                      onChange={(event, value) => {
                        if (value === null) {
                          handleInput1('', "subject")
                          return
                        }
                        handleInput1(value.subject, "subject")
                      }}
                      disableClearable={true}
                      defaultValue={{ subject: data.subject ? data.subject : '' }}
                      style={{ width: '100%', height: '38px' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />}
                    {/* <ErrorMessage component={TextError} name="subject" /> */}
                  </div>
                </div>

                <div className="col-md-6">
                  {/* <Select
                    name="topic"
                    optname="topic"
                    label="Topic Name"
                    value={data.topic ? data.topic : ""}
                    options={topicOptions ? topicOptions : []}
                    onChange={(e) => handleInput(e, "topic")}
                  /> */}
                  <div className="form-group ">
                    <label className="label-heading" htmlFor="curriculum">
                      topic
                    </label>

                    {mode === 'Add' && <Autocomplete1
                      disablePortal
                      id="combo-box-demo"
                      // className="form-control"
                      options={topicOptions}
                      getOptionLabel={(option) => option.topic}
                      onChange={(event, value) => {
                        if (value === null) {
                          handleInput1('', "topic")
                          return
                        }
                        handleInput1(value.topic, "topic")
                      }}

                      style={{ width: '100%', height: '38px' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />}
                    {mode === 'Edit' && data.topic && <Autocomplete1
                      disablePortal
                      id="combo-box-demo"
                      // className="form-control"
                      options={topicOptions}
                      getOptionLabel={(option) => option.topic}
                      onChange={(event, value) => {
                        if (value === null) {
                          handleInput1('', "topic")
                          return
                        }
                        handleInput1(value.topic, "topic")
                      }}
                      disableClearable={true}
                      defaultValue={{ topic: data.topic ? data.topic : '' }}
                      style={{ width: '100%', height: '38px' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />}
                    {/* <ErrorMessage component={TextError} name="topic" /> */}
                  </div>
                </div>

                <div className="col-md-6">
                  {/* <Select
                    name="chapter"
                    optname="chapter"
                    label="Chapter Name"
                    value={data.chapter ? data.chapter : ""}
                    options={chapterOptions ? chapterOptions : []}
                    onChange={(e) => handleInput(e, "chapter")}
                  /> */}
                  <div className="form-group ">
                    <label className="label-heading" htmlFor="curriculum">
                      chapter
                    </label>

                    {mode === 'Add' && <Autocomplete1
                      disablePortal
                      id="combo-box-demo"
                      // className="form-control"
                      options={chapterOptions}
                      getOptionLabel={(option) => option.chapter}
                      onChange={(event, value) => {
                        if (value === null) {
                          handleInput1('', "chapter")
                          return
                        }

                        handleInput1(value.chapter, "chapter")
                      }}

                      style={{ width: '100%', height: '38px' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />}
                    {mode === 'Edit' && data.chapter && <Autocomplete1
                      id="combo-box-demo"
                      // className="form-control"
                      options={chapterOptions}
                      getOptionLabel={(option) => option.chapter}
                      onChange={(event, value) => {
                        if (value === null) {
                          handleInput1('', "chapter")
                          return
                        }
                        console.log('ddddddd')
                        handleInput1(value.chapter, "chapter")
                      }}
                      disableClearable={true}
                      defaultValue={{ chapter: data.chapter ? data.chapter : '' }}
                      style={{ width: '100%', height: '38px' }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />}
                    {/* <ErrorMessage component={TextError} name="chapter" /> */}
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <Select
                    name="Video"
                    optname="Video"
                    label="Video Name"
                    value={data.vedioName ? data.vedioName : ""}
                    options={chapterOptions ? chapterOptions : []}
                    onChange={(e) => handleInput(e, "videoId")}
                  />
                </div> */}

                <div className="col-md-12">
                  <Input
                    type="file"
                    name="video"
                    label="Video"
                    onChange={handleFileChange}
                  />
                </div>
                {/* <div className="col-md-12">
                    <Input
                      type="file"
                      name="upfile"
                      label="upfile"
                      onChange={uploadFileChange}
                    />
                  </div> */}
              </div>
              {/* <div className="text-center">
                <Button
                  className="btn btn-sm btn-primary"
                  label={"upload file"}
                  onClick={fileUpload}
                />
              </div> */}
              <div className="text-center">
                <Button
                  className="btn btn-sm btn-primary"
                  label={"Submit"}
                  onClick={handleFileUpload}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVideo;
