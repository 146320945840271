const loginValues = {
  email: "superadmin@email.com",
  password: "superadmin",
};

const staffValues = {
  curriculum: "",
  curriculumCode: "",
  subject: "",
  firstName: "",
  lastName: "",
  role: "",
  email: "",
  mobile: "",
  address: "",
};

const staffRoleValues = {
  roleName: "",
};

const questionValues = {
  curriculum: "",
  curriculumCode: "",
  subject: "",
  topic: "",
  chapter: "",
  correctanswer: "",
  answer1: "",
  answer2: "",
  answer3: "",
  questionmarks: "",
  questionType: "",
};

const adminStudent = {
  firstName: "",
  middleName: "",
  lastName: "",
  mobile: "",
  email: "",
  password: "",
  gender: "",
  dob: "",
  curriculum: "",
  curriculumCode: "",
  parentOrGardien: {
    parentFirstName: "",
    parentMiddleName: "",
    parentLastName: "",
    parentMobile: "",
    parentEmail: "",
    parentAddress: "",
  },
  address: "",
  country: "",
  state: "",
  city: "",
  pincode: "",
  identityUrl: "",
  identityNumber: ""

}

const scheduleValues = {
  title: "",
  curriculum: "",
  curriculumCode: "",
  subject: "",
  teacher: "",
  description: "",
  date: "",
  startTime: "",
  endTime: "",
  sessionType:"",
  sessionMode:""
};

const subjectValues = {
  curriculum: "",
  curriculumCode: "",
  subject: "",
  subjectInfo: "",
  minCredits: "",
  maxCredits: "",
  practicalSession: "",
  startDate: "",
  endDate: "",
};

const assignmentValues = {
  assessmentName: "",
  type: "",
  curriculum: "",
  curriculumCode: "",
  subject: "",
  topic: "",
  chapter: "",
  maxQuestions: "",
  maxWeightage: "",
  startdate: "",
  enddate: "",
  labSession: "",
  assessmentType: "",
  assignmentMode: "",
  optionalQuestions: "",
  optQuestionMarks: "",
};

export {
  loginValues,
  staffValues,
  staffRoleValues,
  questionValues,
  scheduleValues,
  subjectValues,
  assignmentValues,
  adminStudent
};
