import AuthContainer from "../Authentication/AuthContainer";
export default [

  {
    path: "",
    component: AuthContainer,
  },


 
];
