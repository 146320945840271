import React, { Component } from "react";
import Video from "../../components/admin/video/video";
import AddVideo from "../../components/admin/video/addVideo";
import LoadingModal from "../../components/Share/LoadingModal";
import { getCurriculumCodeFun } from "../../utils/utils";
import AddRead from '../../components/admin/video/addread'
import $ from "jquery";

import {
  getCurriculumList,
  getSubjectsByCur,
  getTopicByCurSub,
  getAllChapters,
} from "../../services/Admin/commonService";

import {
  videosList,
  deleteVideo,
  videoUpload,
  fileUpload,
  submitVideo,
  submitRead,
  getVideo,
  checkVideoStatus,
  getVideoList,
  fileUploadpdf
} from "../../services/Admin/videoService";

class VideoContainer extends Component {
  state = {
    mode: "",
    isLoading: false,
    curriculumList: [],
    errorMessage: "",
    subjectsList: [],
    topicsList: [],
    questionsList: [],
    videosList: [],
    videosListAll: [],

    questionsIndividualList: [],
    uploadFile: "",
    uploadFile1: '',
    videoList: [],
    videoListDropDown: [],
    search: '',
    videoData: {
      curriculum: "",
      curriculumCode: "",
      subject: "",
      topic: "",
      chapter: "",
      fileName: "",
      fileLocation: "",
      vedioName: '',
      videoId: '',
      videofileName:""
    },
    readFileData: {
      curriculum: "",
      curriculumCode: "",
      subject: "",
      topic: "",
      chapter: "",
      fileName: '',
      location: ''

    },
    videoStatuss: false
  };

  loadDefaultValues = () => {
    const pathName = this.props.match.path;
    if (pathName === "/admin/add-video" || pathName === "/admin/add-read") {
      this.setState({ mode: "Add" });
    } else if (pathName === "/admin/edit-video/:id") {
      this.setState({ mode: "Edit" });
    } else if (pathName === "/admin/view-video/:id") {
      this.setState({ mode: "view" });
    } else if (pathName === "/admin/video") {
      this.setState({ mode: "List" });
    }
  };

  handleReadInput = ({ currentTarget: input }, name) => {
    const fileData = { ...this.state.readFileData };
    const { options, selectedIndex } = input;
    console.log('file', input.name, options[selectedIndex].innerHTML)
    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;
      fileData[input.name] = optValue;
    } else {
      fileData[input.name] = input.value;
    }
    //console.log('file', name, options[selectedIndex].innerHTML)

    if (name === "curriculum") {
      fileData.subject = "";
      fileData.topic = "";
      fileData.chapter = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      fileData["curriculumCode"] = curriculumCodeValue;
      this.getAllSubjectList(curriculumCodeValue);
    }

    if (name === "subject") {
      fileData.topic = "";
      fileData.chapter = "";
      this.getAllTopicsList(fileData.curriculumCode, input.value);
    }

    if (name === "topic") {
      this.getAllChaptersList(
        fileData.curriculumCode,
        fileData.subject,
        input.value
      );
    }
    if (name === "chapter") {
      this.getVedioList()
      console.log('vid0000--', options[selectedIndex].innerHTML)
      this.videoListDropDown(options[selectedIndex].innerHTML)
    }
    if (name ==='video'){

    }
    if (name === "videoId") {

      // console.log('inpu-' + JSON.stringify(fileData))

    }


    this.setState({ readFileData: fileData });
  };
  handleInput2 = async (input, name) => {
    const fileData = { ...this.state.readFileData };

    if (name === "curriculum") {
      fileData.subject = "";
      fileData.topic = "";
      fileData.chapter = "";
      fileData.curriculum = input

      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input
      );
      fileData["curriculumCode"] = curriculumCodeValue;
      this.getAllSubjectList(curriculumCodeValue);
    }

    if (name === "subject") {
      fileData.topic = "";
      fileData.chapter = "";
      fileData.subject = input;

      this.getAllTopicsList(fileData.curriculumCode, input);
    }

    if (name === "topic") {
      fileData.chapter = "";
      fileData.topic = input;

      this.getAllChaptersList(
        fileData.curriculumCode,
        fileData.subject,
        input
      );

    }
    if (name === "chapter") {
      fileData.chapter = input;
      this.videoListDropDown(input)


    }
    if(name==='video'){
      fileData.videofileName=input
    }
    this.setState({ readFileData: fileData });
  };
  getVedioList = () => {
    getVideo().then(res => {
      if (res.status === 200) {
        if (res.data.status) {
          this.setState({ videoList: res.data.vedioList, videosListAll: res.data.vedioList })
        }
      }

    }).catch(err => { console.log(err) })
  }

  videoListDropDown=(value)=>{
    let payload = { ...this.state.readFileData}
    payload['chapter']=value;
    console.log('ere==', payload)
    getVideoList(payload).then(res => {
      if (res.status === 200) {

        if (res.data.status) {
          console.log('res==',res.data)
          this.setState({ videoListDropDown: res.data.data })
        }
      }

    }).catch(err => { console.log(err) })
  }


  //rad inputs

  handleInput = ({ currentTarget: input }, name) => {
    const videoData = { ...this.state.videoData };
    const { options, selectedIndex } = input;
    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;
      videoData[input.name] = optValue;
    } else {
      videoData[input.name] = input.value;
    }

    if (name === "curriculum") {
      videoData.subject = "";
      videoData.topic = "";
      videoData.chapter = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      videoData["curriculumCode"] = curriculumCodeValue;
      this.getAllSubjectList(curriculumCodeValue);
    }

    if (name === "subject") {
      videoData.topic = "";
      videoData.chapter = "";
      this.getAllTopicsList(videoData.curriculumCode, input.value);
    }

    if (name === "topic") {
      this.getAllChaptersList(
        videoData.curriculumCode,
        videoData.subject,
        input.value
      );
    }

    this.setState({ videoData });
  };
  handleInput1 = async (input, name) => {
    const videoData = { ...this.state.videoData };

    if (name === "curriculum") {
      videoData.subject = "";
      videoData.topic = "";
      videoData.chapter = "";
      videoData.curriculum = input

      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input
      );
      videoData["curriculumCode"] = curriculumCodeValue;
      this.getAllSubjectList(curriculumCodeValue);
    }

    if (name === "subject") {
      videoData.topic = "";
      videoData.chapter = "";
      videoData.subject = input;

      this.getAllTopicsList(videoData.curriculumCode, input);
    }

    if (name === "topic") {
      videoData.chapter = "";
      videoData.topic = input;

      this.getAllChaptersList(
        videoData.curriculumCode,
        videoData.subject,
        input
      );

    }
    if (name === "chapter") {
      videoData.chapter = input;

    }
    this.setState({ videoData });
  };




  // Get the all curriculum list for that organization
  getAllCurriculumList = async () => {
    getCurriculumList()
      .then((res) => {
        this.setState({ curriculumList: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Get the all subjects list based on curriculum
  getAllSubjectList = async (cc) => {
    const payload = {
      curriculumCode: cc,
    };
    getSubjectsByCur(payload)
      .then((res) => {
        this.setState({ subjectsList: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Get the all topics list based on curriculum, subject
  getAllTopicsList = async (cc, sub) => {
    const payload = {
      curriculumCode: cc,
      subject: sub,
    };
    getTopicByCurSub(payload)
      .then((res) => {
        this.setState({ topicsList: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Get the all chapters list based on curriculum, subject, topic
  getAllChaptersList = async (cc, sub, topic) => {
    const payload = {
      curriculumCode: cc,
      subject: sub,
      topic: topic,
    };
    getAllChapters(payload)
      .then((res) => {
        this.setState({ chaptersList: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Get the all videos list
  getVideosList = async () => {
    this.setState({ isLoading: true });
    const payload = {};
    videosList(payload)
      .then((res) => {
        this.setState({ videosList: res.data.data, isLoading: false, videosListAll: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  deleteVideo = (videoId, file) => {
    this.setState({ isLoading: true });
    const payload = {
      videoID: videoId,
      fileName: file,
    };
    deleteVideo(payload)
      .then((res) => {
        this.setState({ isLoading: false });
        this.props.history.push("/admin/video");
        this.getVideosList();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  async componentDidMount() {
    await this.loadDefaultValues();
    const { mode } = this.state;
    if (mode === "Add") {
      this.getAllCurriculumList();
    } else if (mode === "Edit") {
      this.getAllCurriculumList();
    } else if (mode === "List") {
      this.getVideosList();
    } else if (mode === "View") {
    }
  }

  // ShowAlert Function
  ocShowAlert = (message, background = "#3089cf") => {
    let alertContainer = document.querySelector("#oc-alert-container"),
      alertEl = document.createElement("div"),
      textNode = document.createTextNode(message);
    alertEl.setAttribute("class", "oc-alert-pop-up");
    $(alertEl).css("background", background);
    alertEl.appendChild(textNode);
    alertContainer.appendChild(alertEl);
    setTimeout(function () {
      $(alertEl).fadeOut("slow");
      $(alertEl).remove();
    }, 3000);
  };

  handleFileChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };
  uploadFileChange = (event) => {
    // console.log('dd-' + event.target.files[0])
    this.setState({
      uploadFile1: event.target.files[0],
    });
  };
  fileUpload = () => {

    this.setState({ errorMessage: '' })
    const data = new FormData();
    var filename, fileLoaction
    if (this.state.uploadFile1) {
      console.log('data-' + JSON.stringify(data))
      var ext = this.state.uploadFile1.name.substring(this.state.uploadFile1.name.lastIndexOf('.') + 1);
      console.log('type', ext)

      if(ext!=='pdf' &&  ext!=='png' && ext!=='jpg' && ext!=='zip'){
        this.setState({ errorMessage: 'Please upload a PDF/PNG/JPG/ZIP' })

        return 
      }
      
      data.append(
        "upfile",
        this.state.uploadFile1,
        this.state.uploadFile1.name
      );
    }

    for (var key of data.entries()) {
      // console.log(JSON.stringify(key[1]));
    }

    let checkVideo = { ...this.state.readFileData }
    checkVideoStatus(checkVideo).then(responsee => {
      // console.log('re' + JSON.stringify(responsee))
      if (responsee.data.videoStaus && responsee.data.status) {
        let headers = {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        };
        fileUploadpdf(data).then(res => { //location
          return { fieldname: res.data.fieldname, loaction: res.data.location }
        }).then(data => {
          const readfileData = { ...this.state.readFileData }
          
          readfileData['fileName'] = data.fieldname.originalname
          readfileData['location'] = data.loaction
          submitRead(readfileData).then(apiRes => {
            if (apiRes.data.status === true) {
              this.setState({ isLoading: false });

              this.props.history.push("/admin/video");
            } else {
            
              this.setState({ errorMessage: apiRes.data.message })
              setTimeout(()=>{
                this.setState({ isLoading: false });
              },2000)
             


            }
          })
        }).catch(err => console.log('errr-' + JSON.stringify(err)))
      } else {
        console.log('status false')
        this.setState({ errorMessage: 'Video not found,Please upload video before file upload' })
      }
    }).catch(err => {
      this.setState({ errorMessage: 'Video not found' })
    })
    return;

    // console.log(JSON.stringify(filedata))
  }

  handleFileUpload = (event) => {
    this.setState({ errorMessage: '' })
    const data = new FormData();
    if (this.state.selectedFile) {
      this.setState({ isLoading: true });
      data.append(
        "profileImage",
        this.state.selectedFile,
        this.state.selectedFile.name
      );
      let headers = {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      };
      videoUpload(data, headers)
        .then((response) => {
          if (200 === response.status) {
            // If file size is larger than expected.
            if (response.data.error) {
              if ("LIMIT_FILE_SIZE" === response.data.error.code) {
                console.log("Max size: 2MB -----------------");
                // this.ocShowAlert( 'Max size: 2MB', 'red' );
              } else {
                console.log(
                  "response Data ---------------",
                  response.data.error
                );
                // If not the given file type
                // this.ocShowAlert( response.data.error, 'red' );
              }
            } else {
              // Success
              let fileData = response.data;
              // this.ocShowAlert( 'File Uploaded', '#3089cf' );
              const videoData = { ...this.state.videoData };
              videoData["fileName"] = fileData.fileName;
              videoData["fileLocation"] = fileData.fileLocation;
              submitVideo(videoData).then((response) => {
                if (response.data.error) {
                  console.log(
                    "Question Not inserted properly",
                    response.data.error
                  );
                } else {
                  this.props.history.push("/admin/video");
                  this.setState({ isLoading: false });
                  // console.log("Question inserted successfully!");
                }
              })
            }
          }
        })
        .catch((error) => {
          // If another error
          console.log("error ---------------", error);
          // this.ocShowAlert( error, 'red' );
        });
    } else {
      // if file not selected throw error
      console.log("Please upload file ---------------");
      // this.ocShowAlert( 'Please upload file', 'red' );
    }
  };
  searchVideo = (event) => {
    this.setState({ search: event.target.value })
    let val = String(event.target.value).toLowerCase()
    if (!val) {
      this.getVideosList()
      return
    }
    let filter = this.state.videosListAll.filter(q => {

      // console.log('ass--',q)
      try {

        if (q.fileName.toLowerCase().indexOf(val) !== -1) {
          // console.log('ass--', q.fileName)

          return q
        }
        else if(q.curriculum.toLowerCase().indexOf(val) !== -1){
          return q
        }
        else if(q.subject.toLowerCase().indexOf(val) !== -1){
          return q
        }
        else if(q.topic.toLowerCase().indexOf(val) !== -1){
          return q
        }
      } catch (err) {

      }

    })
    // console.log('filter-' + JSON.stringify(filter))
    this.setState({ videosList: [...filter] })

  }
  render() {
    let path = this.props.match.path;
    const {
      mode,
      isLoading,
      curriculumList,
      subjectsList,
      topicsList,
      chaptersList,
      videoData,
      videosList,
      readFileData,
      errorMessage
    } = this.state;
    return (
      <React.Fragment>
        {path === "/admin/video" && (
          <Video videosList={videosList} deleteVideo={this.deleteVideo} search={this.state.search} searchVideo={this.searchVideo} />
        )}
        {path === "/admin/add-video" && (
          <AddVideo
            mode={mode}
            curriculumList={curriculumList}
            subjectsList={subjectsList}
            topicsList={topicsList}
            chaptersList={chaptersList}
            videoData={videoData}
            handleInput={this.handleInput}
            handleFileChange={this.handleFileChange}
            handleFileUpload={this.handleFileUpload}
            uploadFileChange={this.uploadFileChange}
            fileUpload={this.fileUpload}
            handleInput1={this.handleInput1}

          />
        )}
        {path === '/admin/add-read' && (
          <AddRead
            mode={mode}
            curriculumList={curriculumList}
            subjectsList={subjectsList}
            topicsList={topicsList}
            chaptersList={chaptersList}
            videoData={readFileData}
            handleInput={this.handleReadInput}
            handleFileChange={this.handleFileChange}
            handleFileUpload={this.handleFileUpload}
            uploadFileChange={this.uploadFileChange}
            fileUpload={this.fileUpload}
            error={errorMessage}
            videoList={this.state.videoList}
            videoListDropDown={this.state.videoListDropDown}
            handleInput2={this.handleInput2}

          />
        )}

        <LoadingModal visible={isLoading} />
      </React.Fragment>
    );
  }
}

export default VideoContainer;
