import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
    "x-access-token": getAuthToken(),
};

export const getQueriesList = (payload) => {
    return http.post(apiUrl + "/getAllTeacherQueries", payload, {
        headers: headers,
    });
};

export const changeStatus = (payload) => {
    return http.post(apiUrl + "/changeStatus", payload, {
        headers: headers,
    });
};

export const addQuery = (payload) => {
    return http.post(apiUrl + "/addQuery", payload, {
        headers: headers,
    });
};
