import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const sessionInsert = (payload) => {
  return http.post(apiUrl + "/sessionInsert", payload, {
    headers: headers,
  });
};

export const sessionUpdate = (payload) => {
  return http.post(apiUrl + "/sessionUpdate", payload, {
    headers: headers,
  });
};

export const getAllSessions = (payload) => {
  return http.post(apiUrl + "/getAllSessions", payload, {
    headers: headers,
  });
};
export const getOpenSessions = (payload) => {
  return http.post(apiUrl + "/helpFromTutor", payload, {
    headers: headers,
  });
};

export const getSessionByID = (payload) => {
  return http.post(apiUrl + "/getSessionByID", payload, {
    headers: headers,
  });
};

export const getAllTeachers = (payload) => {
  return http.post(apiUrl + "/teachers", payload, {
    headers: headers,
  });
};


export const getSession = (payload) => {
  return http.post(apiUrl + "/getSessionByCode", payload, {
    headers: headers,
  });
};
export const getUpcomingSession = (payload) => {
  return http.post(apiUrl + "/getAllUpcomingSessions", payload, {
    headers: headers,
  });
};
export const getCompletedSession = (payload) => {
  return http.post(apiUrl + "/getAllCompletedSessions", payload, {
    headers: headers,
  });
};
export const getusercur = (payload) => {
  return http.post(apiUrl + "/getusercur", payload, {
    headers: headers,
  });
};

export const sessionStatus = (payload) => {
  return http.post(apiUrl + "/sessionStatus", payload, {
    headers: headers,
  });
};
