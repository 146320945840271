import React, { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import ImageCompent from '../../components/admin/image/imageList';
import { useDispatch, useSelector } from 'react-redux';
import * as Img from '../../store/actions/logoImage'
import { fileUpload } from "../../services/Admin/videoService";
import { updateImage } from "../../services/Admin/commonService";
const ImagesContainer = () => {
    const [logoImg, setLogoImg] = useState('')
    const dispatch = useDispatch()
    const [upImg, setUpImg] = useState('')
    const [loading, setLoading] = useState(false)

    const uploadFileChange = (event) => {
        setUpImg(event.target.files[0])
    }

    const submitupload = () => {
        const data = new FormData();
        if (upImg) {
            data.append(
                "upfile",
                upImg,
                upImg.name
            );
            let headers = {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            };
            setLoading(true)
            fileUpload(data).then(res => {
                console.log('imf-' + res.data.location)
                let payload = {
                    "type": "logo",
                    "imageUrl": res.data.location
                }
                updateImage(payload).then(result => {
                    if (result.status === 200) {
                        dispatch(Img.getlogo())

                    } else {
                        console.log('error---')
                    }
                    setLoading(false)
                }).catch(err => console.log('da'))
            }).catch(err => console.log('data-img'))
        }
    }
    useEffect(() => {
        dispatch(Img.getlogo())
        
    }, [])
    

    let image = useSelector(state => {
        return state.logo.logo
    })
    useEffect(() => {
        console.log('image logo')
        setLogoImg(image)
    }, [image])
    return <Fragment>
        <ImageCompent logoImg={logoImg} uploadFileChange={uploadFileChange} submitupload={submitupload} loading={loading} />
    </Fragment>
}

export default ImagesContainer