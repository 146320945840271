import React, { useState, useEffect, Fragment } from 'react'
import LoadingModal from '../../components/Share/LoadingModal';
import TeacherSessionComp from '../../components/Teachers/session/teacherSessionComp'
import {
    userEmail,
    userRole,
    userCurriculumCode,
    userID,
} from "../../services/Authentication/AuthService";
import { getAllSessions } from '../../services/Student/SessionService';
import { sessionStatus } from '../../services/Student/SessionService';
const TeacherSession = () => {
    const [sessionList, setSessionList] = useState([])
    const [loading, setloading] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        getSession()
    }, [])
    const getSession = () => {
        const payload = {
            curriculumCode: userCurriculumCode(),
            email: userEmail(),
            role: userRole()
        }
        console.log('------------')
        getAllSessions(payload).then(res => {
            if (res.status === 200) {
                let list = []
                let list1 = res.data.data
                list = res.data.data.filter(el => {
                    let arr = []
                    let arr1 = []
                    let arr2 = []
                    console.log('dsds11', el);
                    if (el.acceptedTeacher) {
                        arr1 = el.acceptedTeacher.filter(e => {

                            if (e.email === userEmail() || e.email === 'all') {
                                return el
                            }
                        })
                        return arr
                    } else if (el.requestedTeacher) {
                        console.log('request')
                        arr2 = el.requestedTeacher.filter(e => {

                            if (e.email === userEmail() || e.email === 'all') {
                                console.log('0000000000')
                                return el
                            }
                        })
                    }

                });
                setSessionList(list1.reverse())
            } else {
                console.log('error')
            }
        })
    }
    const statusChange = (data, status) => {
        let payload = {
            sessionID: data.sessionID,
            title: data.title,
            start: data.start.replace(" ", "T"),
            end: data.end.replace(" ", "T"),
            studentEmails: data.studentEmails,
            status: status,
            // email: 'support@advstechnosolutions.onmicrosoft.com'
            // email: userEmail()
            email: "ankush@uxdlabtech.com"
        }
        setloading(true)
        if (status === 'REJECTED') {
            payload['requestedTeacher'] = data.requestedTeacher.filter((el) => {
                
                return Number(el.userID) !== Number(userID())
            })
            // payload['rejectedTeacher'] = data.requestedTeacher.filter(el => {

            //     return Number(el.userID) === Number(userID())
            // })
        }else if(status==='ACCEPTED'){
            payload['requestedTeacher'] = data.requestedTeacher.filter(el => {

                return Number(el.userID) === Number(userID())
            })
        }
        
        sessionStatus(payload).then(res => {
            if (res.status === 200 && res.data.status) {
                getSession()
                setloading(false)

            } else {
                if (res.data.error) {

                    alert(res.data.error)
                } else {
                    alert(res.data.message)
                }
                setloading(false)

            }
        }).catch(err => {
            setloading(false)

        })
    }
    const changeDateHandler = (value, index, type) => {
        setUpdate(true)
        if (type === 1) {
            // console.log('value=', value.target.value)
            let data = sessionList
            let sdate = String(value.target.value) + " " + data[index].start.split(' ')[1]
            data[index].start = sdate

            setSessionList([...data])
        } else {
            let data = sessionList
            let sdate = String(value.target.value) + " " + data[index].end.split(' ')[1]
            data[index].end = sdate

            setSessionList([...data])
        }
        setUpdate(false)

    }
    const changeTimeHandler = (value, index, type) => {
        // console.log('time-' + value.target.value)
        setUpdate(true)
        if (type === 1) {
            let data = sessionList
            let stime = data[index].start.split(' ')[0] + " " + value.target.value
            data[index].start = stime

            // console.log('data--' + JSON.stringify(data[index].start))
            setSessionList([...data])
        } else {
            let data = sessionList
            let stime = data[index].end.split(' ')[0] + " " + value.target.value
            data[index].end = stime

            setSessionList([...data])
        }
        setUpdate(false)
    }

    return <Fragment>
        <TeacherSessionComp
            sessionList={sessionList}
            statusChange={statusChange}
            loading={loading}
            changeDateHandler={changeDateHandler}
            changeTimeHandler={changeTimeHandler}
        />
        <LoadingModal visible={loading} />
    </Fragment>

}


export default TeacherSession