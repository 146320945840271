import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/Images/log.png";
import styles from "./Sidebar.module.css";

const Sidebar = () => {
  const [logoImg, setLogoImg] = useState();
  useEffect(() => {
    setLogoImg(localStorage.logo);
  }, []);
  return (
    <aside className={styles.mainSidebar}>
      <div className={styles.logo}>
        <img
          src={Logo}
          style={{ width: "175px", height: "100px" }}
          alt="logo22"
        />
        <span>Organization Dashboard</span>
        <hr />
      </div>
      <ul className="navbar-nav ml-auto mt-3 colr">
        <li className="nav-item">
          <NavLink
            to="/OrgAdmin/Oragnization"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-th-large ${styles.sideIcon}`}></i>
            Organization
          </NavLink>
        </li>
        
        <li className="nav-item">
          <NavLink
            to="/OrgAdmin"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-graduation-cap ${styles.sideIcon}`}></i>
            Curriculum
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to=""
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-th-large ${styles.sideIcon}`}></i>
            Module
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to=""
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-book-reader ${styles.sideIcon}`}></i>
            Subject
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to=""
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-book-open ${styles.sideIcon}`}></i>
            Chapter
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to=""
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-chalkboard ${styles.sideIcon}`}></i>
            Templetes
          </NavLink>
        </li>

        {/* <li className="nav-item">
          <NavLink
            to="/OrgAdmin/Assignment"
            exact
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Assignments
          </NavLink>
        </li> */}

        {/* <li className="nav-item">
          <NavLink
            to="/teacher/calender"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Calender
          </NavLink>
        </li> */}
        <li className="nav-item">
          <NavLink
            to="/OrgAdmin/PracticalSession"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            PracticalSession
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/OrgAdmin/ClassroomScheduling"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Classroom Scheduling
          </NavLink>
        </li>

        {/* <li className="nav-item">
          <NavLink
            to="/teacher/queries-list"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Querises
          </NavLink>
        </li> */}
      </ul>
    </aside>
  );
};

export default Sidebar;
