import React, { Component } from "react";

import Registration from "../../components/Authentication/Register";
import Login from "../../components/Authentication/Login";
import RegisterSuccess from "../../components/Authentication/RegisterSuccess";
import { useDispatch } from 'react-redux';
import * as logo from '../../store/actions/logoImage'


import {
  register,
  login,
  setToken,
  googlelog,
} from "../../services/Authentication/AuthService";

import { getAllCurriculum } from "../../services/Admin/curriculumService";
import {
  registerValues,
  loginValues,
} from "../../constants/StudentInitialValues";
class AuthContainer extends Component {
  state = {
    loginData: loginValues,
    registerData: registerValues,
    curriculumList: {},
    curriculumCode: "",
    errorMsg: "",
    registerError: ''
  };
  handleCurriculum = (data, e) => {
    this.state.curriculumList.length > 0 &&
      this.state.curriculumList.map((cc) => {
        if (cc.curriculum === e.target.value) {
          this.setState({ curriculumCode: cc.curriculumCode });
        }
      });
  };

  handleSubmitRegister = async (formik) => {
    const values = formik.values;
    console.log(values.state,"<-");
    values.paymentDeatils=[];
    values.curriculumCode = this.state.curriculumCode;
    const payload = values;
    if (formik.isValid) {
      register(payload)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status) {
              this.props.history.replace("/register-success");

            } else {
              this.setState({ registerError: res.data.Message })

            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  googleLog = async (loginInfo) => {
    if (loginInfo) {
      googlelog(loginInfo).then(res => {
        //console.log('result' + JSON.stringify(res))

        if (res.data.status === true) {
          if (res.data.valid === true) {
            //console.log(loginInfo.email)
            var payload = {
              email: loginInfo.email,
              password: loginInfo.id
            }
            login(payload)
              .then((res) => {
                if (res.status === 200) {
                  if (res.data.statuscode == 204) {
                    this.setState({ errorMsg: res.data.message });
                  } else if (!res.data.status) {
                    this.setState({ errorMsg: res.data.message });
                  }

                  
                  else {
                    if (res.data.message === 'Account Inactive') {
                      this.setState({ errorMsg: res.data.message });

                    } else if (res.data.message === 'Email verification Pending') {
                      this.setState({ errorMsg: res.data.message });
                    }else{
                      const role = res.data.loginModule;
                      setToken(res.data.token);
                      this.setState({ errorMsg: "" });
                      if (role === "student") {
                        sessionStorage.setItem("loginType", "student");
                        this.props.history.replace("/student/dashboard");
                        window.location.reload();
                      } else if (role === "admin") {
                        sessionStorage.setItem("loginType", "superadmin");
                        this.props.history.replace("/admin/dashboard");
                        window.location.reload();
                      } else if (role === "teacher") {
                        sessionStorage.setItem("loginType", "teacher");
                        this.props.history.replace("/teacher/dashboard");
                        window.location.reload();
                      }
                    }
                   
                  }
                }
              })
              .catch((err) => { });
          } else {
            sessionStorage.setItem('log', JSON.stringify(loginInfo))
            this.props.history.push('/register?Glogin=true')
            window.location.reload();
          }


        } else {
          this.setState({ errorMsg: res.data.message })
        }
        //console.log('result' + res)
      })
        .catch((err) => {
          console.log("err google", err);
        });
    }

  }


  handleSubmitLogin = async (formik) => {

    const values = formik.values;
    const payload = values;
    if (formik.isValid) {
      login(payload)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.statuscode == 204) {
              this.setState({ errorMsg: res.data.message });
            } else if (!res.data.status) {
              this.setState({ errorMsg: res.data.message });
            }

            else {
              if (res.data.message === 'Account Inactive') {
                this.setState({ errorMsg: res.data.message });

              } else if (res.data.message === 'Email verification Pending') {
                this.setState({ errorMsg: res.data.message });
              }
              else {
                const role = res.data.loginModule;
                setToken(res.data.token);
                this.setState({ errorMsg: "" });
                //console.log('token=' + JSON.stringify(res.data))
                if (role === "student") {
                  sessionStorage.setItem("loginType", "student");
                  this.props.history.replace("/student/dashboard");
                  window.location.reload();
                } else if (role === "admin") {
                  sessionStorage.setItem("loginType", "superadmin");
                  this.props.history.replace("/admin/dashboard");
                  window.location.reload();
                } else if (role === "teacher") {
                  sessionStorage.setItem("loginType", "teacher");
                  this.props.history.replace("/teacher/dashboard");
                  window.location.reload();
                }
              }

            }
          }
        })
        .catch((err) => { });
    }
  };

  getCurriculumList = async () => {
    try {
      const response = await getAllCurriculum();
      this.setState({ curriculumList: response.data.data });
      
    } catch (ex) {
 
      if (ex.response && ex.response.status === 404) {
        this.props.history.replace("/not-found");
      }
    }
  };

  redirectRegister = () => {
    this.props.history.push("/register");
    window.location.reload();
  };

  componentDidMount() {
    const path = this.props.match.path;
    //console.log('path',path)
    if (path === "/register") {

      this.getCurriculumList();
    }
  }

  render() {

   // console.log(this.props.location)
    const params = new URLSearchParams(this.props.location.search)

    let query = params.get('Glogin')
    //console.log('query', query);
    if (this.props.location.pathname === '/register') {
      //console.log('---lklk');
      if (query) {
        //this.getCurriculumList();
        const gLog = JSON.parse(sessionStorage.getItem('log'))
        // registerValues.firstName = gLog.givenName
        // registerValues.lastName = gLog.familyName
        registerValues.email = gLog.email
        registerValues.password = gLog.id
        //console.log('dsdd===' + query)
      } else {
        query = false
      }
      //this.getCurriculumList();
    }
    const path = this.props.match.path;
    const {
      registerData,
      loginData,
      errorMsg,
      curriculumList,
      curriculumCode,
      registerError
    } = this.state;
    return (
      <React.Fragment>
        {path === "/register" && (
          <Registration
            registerData={registerData}
            curriculumCode={curriculumCode}
            curriculumList={curriculumList}
            handleInput={this.handleInput}
            handleParentInput={this.handleParentInput}
            handleDates={this.handleDates}
            handleSubmitRegister={this.handleSubmitRegister}
            handleCurriculum={this.handleCurriculum}
            query={query}
            error={this.state.registerError}
          />
        )}

        {path === "/" && (
          <Login
            loginData={loginData}
            errorMsg={errorMsg}
            handleInputLogin={this.handleInputLogin}
            handleSubmitLogin={this.handleSubmitLogin}
            redirectRegister={this.redirectRegister}
            logToGoogle={this.googleLog}
          />
        )}

        {path === "/register-success" && <RegisterSuccess />}
      </React.Fragment>
    );
  }
}

export default AuthContainer;
