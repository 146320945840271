import { getInactiveUser } from '../../services/Admin/commonService';
import { userCurriculumCode, userEmail, userRole } from '../../services/Authentication/AuthService';
import { getAllSessions } from '../../services/Student/SessionService';


export const INAVTIVEUSER = "INAVTIVEUSER";
export const GETLISTUSER = "GETLISTUSER";
export const GETSESSION = "GETSESSION"
export const inActiveList = (userList) => {
    return { type: INAVTIVEUSER, user: userList }
}


export const getList = () => dispatch => {
    getInactiveUser().then(res => {
        dispatch({ type: GETLISTUSER, userList: res.data.userDetail })
        // return { type: INAVTIVEUSER, userList: res.data.userDetail }

    }).catch(err => { console.log('redux') })
}
export const getSession = () => {
    return dispatch => {
        const payload = {
            curriculumCode: userCurriculumCode(),
            email: userEmail(),
            role: userRole()
        }
        getAllSessions(payload).then(res => {
            dispatch({ type: GETSESSION, sessionList: res.data.data })
        }).catch(err => console.log('redux error'))
    }
}