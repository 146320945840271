import React from "react";
import parse from "html-react-parser";
import DisplayErrorMessage from "../../Share/DisplayErrorMessage";
import styles from "./HelpFromTutor.module.css";
import moment from "moment";

const ScheduledRequests = ({ scheduledRequests }) => {
  if (scheduledRequests && scheduledRequests.length === 0) {
    return (
      <DisplayErrorMessage
        message="No scheduled requests found"
        errorSize="big"
      />
    );
  }
  return (
    <React.Fragment>
      {scheduledRequests.length > 0 &&
        scheduledRequests.map((q) => {
          if (q.status === "ACCEPTED") {
            return (
              <div className={`cardDiv ${styles.tutorDiv}`} key={q._id}>
                <h5 title="Curriculum" className={styles.curriculum}>
                  {q.curriculum} {"  -"}
                </h5>
                <div title="Subjet" className={styles.subject}>
                  {q.subject} {"-"}
                </div>
                <div title="Topic" className={styles.topic}>
                  {q.topics ? q.topics.join(", ") : ""}
                </div>

                <hr className={styles.hr} />
                <p>{q.desc ? parse(q.desc) : ""}</p>
                <div className={styles.tutorFeatures}>
                  <p className={styles.status}>Status : Scheduled</p>
                  <p className={styles.scheduledDate}>
                    Session Start :{moment(q.start).format("DD-MM-YYYY HH:mm")}
                    {" - "}
                    Session End :{moment(q.end).format("DD-MM-YYYY HH:mm")}
                  </p>
                  <p className={styles.scheduledDate}>
                    Session Link:{" "}
                    <a target="_blank" rel="noreferrer" href={q.link}>
                      Link
                    </a>
                  </p>
                </div>
              </div>
            );
          }
        })}
    </React.Fragment>
  );
};

export default ScheduledRequests;
