import React, { useState, useEffect, Fragment } from "react";


const ViewStudent = props => {
    const { studentValues, staffDelete, changeStatus } = props
    console.log('dsd', studentValues.isActivate);
    return <div className="container">
        <button className="btn btn-sm btn-danger float-right ml-3" onClick={() => staffDelete(studentValues._id)}>
            Delete
        </button>
        {studentValues.isActivate ? <button className="btn btn-sm btn-info float-right ml-3" onClick={() => changeStatus(studentValues._id, false)}>
            Active 
        </button> : <button className="btn btn-sm btn-info float-right ml-3" onClick={() => changeStatus(studentValues._id, true)}>
            InActive
        </button>}
        <div className="row justify-content-center">

            <div className="col-md-10">
                <h1 className="form-heading">Student Detail</h1>

                <div className="card">
                    <div className="card-body">
                        <div className="text-view">
                            <label>Name </label>
                            <span>
                                {studentValues.firstName} {studentValues.middleName} {studentValues.lastName}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>mobile</label>
                            <span>
                                {studentValues.mobile}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>email
                            </label>
                            <span>
                                {studentValues.email}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>address</label>
                            <span>
                                {studentValues.address}
                            </span>
                        </div>
                        {props.role !== 'admin' && <Fragment>

                            <div className="text-view">
                                <label>Curriculum</label>
                                <span>
                                    {studentValues.curriculum}
                                </span>
                            </div>
                            <div className="text-view">
                                <label>curriculum Code</label>
                                <span>
                                    {studentValues.curriculumCode}
                                </span>
                            </div>
                            {props.role !== 'teacher' && <Fragment>   <div className="text-view">
                                <label>gender</label>
                                <span>
                                    {studentValues.gender}
                                </span>
                            </div>
                                <div className="text-view">
                                    <label>Date of birth</label>
                                    <span>
                                        {studentValues.dob && studentValues.dob.split('T')[0]}
                                    </span>
                                </div>
                                <div className="text-view">
                                    <label>country</label>
                                    <span>
                                        {studentValues.country}
                                    </span>
                                </div>
                                <div className="text-view">
                                    <label>state</label>
                                    <span>
                                        {studentValues.state}
                                    </span>
                                </div>

                                <div className="text-view">
                                    <label>city</label>
                                    <span>
                                        {studentValues.city}
                                    </span>
                                </div>
                                <hr />
                                <div className="text-view">
                                    <label>Parent/Gaurdian Name</label>
                                    <span>
                                        {studentValues.parentOrGardien && studentValues.parentOrGardien.parentFirstName}
                                        {studentValues.parentOrGardien &&  studentValues.parentOrGardien.parentMiddleName}
                                        {studentValues.parentOrGardien && studentValues.parentOrGardien.parentLastName}

                                    </span>
                                </div>
                                <div className="text-view">
                                    <label>Parent/Gaurdian Email</label>
                                    <span>
                                        {studentValues.parentOrGardien && studentValues.parentOrGardien.parentEmail}


                                    </span>
                                </div>
                                <div className="text-view">
                                    <label>Parent/Gaurdian Mobile</label>
                                    <span>
                                        {studentValues.parentOrGardien && studentValues.parentOrGardien.parentMobile}


                                    </span>
                                </div>
                                <div className="text-view">
                                    <label>Parent/Gaurdian Address</label>
                                    <span>
                                        {studentValues.parentOrGardien && studentValues.parentOrGardien.parentAddress}


                                    </span>
                                </div>
                            </Fragment>}
                        </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ViewStudent