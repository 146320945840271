import React, { Component } from "react";
import PracticalSession from "../../components/OrgAdmin/PracticalSession/PracticalSession";
import { userID } from "../../services/Authentication/AuthService";

class PracticalSessionContainer extends Component {
  state = {};

  componentDidMount() {}
  render() {
    return (
      <React.Fragment>
        <PracticalSession />
      </React.Fragment>
    );
  }
}
export default PracticalSessionContainer;
