import React from "react";
import moment from "moment";
import parse from "html-react-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "../../Students/Assignments/Assignments.module.css";

const AssignmentCorrection = (props) => {
  const {
    assignmentIndividual: data,
    pendingQuestions,
    submitCorrection,
    manualMarks,
  } = props;

  return (
    <React.Fragment>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body pad-0">
            <h1 className="form-heading">Assessment Correction</h1>
            <div className="row mt-3">
              <div className="col-md-4">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th>Assessment Name</th>
                      <td>{data.assessmentName}</td>
                    </tr>
                    <tr>
                      <th>Assessment Type</th>
                      <td>{data.type === "Public" ? "Informal" : "Formal"}</td>
                    </tr>
                    <tr>
                      <th>Curriculum</th>
                      <td>{data.curriculum}</td>
                    </tr>
                    <tr>
                      <th>Subject</th>
                      <td>{data.subject}</td>
                    </tr>
                    <tr>
                      <th>
                        Start Date <small>(DD/MM/YYYY)</small>
                      </th>
                      <td>{moment(data.startdate).format("DD/MM/YYYY")}</td>
                    </tr>
                    <tr>
                      <th>
                        End Date <small>(DD/MM/YYYY)</small>
                      </th>
                      <td>{moment(data.enddate).format("DD/MM/YYYY")} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-8">
                {pendingQuestions &&
                  pendingQuestions.length > 0 &&
                  pendingQuestions.map((q, i) => {
                    if (q.questionType === "Multi") {
                      return (
                        <div className={`${styles.quesItem}`} key={q._id}>
                          <div className={`${styles.quesTitle}`}>
                            {q.question ? (
                              <React.Fragment>
                                <span>Q{i + 1} :</span> {parse(q.question)}
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="ui form">
                            <div className={`${styles.radioCheckbox}`}>
                              <i className="las la-times-circle"></i>
                              &nbsp;
                              <input
                                type="radio"
                                name={`question${i + 1}`}
                                checked={
                                  q.answer1 === q.response ? true : false
                                }
                                disabled={true}
                              />
                              <label>{q.answer1}</label>
                            </div>
                            <div className={`${styles.radioCheckbox}`}>
                              <i className="las la-times-circle"></i>
                              &nbsp;
                              <input
                                type="radio"
                                name={`question${i + 1}`}
                                checked={
                                  q.answer2 === q.response ? true : false
                                }
                                disabled={true}
                              />
                              <label>{q.answer2}</label>
                            </div>
                            <div className={`${styles.radioCheckbox}`}>
                              <i className="las la-times-circle"></i>
                              &nbsp;
                              <input
                                type="radio"
                                name={`question${i + 1}`}
                                checked={
                                  q.answer3 === q.response ? true : false
                                }
                                disabled={true}
                              />
                              <label>{q.answer3}</label>
                            </div>
                            <div className={`${styles.radioCheckbox}`}>
                              <i className="las la-check-circle"></i>
                              &nbsp;
                              <input
                                type="radio"
                                name={`question${i + 1}`}
                                checked={
                                  q.correctanswer === q.response ? true : false
                                }
                                disabled={true}
                              />
                              <label>{q.correctanswer}</label>
                            </div>
                          </div>
                          <React.Fragment>
                            <div className="form-group mt-4">
                              <div className="row">
                                <div className="col-md-2 pl-0">
                                  <label>Marks</label>
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      q.valid === true ? q.questionmarks : "0"
                                    }

                                    disabled={true}
                                  />
                                </div>
                                <div className="col-md-4">
                                  Marks for this question:{" "}
                                  <span className="badge badge-info">
                                    {q.questionmarks}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      );
                    } else {
                      return (
                        <div className={`${styles.quesItem}`} key={q._id}>
                          <div className={`${styles.quesTitle}`}>
                            {q.question ? (
                              <React.Fragment>
                                <span>Q{i + 1} :</span> {parse(q.question)}
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="ui form">
                            <CKEditor
                              editor={ClassicEditor}
                              data={q.response}
                            />
                          </div>
                          <React.Fragment>
                            <div className="form-group mt-4">
                              <div className="row">
                                <div className="col-md-2  pl-0">
                                  <label>Marks</label>
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => manualMarks(e, q, "marks")}
                                  />
                                </div>
                                <div className="col-md-4">
                                  Marks for this question:{" "}
                                  <span className="badge badge-info">
                                    {q.questionmarks}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="form-group mt-4">
                              <div className="row">
                                <div className="col-md-2  pl-0">
                                  <label>Comments</label>
                                </div>
                                <div className="col-md-10">
                                  <textarea
                                    className="form-control"
                                    row="3"
                                    onChange={(e) =>
                                      manualMarks(e, q, "comments")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      );
                    }
                  })}

                <div className="col-md-12 text-center mb-4">
                  <button
                    className="btn btn-primary"
                    onClick={() => submitCorrection(pendingQuestions)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AssignmentCorrection;
