import DashboardContainer from "./DashboardContainer";
import MyQueriesContainer from "./MyQueriesContainer";
import AssignmentsContainer from "./AssignmentsContainer";
import CurriculumContainer from "./CurriculumContainer";
import HelpTutorContainer from "./HelpTutorContainer";
import LabContainer from "./LabContainer";
import SeesionContainer from "./SessionContainer";
import Calenders from "../../components/Schedule/Calender";
import StudentProfileContainer from "./StudentProfileContainer";
import CertificateContainer from "./certificateContainer";
import StudentTrainingContainer from "./StudentTrainingContainer";
import SubscriptionContainer from "./SubscriptionContainer";

export default [
  {
    path: "dashboard",
    component: DashboardContainer,
  },
  {
    path: "StudentTraining",
    component: StudentTrainingContainer,
  },
  {
    path: "my-queries/open",
    component: MyQueriesContainer,
  },
  {
    path: "my-queries/inprogress",
    component: MyQueriesContainer,
  },
  {
    path: "Subscription",
    component: SubscriptionContainer,
  },
  {
    path: "my-queries/completed",
    component: MyQueriesContainer,
  },
  {
    path: "my-queries/raise-query",
    component: MyQueriesContainer,
  },
  {
    path: "assignments",
    component: AssignmentsContainer,
  },
  {
    path: "test-view/:id",
    component: AssignmentsContainer,
  },
  {
    path: "assignment-preview/:id",
    component: AssignmentsContainer,
  },
  {
    path: "assignment-result/:id",
    component: AssignmentsContainer,
  },
  {
    path: "test-result/:id",
    component: AssignmentsContainer,
  },
  {
    path: "lab",
    component: LabContainer,
  },
  {
    path: "curriculum",
    component: CurriculumContainer,
  },
  {
    path: "video",
    component: CurriculumContainer,
  },
  {
    path: "help-from-tutor/open",
    component: HelpTutorContainer,
  },
  {
    path: "help-from-tutor/scheduled",
    component: HelpTutorContainer,
  },
  {
    path: "help-from-tutor/completed",
    component: HelpTutorContainer,
  },
  {
    path: "help-from-tutor/request-session",
    component: HelpTutorContainer,
  },
  {
    path: "sessions",
    component: SeesionContainer,
  },
  {
    path: "session-preview/:id",
    component: SeesionContainer,
  },
  {
    path: "schedule",
    component: Calenders,
  },
  {
    path: "profile",
    component: StudentProfileContainer,
  },
  {
    path: "certificate",
    component: CertificateContainer,
  },
];
