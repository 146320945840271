import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

function Select(props) {
  const { name, optname, label, value, options, error, placeholder, ...rest } =
    props;

  return (
    <div className="form-group">
      <label className="label-heading" htmlFor={name}>
        {label}
      </label>
      <Field
        as="select"
        name={name}
        optname={optname}
        id={name}
        value={value}
        // onChange={(value) =>console.log("Field","value is--",value.target.value)}
        className="form-control"
        {...rest}
      >
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={option._id || option[optname]} value={option.name}>
            {optname ? option[optname] : option.name}
          </option>
        ))}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default Select;
