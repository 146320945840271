import React, { useState } from "react";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./curriculum.css";
import Input from "../common/input";
import Select from "../common/select";
import { Link } from "react-router-dom";
import loop from "../../../assets/Images/admin-bg1.jpg";
const AddCurriculum = (props) => {
  const {
    mode,
    addCurriculumData,
    generateCurriculamName,
    handleAddCurriculum,
    handleInput,
    handleCkeditor,
    handleDates,
    handleInputImage,
    displayImage,
  } = props;

  let data = addCurriculumData;

  let date1 = new Date(data.startDate);
  let date2 = new Date(data.endDate);
  let diffTime = Math.abs(date2 - date1);
  let durationTime = (diffTime / (1000 * 60 * 60 * 24 * 30 * 12)).toFixed(1);
  data.duration = durationTime && durationTime !== "NaN" ? durationTime : "";
  data.curriculumCode = data.curriculum
    ? data.curriculum + "-" + new Date().getFullYear()
    : "";

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [effectiveDate, setEffectiveDate] = useState(null);

  // const [require, setRequire] = useState("");
  //   const [requireCOde, setRequireCode] = useState("");
  // const [requireCodeErr, setRequireCodeErr] = useState(false);

  // function formHandler(e) {
  //   e.preventDefault();
  //   console.log(require);
  //   // if(require == null)
  //   // {
  //   alert("Enter Correct Value");
  //   // }
  //   console.log("btn clicked");
  // }

  // function requireHandler(e) {
  //   setRequire(e.target.value);
  //   let req = e.target.value;

  //   if (req == null) {
  //     console.log("Invalid orgName");
  //     setRequireErr(true);
  //   } else {
  //     setRequireErr(false);
  //   }
  // }
  // function btnclick() {

  //   if (require == "") {
  //     alert("erere");
  //   } else {

  //     handleAddCurriculum();
  //   }

  //   if (requireCOde == "") {
  //     alert("erere");
  //   } else {
  //     handleAddCurriculum();
  //   }

  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddCurriculum();
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-10 mx-auto">
          <h1 className="form-heading">{mode} Curriculum</h1>
          <div className="add-btn-div">
            <Link to={`/admin/curriculum`} className="btn btn-sm btn-danger">
              Cancel
            </Link>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="card">
              <div className="card-body">
                <Input
                  type="text"
                  name="organizationName"
                  label="Organization Name"
                  value={data.organizationName ? data.organizationName : ""}
                  // onChange={requireHandler}
                  // disabled
                  onChange={handleInput}
                  required
                />
                <Input
                  type="text"
                  name="organizationCode"
                  label="Organization Code"
                  value={data.organizationCode ? data.organizationCode : ""}
                  onChange={handleInput}
                  // onChange={requireHandler}
                  // disabled
                  required
                />
                {/* <Input
                type="file"
                name="organizationImage"
                label="Organization Image"
                onChange={handleInputImage}
              // disabled
              />
              <div className="d-flex justify-content-center align-items-center" >
                <div className="logo d-flex justify-content-center align-items-center">
                  {!displayImage && <p>Select A Image</p>}
                  {displayImage && <img className="imageLogo" src={displayImage} alt="no img" />}
                </div>
              </div> */}
                <Input
                  type="text"
                  name="curriculumAward"
                  label="Curriculum Award"
                  value={data.curriculumAward ? data.curriculumAward : ""}
                  onChange={(e) => {
                    handleInput(e);
                    generateCurriculamName(e);
                  }}
                  required
                />
                <Input
                  type="text"
                  name="shortName"
                  label="Short Name"
                  value={data.shortName ? data.shortName : ""}
                  onChange={(e) => {
                    handleInput(e);
                    generateCurriculamName();
                  }}
                  required
                />
                <Input
                  type="text"
                  name="curriculum"
                  label="Curriculum Name"
                  value={data.curriculum ? data.curriculum : ""}
                  onChange={handleInput}
                  disabled
                />
                <Input
                  type="text"
                  name="curriculumCode"
                  label="Curriculum Code"
                  value={data.curriculumCode ? data.curriculumCode : ""}
                  onChange={handleInput}
                  disabled
                />
                <Input
                  type="text"
                  name="longName"
                  label="Long Name"
                  value={data.longName ? data.longName : ""}
                  onChange={handleInput}
                  required
                />
                <Select
                  label=" Experience"
                  name="experience"
                  value={data.experience ? data.experience : ""}
                  options={[
                    { _id: "1", name: "Internship" },
                    { _id: "2", name: "Placement" },
                    { _id: "3", name: "Work experience" },
                    { _id: "4", name: "Industry experience" },
                  ]}
                  onChange={handleInput}
                  required
                />
                <Select
                  label="Marks Mode"
                  name="marksMode"
                  value={data.marksMode ? data.marksMode : ""}
                  options={[
                    { _id: "1", name: "Percentage" },
                    { _id: "2", name: "Marks" },
                  ]}
                  onChange={handleInput}
                  required
                />

                <Select
                  label="Live Session"
                  name="liveSession"
                  value={
                    data.liveSession === true
                      ? "Yes"
                      : data.liveSession === false
                      ? "No"
                      : ""
                  }
                  options={[
                    { _id: "1", name: "Yes" },
                    { _id: "2", name: "No" },
                  ]}
                  onChange={handleInput}
                  required
                />

                <Select
                  label="Show Previous Curriculums"
                  name="showPreviousCurriculum"
                  value={
                    data.showPreviousCurriculum === true
                      ? "Yes"
                      : data.showPreviousCurriculum === false
                      ? "No"
                      : ""
                  }
                  options={[
                    { _id: "1", name: "Yes" },
                    { _id: "2", name: "No" },
                  ]}
                  onChange={handleInput}
                  required
                />

                <div className="form-group">
                  <label>Eligibility</label>
                  <CKEditor
                    data={data.eligibility}
                    editor={ClassicEditor}
                    onBlur={(event, editor) =>
                      handleCkeditor(editor.getData(), "eligibility")
                    }
                  />
                </div>

                <div className="form-group">
                  <label>Pass Criteria</label>
                  <CKEditor
                    data={data.passCriteria}
                    editor={ClassicEditor}
                    onBlur={(event, editor) =>
                      handleCkeditor(editor.getData(), "passCriteria")
                    }
                  />
                </div>

                <Input
                  type="text"
                  name="createDate"
                  label="Create Date (MM/DD/YYYY)"
                  value={
                    data.createDate
                      ? moment(data.createDate).format("MM/DD/YYYY")
                      : ""
                  }
                  onChange={handleInput}
                  disabled
                />

                <div className="form-group">
                  <label>Effective Date (MM/DD/YYYY)</label>
                  <DatePicker
                    name="effectiveDate"
                    selected={
                      data.effectiveDate === ""
                        ? effectiveDate
                        : new Date(
                            moment(data.effectiveDate).format("MM/DD/YYYY")
                          )
                    }
                    onChange={(date) => {
                      handleDates(date, "effectiveDate");
                      setEffectiveDate(date);
                    }}
                    minDate={new Date()}
                    className="form-control"
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="MM/DD/YYYY"
                    required
                  />
                </div>
                <div className="form-group">
                  <label> Start Date (MM/DD/YYYY)</label>
                  <DatePicker
                    name="startDate"
                    className="form-control"
                    selected={
                      data.startDate === ""
                        ? startDate
                        : new Date(moment(data.startDate).format("MM/DD/YYYY"))
                    }
                    onChange={(date) => {
                      handleDates(date, "startDate");
                      setStartDate(date);
                    }}
                    startDate={data.effectiveDate}
                    minDate={data.effectiveDate}
                    endDate={endDate}
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="MM/DD/YYYY"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>End Date (MM/DD/YYYY)</label>
                  <DatePicker
                    name="endDate"
                    className="form-control"
                    selected={
                      data.endDate === ""
                        ? endDate
                        : new Date(moment(data.endDate).format("MM/DD/YYYY"))
                    }
                    onChange={(date) => {
                      handleDates(date, "endDate");
                      setEndDate(date);
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="MM/DD/YYYY"
                    required
                  />
                </div>

                <Input
                  type="text"
                  name="duration"
                  label="Duration (in Years)"
                  value={data.duration ? data.duration : ""}
                  onChange={handleInput}
                  disabled
                />
                <Input
                  type="number"
                  name="price"
                  label="Price of Curriculum"
                  value={data.price ? data.price : ""}
          
                  min="00"
                  onChange={handleInput}
                  required
                />

                <div className="text-center">
                  <button
                    //  onClick={handleAddCurriculum}
                    // onClick={btnclick}
                    type="submit"
                    className="btn btn-primary mt-2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCurriculum;
