import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const getAssignments = (payload) => {
  return http.post(apiUrl + "/getAssignments", payload, {
    headers: headers,
  });
};

export const getAssignmentByID = (payload) => {
  return http.post(apiUrl + "/getAssignmentByID", payload, {
    headers: headers,
  });
};
export const getMeeting = (payload) => {
  return http.post(apiUrl + "/createMeeting", payload, {
    headers: headers,
  });
};
export const getTestQuesList = (payload) => {
  return http.post(apiUrl + "/getTestQuesList", payload, {
    headers: headers,
  });
};

export const getAssignmentResult = (payload) => {
  return http.post(apiUrl + "/getAssignmentsResult", payload, {
    headers: headers,
  });
};
export const testSubmit = (payload) => {
  return http.post(apiUrl + "/testSubmit", payload, {
    headers: headers,
  });
};

export const getResult = (payload) => {
  return http.post(apiUrl + "/getResult", payload, {
    headers: headers,
  });
};

export const getTestsList = (payload) => {
  return http.post(apiUrl + "/getTestsList", payload, {
    headers: headers,
  });
};
