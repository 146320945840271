import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./Assignments.module.css";
import DisplayErrorMessage from "../../Share/DisplayErrorMessage";
import { dateFormatMMDDYYYY } from "../../../utils/utils";
import cur from "../../../assets/Banners/Banner2.png";
import logo11 from "../../../assets/Icons/Icon21.png";
import logo12 from "../../../assets/Icons/Iconupcomingsession.png";
import logo13 from "../../../assets/Icons/Icon22.png";

const FormalAssignments = (props) => {
  const { assignmentList } = props;
  const currentDate = new Date(moment().format("MM/DD/YYYY")).getTime();

  const ongoingAssignments = [];
  const upComingAssignments = [];
  const completedAssignments = [];

  {
    assignmentList.length > 0 &&
      assignmentList.map((a) => {
        if (a.type === "Private") {
          const startDate = new Date(dateFormatMMDDYYYY(a.startdate)).getTime();
          const endDate = new Date(dateFormatMMDDYYYY(a.enddate)).getTime();
          const isBetween = () => {
            return currentDate >= startDate && currentDate <= endDate;
          };

          if (isBetween() === true && a.status === 0) {
            ongoingAssignments.push(a);
          } else if (isBetween() === false) {
            if (startDate > currentDate && a.status === 0) {
              upComingAssignments.push(a);
            } else {
              completedAssignments.push(a);
            }
          } else if (a.status === 1) {
            completedAssignments.push(a);
          }
        }
      });
  }
  return (
    <React.Fragment>
      <div className="container" id="containerform">
        <div className="row">
          <div className="uttam">
            <div className="row">
              <h4 className="text-heading">
                {" "}
                {/* <img
                src={logo11}
                style={{ height: "23px", width: "23px", marginRight: "5px" }}
                alt="imgh"
              /> */}
                Ongoing Assignments
              </h4>
            </div>
            <div className="row">
              {ongoingAssignments && ongoingAssignments.length === 0 && (
                <div className="col-md-12">
                  <DisplayErrorMessage message />
                </div>
              )}
              {ongoingAssignments &&
                ongoingAssignments.length > 0 &&
                ongoingAssignments.map((a) => {
                  return (
                    <div className="col-md-4" key={a._id}>
                      <div className={`cardDiv ${styles.assignmentDiv}`}>
                        <Link to={`/student/assignment-preview/${a.testID}`}>
                          <h5>{a.assessmentName}</h5>
                        </Link>
                        <p>
                          Start Date -{" "}
                          {moment(a.startdate).format("DD-MM-YYYY")}
                        </p>
                        <p>
                          End Date - {moment(a.enddate).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <br />

            <div className="row">
              <h4 className="text-heading">
                {" "}
                {/* <img
                src={logo12}
                style={{ height: "23px", width: "23px", marginRight: "5px" }}
                alt="imgh"
              /> */}
                Upcoming Assignments
              </h4>
            </div>
            <div className="row">
              {upComingAssignments && upComingAssignments.length === 0 && (
                <div className="col-md-12">
                  <DisplayErrorMessage message />
                </div>
              )}
              {upComingAssignments &&
                upComingAssignments.length > 0 &&
                upComingAssignments.map((a) => {
                  return (
                    <div className="col-md-4" key={a._id}>
                      <div className={`cardDiv ${styles.assignmentDiv}`}>
                        {/* <Link to={`/student/assignment-preview/${a.testID}`}> */}
                        <h5>{a.assessmentName}</h5>
                        {/* </Link> */}
                        <p>
                          Start Date -{" "}
                          {moment(a.startdate).format("DD-MM-YYYY")}
                        </p>
                        <p>
                          End Date - {moment(a.enddate).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <br />

            <div className="row">
              <h4 className="text-heading">
                {/* <img
                src={logo13}
                style={{ height: "23px", width: "23px", marginRight: "5px" }}
                alt="imgh"
              /> */}
                Completed Assignments
              </h4>
            </div>
            <div className="row">
              {completedAssignments && completedAssignments.length === 0 && (
                <div className="col-md-12">
                  <DisplayErrorMessage message />
                </div>
              )}
              {completedAssignments &&
                completedAssignments.length > 0 &&
                completedAssignments.map((a) => {
                  return (
                    <div className="col-md-4" key={a._id}>
                      <div className={`cardDiv ${styles.assignmentDiv}`}>
                        <Link to={`/student/assignment-result/${a.testID}`}>
                          <h5>{a.assessmentName}</h5>
                        </Link>
                        <p>
                          Start Date -{" "}
                          {moment(a.startdate).format("DD-MM-YYYY")}
                        </p>
                        <p>
                          End Date - {moment(a.enddate).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {/* <div className="col-md-3">
          <h4 className="text-heading">Assignment Summary</h4>
          <div className="col-md-12 mb-3" style={{ overflow: "hidden" }}>
            <div className={styles.testSummary}>
              <p>
                Ongoing
                <span className={`badge badge-primary ${styles.badge}`}>
                  {ongoingAssignments.length}
                </span>
              </p>
              <p>
                Upcoming
                <span className={`badge badge-success ${styles.badge}`}>
                  {upComingAssignments.length}
                </span>
              </p>
              <p>
                Completed{" "}
                <span className={`badge badge-danger ${styles.badge}`}>
                  {completedAssignments.length}
                </span>
              </p>
            </div>
          </div> */}

          <br />

          {/* <h4 className="text-heading">Subject Wise Tests</h4> */}
          {/* <div className="col-md-12" style={{ overflow: "hidden" }}>
            <div className={styles.testSummary}>
              <p>
                Physics
                <span className={`badge badge-primary ${styles.badge}`}>
                  10
                </span>
              </p>
              <p>
                Maths
                <span className={`badge badge-success ${styles.badge}`}>6</span>
              </p>
              <p>
                English
                <span className={`badge badge-danger ${styles.badge}`}>4</span>
              </p>
            </div>
          </div> */}
          {/* </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FormalAssignments;
