import React, { useEffect, useState } from "react";


const UserInfo = (props) => {
    console.log(JSON.stringify(props))
    return <div className="container">
        <div className="row justify-content-center">
            <div className="col-md-10">
                <h1 className="form-heading">User Info</h1>

                <div className=" row justify-content-center m-3">
                    {!props.user.isEmail && <p className='text-danger'>Email verification pending</p>}
                </div>
                {!props.user.isActivate && <div className=" row justify-content-center m-3">

                    <button className="btn btn-sm btn-success mr-3" onClick={() => props.active({ id: props.user._id, isActive: true })}>
                        Accept
                    </button>
                    <button className="btn btn-sm btn-danger" onClick={() => props.active({ id: props.user._id, isActive: false })}>
                        Reject
                    </button>
                </div>}
                <div className="card">
                    <div className="card-body">
                        <div className="text-view">
                            <label>Name</label>
                            <span>
                                {props.user.firstName + ' ' + props.user.lastName + ' ' + props.user.lastName}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>Mobile No</label>
                            <span>
                                {props.user.mobile}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>email</label>
                            <span>
                                {props.user.email}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>gender</label>
                            <span>
                                {props.user.gender}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>dob</label>
                            <span>
                                {props.user.dob}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>curriculum</label>
                            <span>
                                {props.user.curriculum}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>curriculumCode</label>
                            <span>
                                {props.user.curriculumCode}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>address</label>
                            <span>
                                {props.user.address}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>country</label>
                            <span>
                                {props.user.country}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>state</label>
                            <span>
                                {props.user.state}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>city</label>
                            <span>
                                {props.user.city}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>pincode</label>
                            <span>
                                {props.user.pincode}
                            </span>
                        </div>
                        <div className="text-view">
                            <label>city</label>
                            <span>
                                city
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
}

export default UserInfo;