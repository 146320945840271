import React from "react";
import styles from "./StudentTraining.module.css";
import image from "../../..//assets/Images/admin-bg2.jpg";
import image1 from "../../..//assets/Images/admin-bg3.jpg";
import image2 from "../../..//assets/Images/admin-bg4.jpg";

export default function StudentTraining() {
  return (
    <React.Fragment>
      <div className="container" id="containers">
        <h4>Java Development</h4>
        <div className="row">
          <div className="col-md-3">
            <a href="https://www.youtube.com/watch?v=Px4Lm8NixtE">
              <img src={image} style={{ width: "100%", height: "100%" }} />
            </a>

            <p>Java Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image} style={{ width: "100%", height: "100%" }} />
            <p>Java Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image} style={{ width: "100%", height: "100%" }} />
            <p>Java Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image} style={{ width: "100%", height: "100%" }} />
            <p>Java Tutorial for Complete Beginners</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-3">
            <img src={image1} style={{ width: "100%", height: "100%" }} />
            <p>Java Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image1} style={{ width: "100%", height: "100%" }} />
            <p>Java Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image1} style={{ width: "100%", height: "100%" }} />
            <p>Java Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image1} style={{ width: "100%", height: "100%" }} />
            <p>Java Tutorial for Complete Beginners</p>
          </div>
        </div>
        <h4 className="mt-5">UI Design</h4>
        <div className="row">
          <div className="col-md-3">
            <img src={image2} style={{ width: "100%", height: "100%" }} />
            <p>UI Design Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image2} style={{ width: "100%", height: "100%" }} />
            <p>UI Design Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image2} style={{ width: "100%", height: "100%" }} />
            <p>UI Design Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image2} style={{ width: "100%", height: "100%" }} />
            <p>UI Design Tutorial for Complete Beginners</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-3">
            <img src={image} style={{ width: "100%", height: "100%" }} />
            <p>UI Design Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image} style={{ width: "100%", height: "100%" }} />
            <p>UI Design Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image} style={{ width: "100%", height: "100%" }} />
            <p>UI Design Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image} style={{ width: "100%", height: "100%" }} />
            <p>UI Design Tutorial for Complete Beginners</p>
          </div>
        </div>
        <h4 className="mt-5">Digital Marketing</h4>
        <div className="row">
          <div className="col-md-3">
            <img src={image2} style={{ width: "100%", height: "100%" }} />
            <p>Digital Marketing Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image2} style={{ width: "100%", height: "100%" }} />
            <p>Digital Marketing Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image2} style={{ width: "100%", height: "100%" }} />
            <p>Digital Marketing Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image2} style={{ width: "100%", height: "100%" }} />
            <p>Digital Marketing Tutorial for Complete Beginners</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-3">
            <img src={image} style={{ width: "100%", height: "100%" }} />
            <p>Digital Marketing Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image} style={{ width: "100%", height: "100%" }} />
            <p>Digital Marketing Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image} style={{ width: "100%", height: "100%" }} />
            <p>Digital Marketing Tutorial for Complete Beginners</p>
          </div>
          <div className="col-md-3">
            <img src={image} style={{ width: "100%", height: "100%" }} />
            <p>Digital Marketing Tutorial for Complete Beginners</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
