import React, { Component } from "react";
import LabType from "../../components/Students/Lab/LabType";
import FormalLab from "../../components/Students/Lab/FormalLab";
import InformalLab from "../../components/Students/Lab/InformalLab";

import { getAssignments } from "../../services/Student/AssignmentService";
import { userCurriculumCode } from "../../services/Authentication/AuthService";

class LabContainer extends Component {
  state = {
    isType: "formal",
    assignmentList: {},
  };

  labType = (type) => {
    if (type === "formal") {
      this.setState({ isType: "formal" });
    } else if (type === "informal") {
      this.setState({ isType: "informal" });
    }
  };

  getAllAssignments = async () => {
    const payload = {
      curriculumCode: userCurriculumCode(),
      labSession: true,
    };

    getAssignments(payload)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ assignmentList: res.data.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  componentDidMount() {
    this.getAllAssignments();
  }

  render() {
    const { isType, assignmentList } = this.state;
    return (
      <React.Fragment>
        <LabType isType={isType} labType={this.labType} />
        {isType === "formal" && <FormalLab assignmentList={assignmentList} />}
        {isType === "informal" && (
          <InformalLab assignmentList={assignmentList} />
        )}
      </React.Fragment>
    );
  }
}

export default LabContainer;
