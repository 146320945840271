import React, { useState, useEffect, Fragment } from "react";
import { Formik, Form } from "formik";
import { staffValidation } from "../../../validations/AdminValidations";
import FormikControl from "../../../common/Formik/FormikControl";
import { curriculumListFun, subjectListFun } from "../../../utils/utils";
import Autocomplete from "react-autocomplete";
import Autocomplete1 from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { Country, State, City } from "country-state-city";

const EditStudent = (props) => {
  let [country, setcountry] = useState([]);
  let [state, setstate] = useState([]);
  let [city, setcity] = useState([]);
  let [countrycode, setcountrycode] = useState("");
  let [Statedis, setStatedis] = useState(false);
  let [Citydis, setCitydis] = useState(false);

  function countrychange(e) {
    setStatedis(true);
    setcountrycode(e.split(",")[0]);
    setstate([...State.getStatesOfCountry(e.split(",")[0])]);
  }

  function statechange(e) {
    setCitydis(true);
    setcity([...City.getCitiesOfState(countrycode, e.split(",")[0])]);
  }

  function citychange() {}

  useEffect(() => {
    setcountry([...Country.getAllCountries()]);
  }, []);

  const {
    mode,
    handlestudentAdd,
    studentValues,
    curriculumList,
    handleCurriculum,
    handleInput,
    editError,
    handleInput1,
  } = props;
  const [n1, setn1] = useState();
  const handelSubmit = (form) => {
    console.log("form" + JSON.stringify(form));
  };
  const history = useHistory();

  let curriculumOptions = curriculumListFun(curriculumList);
  const handleChange1 = (event, value) => {
    console.log(value.curriculum);
    handleInput1(value.curriculum, "curriculum", "");
    setn1(value);
    console.log(event);
  };

  return (
    <Fragment>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="d-flex flex-row">
              <h1
                className="form-heading"
                style={{ textTransform: "capitalize" }}
              >
                {mode} Student
              </h1>
              <button
                className="btn btn-sm btn-danger mb-4"
                onClick={() => history.goBack()}
              >
                Close
              </button>
            </div>
            <div className="card">
              <div className="card-body">
                <Formik
                  initialValues={studentValues}
                  validationSchema={staffValidation}
                  validateOnMount
                  enableReinitialize
                >
                  {(formik) => {
                    const { handleChange, handleSubmit } = formik;
                    return (
                      <Form>
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              control="input"
                              type="text"
                              label="First Name"
                              id="firstName"
                              name="firstName"
                              placeholder="First name"
                            />
                          </div>
                          <div className="col-md-6">
                            <FormikControl
                              control="input"
                              type="text"
                              label="Middle name"
                              id="middleName"
                              name="Middle name"
                              placeholder="Middle name"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              control="input"
                              type="text"
                              label="Last Name"
                              id="lastName"
                              name="lastName"
                              placeholder="Last name"
                            />
                          </div>
                          <div className="col-md-6">
                            <FormikControl
                              control="input"
                              type="text"
                              label="Mobile Number"
                              id="mobile"
                              name="mobile"
                              placeholder="mobile number"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              control="input"
                              type="text"
                              label="Email"
                              id="email"
                              name="email"
                              placeholder="Email"
                            />
                          </div>
                          <div className="col-md-6">
                            <FormikControl
                              control="input"
                              type="text"
                              label="Password"
                              id="password"
                              name="password"
                              placeholder="Password"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              control="select"
                              name="gender"
                              label="Gender"
                              placeholder="Select Gender"
                              options={[
                                { _id: 1, name: "Male" },
                                { _id: 2, name: "Female" },
                                { _id: 2, name: "Other" },
                              ]}
                              value={studentValues.gender}
                            />
                          </div>
                          <div className="col-md-6">
                            <FormikControl
                              control="date"
                              placeholderText="Date of Birth"
                              name="dob1"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              value={studentValues.dob ? studentValues.dob : ""}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            {studentValues.curriculum && (
                              <Autocomplete1
                                disablePortal
                                id="combo-box-demo"
                                options={curriculumOptions}
                                getOptionLabel={(option) => option.curriculum}
                                style={{ width: "100%" }}
                                onChange={(event, value) => {
                                  handleInput1(
                                    value.curriculum,
                                    "curriculum",
                                    ""
                                  );
                                }}
                                disableClearable={true}
                                defaultValue={{
                                  curriculum: studentValues.curriculum
                                    ? studentValues.curriculum
                                    : "",
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={studentValues.curriculum}
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              control="input"
                              type="text"
                              label="Parent/Gaurdian First Name"
                              placeholder="Parent/Gaurdian First Name"
                              id="parentOrGardien.parentFirstName"
                              name="parentOrGardien.parentFirstName"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              control="input"
                              type="text"
                              label="Parent/Gaurdian Middle Name"
                              name="parentOrGardien.parentMiddleName"
                              placeholder="Parent/Gaurdian Middle Name"
                            />
                          </div>
                          <div className="col-md-6">
                            <FormikControl
                              control="input"
                              type="text"
                              label="Parent/Gaurdian Last Name"
                              name="parentOrGardien.parentLastName"
                              placeholder="Parent/Gaurdian Last Name"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              control="input"
                              type="text"
                              label="Parent/Gaurdian Mobile"
                              name="parentOrGardien.parentMobile"
                              placeholder="Parent/Gaurdian Mobile"
                            />
                          </div>
                          <div className="col-md-6">
                            <FormikControl
                              control="input"
                              type="text"
                              label="Parent/Gaurdian Email"
                              name="parentOrGardien.parentEmail"
                              placeholder="Parent/Gaurdian Email"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <FormikControl
                              control="textarea"
                              name="parentOrGardien.parentAddress"
                              className="form-control"
                              label="Parent/Gaurdian Address"
                              placeholder="Parent/Gaurdian Address"
                              rows="3"
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-md-12">
                            <FormikControl
                              control="textarea"
                              label="Address"
                              type="text"
                              name="address"
                              rows="3"
                              placeholder="Address"
                            />
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="country"
                              placeholder="Country"
                              label="Country"
                            />
                          </div>
                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="state"
                              placeholder="State"
                              label="State"
                            />
                          </div>
                          <div className="col-md-4">
                            <FormikControl
                              control="input"
                              type="text"
                              name="city"
                              placeholder="City"
                              label="City"
                            />
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-md-4">
                            <select
                              className="kuchbhiclass"
                              onChange={(e) => countrychange(e.target.value)}
                            >
                              <option disabled>---select---</option>
                              {country &&
                                country.map((e, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={`${e.isoCode},${e.name}`}
                                    >
                                      {e.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="col-md-4">
                            <select
                              className="kuchbhiclass"
                              aria-label="Default select example"
                              ariaPlaceholder="dlfahdkf"
                              onChange={(e) => statechange(e.target.value)}
                            >
                              <option value="" disabled={Statedis}>
                                {" "}
                                Select State{" "}
                              </option>
                              {state &&
                                state.map((e, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={`${e.isoCode},${e.name}`}
                                    >
                                      {e.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="col-md-4 mb-3">
                            <select
                              className="kuchbhiclass"
                              onChange={(e) => citychange(e.target.value)}
                            >
                              <option value="" disabled={Citydis}>
                                {" "}
                                Select City{" "}
                              </option>
                              {city &&
                                city.map((e, index) => {
                                  return (
                                    <option key={index} value={`{e.name}`}>
                                      {e.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormikControl
                              control="input"
                              type="text"
                              label="Pin Code"
                              name="pincode"
                              placeholder="Pin Code"
                            />
                          </div>
                          {/* <div className="col-md-6">
                                                <FormikControl
                                                    control="input"
                                                    type="file"
                                                    name="identityUrl"
                                                />
                                            </div> */}
                        </div>
                        <div className="text-center col-md-12">
                          <p className="text-uppercase text-danger">
                            {editError}
                          </p>
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary mt-4"
                            onClick={() => handlestudentAdd(formik)}
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditStudent;
