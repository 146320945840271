import React from "react";
import { Link } from "react-router-dom";

const PracticalSession = ({ searchCurr, search }) => {
  return (
    <React.Fragment>
      <div>
        <h3 className="text-center">Practical Session</h3>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Courses</th>

                  <th scope="col">Online Session</th>
                  <th scope="col">Live Session</th>
                  <th scope="col">Offline Session</th>
                  <th scope="col">Only exams</th>
                </tr>

                <td>Python Full Course</td>

                <td>
                  <a href=""> Link</a>
                </td>
                <td>
                  <a href=""> Link</a>
                </td>
                <td>
                  <a href=""> Link</a>
                </td>
                <td>
                  <a href=""> Link</a>
                </td>

                <tr>
                  <td>Node Full Course</td>

                  <td>
                    <a href=""> Link</a>
                  </td>
                  <td>
                    <a href=""> Link</a>
                  </td>
                  <td>
                    <a href=""> Link</a>
                  </td>
                  <td>
                    <a href=""> Link</a>
                  </td>
                </tr>

                <td>Java Full Course</td>

                <td>
                  <a href=""> Link</a>
                </td>
                <td>
                  <a href=""> Link</a>
                </td>
                <td>
                  <a href=""> Link</a>
                </td>
                <td>
                  <a href=""> Link</a>
                </td>
                <tr>
                  <td>Angular Full Course</td>

                  <td>
                    <a href=""> Link</a>
                  </td>
                  <td>
                    <a href=""> Link</a>
                  </td>
                  <td>
                    <a href=""> Link</a>
                  </td>
                  <td>
                    <a href=""> Link</a>
                  </td>
                </tr>
                <td>web development Full Course</td>

                <td>
                  <a href=""> Link</a>
                </td>
                <td>
                  <a href=""> Link</a>
                </td>
                <td>
                  <a href=""> Link</a>
                </td>
                <td>
                  <a href=""> Link</a>
                </td>
                <tr>
                  <td>Dot net Full Course</td>

                  <td>
                    <a href=""> Link</a>
                  </td>
                  <td>
                    <a href=""> Link</a>
                  </td>
                  <td>
                    <a href=""> Link</a>
                  </td>
                  <td>
                    <a href=""> Link</a>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PracticalSession;
