import * as Yup from "yup";

const loginValidation = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
});

const staffValidation = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  mobile: Yup.number().required("Required"),
  role: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  curriculum: Yup.string().when("role", {
    is: "teacher",
    then: Yup.string().required("Required"),
  }),
  subject: Yup.string().when("role", {
    is: "teacher",
    then: Yup.string().required("Required"),
  }),
});

const staffRoleValidation = Yup.object().shape({
  roleName: Yup.string().required("Required"),
});

const questionValidation = Yup.object().shape({
  curriculum: Yup.string().required("Required"),
  subject: Yup.string().required("Required"),
  topic: Yup.string().required("Required"),
  chapter: Yup.string().required("Required"),
  correctanswer: Yup.string().when("questionType", {
    is: "Multi",
    then: Yup.string().required("Required"),
  }),
  answer1: Yup.string().when("questionType", {
    is: "Multi",
    then: Yup.string().required("Required"),
  }),
  answer2: Yup.string().when("questionType", {
    is: "Multi",
    then: Yup.string().required("Required"),
  }),
  answer3: Yup.string().when("questionType", {
    is: "Multi",
    then: Yup.string().required("Required"),
  }),
  questionmarks: Yup.string()
    .required("Required")
    .matches(/^[1-9]\d*$/, "Valid numbers 1 to 9 only"),
  questionType: Yup.string().required("Required"),
});

const scheduleValidation = Yup.object().shape({
  title: Yup.string().required("Required"),
  curriculum: Yup.string().required("Required"),
  subject: Yup.string().required("Required"),
  teacher: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  date: Yup.string().required("Required"),
  startTime: Yup.string().required("Required"),
  endTime: Yup.string().required("Required"),
});

const subjectValidation = Yup.object().shape({
  curriculum: Yup.string().required("Required"),
  subject: Yup.string().required("Required"),
  startDate: Yup.date().required("Required"),
  endDate: Yup.date()
    .required("Required")
    .when(
      "startDate",
      (startDate, schema) => startDate && schema.min(startDate)
    ),
  minCredits: Yup.string().required("Required"),
  maxCredits: Yup.string().required("Required"),
  practicalSession: Yup.string().required("Required"),
});

const assignmentValidation = Yup.object().shape({
  assessmentName: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  curriculum: Yup.string().required("Required"),
  subject: Yup.string().required("Required"),
  topic: Yup.string(),
  chapter: Yup.string(),
  startdate: Yup.string().required("Required"),

  enddate: Yup.date()
    .required("Required")
    .when(
      "startdate",
      (startdate, schema) => startdate && schema.min(startdate),
      "adasd"
    ),
  assignmentMode: Yup.string().required("Required"),
  maxQuestions: Yup.number().positive().required("Required"),
  maxWeightage: Yup.number().required("Required"),

  optionalQuestions: Yup.number().when("assignmentMode", {
    is: "Optional",
    then: Yup.number()
      .required("Required")
      .positive()
      .lessThan(Yup.ref("maxQuestions"), "Cannot Exceed Max Questions")
      .integer(),
  }),

  optQuestionMarks: Yup.number().when("assignmentMode", {
    is: "Optional",
    then: Yup.number().required("Required").positive().integer(),
  }),
  labSession: Yup.string().required("Required"),
  assessmentType: Yup.string().required("Required"),
});

export {
  loginValidation,
  staffValidation,
  staffRoleValidation,
  questionValidation,
  scheduleValidation,
  subjectValidation,
  assignmentValidation,
};
