import React, { Component } from "react";
import SessionType from "../../components/Students/Sessions/SessionType";
import UpcomingSessions from "../../components/Students/Sessions/UpcomingSessions";
import CompletedSessions from "../../components/Students/Sessions/CompletedSessions";
import SessionPreview from "../../components/Students/Sessions/SeesionPreview";
import {getUserCurriculum} from "../../services/Student/CurriculumStudentService"

import {
  getAllSessions,
  getCompletedSession,
  getSessionByID,
  getUpcomingSession,
} from "../../services/Student/SessionService";

import {
  userID,
  userCurriculumCode,
} from "../../services/Authentication/AuthService";

class SeesionContainer extends Component {
  state = {
    isType: "upcoming",
    allSessions: [],
    sessionIndividual: [],
    upcomingSession: [],
    completedSession: [],
    curriculumCode:[]
  };

  getAllSessionsList = async () => {
    const payload = {
      role: "student",
      curriculumCode: userCurriculumCode(),
    };
    getAllSessions(payload)
      .then((res) => {
        // console.log("res", res);
        this.setState({ allSessions: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  sessionPreview = async () => {
    const id = this.props.match.params.id;
    const payload = {
      curriculumCode: userCurriculumCode(),
      sessionID: parseInt(id),
      userID: userID(),
    };
    console.log(this.props.match.params.id);
    getSessionByID(payload)
      .then((res) => {
        this.setState({ sessionIndividual: res.data.data[0] });
        console.log(this.state.sessionIndividual);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  upcomingSession= () => { 
    getUserCurriculum(userID()).then((res)=>{
    var data=res.data.data
   let code=[]
   data.map((e)=>{
     code.push(e.curriculumCode)
   })
   const payload = {
     curriculumCode:code

   };
   getUpcomingSession(payload).then(res => {
     if (res.status === 200) {
       let arr = res.data.data.filter(el=>{
         return el.status !=='PENDING'
       })
       this.setState({ upcomingSession: arr })
       console.log(this.state.upcomingSession,"<-session");
     } else {
       this.setState({ upcomingSessionList:[]})
     }
   })
 })
}
  componentDidMount() {
    this.upcomingSession()
    this.completedsession()
    this.getAllSessionsList();
    if (this.props.match.path === "/student/session-preview/:id") {
      this.sessionPreview();
    }
  }
 

  completedsession = () => {
    getUserCurriculum(userID()).then((res)=>{
      var data=res.data.data
     let code=[]
     data.map((e)=>{
       code.push(e.curriculumCode)
     })
     const payload = {
       curriculumCode:code
  
     };
    getCompletedSession(payload).then(res => {
      if (res.status === 200) {
        this.setState({ completedSession: res.data.data })
      } else {

      }
    })
  })
  }
  sessionType = (type) => {
    if (type === "upcoming") {
      this.setState({ isType: "upcoming" });
    } else if (type === "completed") {
      this.setState({ isType: "completed" });
    }
  };
  render() {
    const path = this.props.match.path;
    const { isType, allSessions, sessionIndividual } = this.state;
    return (
      <React.Fragment>
        {path === "/student/sessions" && (
          <React.Fragment>
            <SessionType isType={isType} sessionType={this.sessionType} />
            {isType === "upcoming" && (
              <UpcomingSessions
                allSessions={allSessions}
                sessionPreview={this.sessionPreview}
                upcomingSession={this.state.upcomingSession}
              />
            )}
            {isType === "completed" && (
              <CompletedSessions
                allSessions={allSessions}
                sessionPreview={this.sessionPreview}
                completedSession={this.state.completedSession}
              />
            )}
          </React.Fragment>
        )}
        {path === "/student/session-preview/:id" && (
          <SessionPreview sessionIndividual={sessionIndividual} />
        )}
      </React.Fragment>
    );
  }
}

export default SeesionContainer;
