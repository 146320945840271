import React from 'react';
import { useHistory } from "react-router-dom";


const QueryType = (props) => {
    const { queryType } = props
    const path = useHistory().location.pathname
    console.log('hos' + path)
    return <div className="row col-md-12 mb-2">
        <div className={`tabItem ${path === "/teacher/queries-list" ? "tabItemActive" : ""}`} onClick={() => queryType('open')}>open</div>
        <div className={`tabItem ${path === "/teacher/queries-inprogess" ? "tabItemActive" : ""}`   } onClick={() => queryType('inprogress')}>Inprogress</div>

        <div className={`tabItem ${path === "/teacher/queries-closed" ? "tabItemActive" : ""}`} onClick={() => queryType('closed')}>closed</div>

    </div>
}
export default QueryType