import React from "react";
import { useHistory } from "react-router-dom";
import help from '../../../assets/Banners/Banner5.png'
import styles from "./HelpFromTutor.module.css";

const RequestType = ({ requestType, raiseRequest }) => {
  const path = useHistory().location.pathname;
  return (
    <React.Fragment>
      
      <div className="col-md-12 row">
        <div
          className={`tabItem ${path === "/student/help-from-tutor/open" ? "tabItemActive" : ""
            }`}
          onClick={() => requestType("open")}
        >
          Open
        </div>
        <div
          className={`tabItem ${path === "/student/help-from-tutor/scheduled" ? "tabItemActive" : ""
            }`}
          onClick={() => requestType("scheduled")}
        >
          Scheduled
        </div>
        {/* <div
          className={`tabItem ${path === "/student/help-from-tutor/completed" ? "tabItemActive" : ""
            }`}
          onClick={() => requestType("completed")}
        >
          Completed
        </div> */}

        <button
          className="btn btn-primary mb-3 btn-right mr-0"
          onClick={raiseRequest}
        >
          Request A Session
        </button>
      </div>
    </React.Fragment>
  );
};

export default RequestType;
