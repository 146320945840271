import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./question.css";
import { Formik, Form, ErrorMessage } from "formik";
import { questionValidation } from "../../../validations/AdminValidations";
import FormikControl from "../../../common/Formik/FormikControl";
import { makeStyles } from "@material-ui/core/styles";

import {
  curriculumListFun,
  subjectListFun,
  topicListFun,
  chapterListFun,
} from "../../../utils/utils";
import "../../../common/Formik/Common.css";
import Autocomplete1 from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import TextError from "../../../common/Formik/TextError";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
  auto: {
    width: "85%",
    position: "absolute",
  },
});
const AddQuestion = (props) => {
  const classes = useStyles();
  const {
    mode,
    questionValues,
    curriculumList,
    subjectsList,
    topicsList,
    chaptersList,
    handleInput,
    handleSubmit,
    handleQuestionName,
    questionError,
    handleInput1,
    handleFileChange
  } = props;

  const data = questionValues;
  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);
  let topicOptions = topicListFun(topicsList);
  let chapterOptions = chapterListFun(chaptersList);

  console.log("data//", subjectOptions);

  const [ans1, setAns1] = useState("/icons/Placeholder.png");
  const [ans2, setAns2] = useState("/icons/Placeholder.png");
  const [ans3, setAns3] = useState("/icons/Placeholder.png");
  const [ans4, setAns4] = useState("/icons/Placeholder.png");

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <h1 className="form-heading">{mode} Question Library</h1>
          <div className="add-btn-div">
            <Link to={`/admin/question`} className="btn btn-sm btn-danger">
              Cancel
            </Link>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={data}
                validationSchema={questionValidation}
                validateOnMount
                enableReinitialize
              >
                {(formik) => {
                  //console.log("formik", formik);
                  const { handleChange } = formik;
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="label-heading"
                              htmlFor="curriculum"
                            >
                              curriculums
                            </label>

                            {mode === "Add" && (
                              <Autocomplete1
                                disablePortal
                                id="combo-box-demo"
                                // className="form-control"
                                options={curriculumOptions}
                                getOptionLabel={(option) => option.curriculum}
                                onChange={(event, value) => {
                                  if (value === null) {
                                    handleInput1("", "curriculum");
                                    return;
                                  }
                                  handleInput1(value.curriculum, "curriculum");
                                }}
                                disableClearable={true}
                                style={{ width: "100%", height: "38px" }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                            )}
                            {mode === "Edit" && data.curriculum && (
                              <Autocomplete1
                                disablePortal
                                id="combo-box-demo"
                                options={curriculumOptions}
                                getOptionLabel={(option) => option.curriculum}
                                onChange={(event, value) => {
                                  if (value === null) {
                                    handleInput1("", "curriculum");
                                    return;
                                  }
                                  handleInput1(value.curriculum, "curriculum");
                                }}
                                disableClearable={true}
                                defaultValue={{
                                  curriculum: data.curriculum
                                    ? data.curriculum
                                    : "",
                                }}
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  overflow: "hidden",
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                            )}
                            <ErrorMessage
                              component={TextError}
                              name="curriculum"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group ">
                            <label
                              className="label-heading"
                              htmlFor="curriculum"
                            >
                              subject
                            </label>

                            {mode === "Add" && (
                              <Autocomplete1
                                disablePortal
                                id="combo-box-demo"
                                options={subjectOptions}
                                getOptionLabel={(option) => option.subject}
                                onChange={(event, value) => {
                                  if (value === null) {
                                    handleInput1("", "subject");
                                    return;
                                  }
                                  handleInput1(value.subject, "subject");
                                }}
                                style={{ width: "100%", height: "38px" }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                            )}
                            {mode === "Edit" && (
                              <Autocomplete1
                                disablePortal
                                id="combo-box-demo"
                                options={subjectOptions}
                                getOptionLabel={(option) => option.subject}
                                onChange={(event, value) => {
                                  if (value === null) {
                                    handleInput1("", "subject");
                                    return;
                                  }
                                  handleInput1(value.subject, "subject");
                                }}
                                disableClearable={true}
                                defaultValue={{
                                  subject: data.subject ? data.subject : "",
                                }}
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  overflow: "hidden",
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                            )}
                            <ErrorMessage
                              component={TextError}
                              name="subject"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group ">
                            <label
                              className="label-heading"
                              htmlFor="curriculum"
                            >
                              topic
                            </label>

                            {mode === "Add" && (
                              <Autocomplete1
                                disablePortal
                                id="combo-box-demo"
                                options={topicOptions}
                                getOptionLabel={(option) => option.topic}
                                onChange={(event, value) => {
                                  if (value === null) {
                                    handleInput1("", "topic");
                                    return;
                                  }
                                  handleInput1(value.topic, "topic");
                                }}
                                style={{ width: "100%", height: "38px" }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                            )}
                            {mode === "Edit" && (
                              <Autocomplete1
                                disablePortal
                                id="combo-box-demo"
                                options={topicOptions}
                                getOptionLabel={(option) => option.topic}
                                onChange={(event, value) => {
                                  if (value === null) {
                                    handleInput1("", "topic");
                                    return;
                                  }
                                  handleInput1(value.topic, "topic");
                                }}
                                disableClearable={true}
                                defaultValue={{
                                  topic: data.topic ? data.topic : "",
                                }}
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  overflow: "hidden",
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                            )}
                            <ErrorMessage component={TextError} name="topic" />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group ">
                            <label
                              className="label-heading"
                              htmlFor="curriculum"
                            >
                              chapter
                            </label>

                            {mode === "Add" && (
                              <Autocomplete1
                                disablePortal
                                id="combo-box-demo"
                                options={chapterOptions}
                                getOptionLabel={(option) => option.chapter}
                                onChange={(event, value) => {
                                  if (value === null) {
                                    handleInput1("", "chapter");
                                    return;
                                  }

                                  handleInput1(value.chapter, "chapter");
                                }}
                                style={{ width: "100%", height: "38px" }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                            )}
                            {mode === "Edit" && (
                              <Autocomplete1
                                disablePortal
                                id="combo-box-demo"
                                options={chapterOptions}
                                getOptionLabel={(option) => option.chapter}
                                onChange={(event, value) => {
                                  if (value === null) {
                                    handleInput1("", "chapter");
                                    return;
                                  }
                                  handleInput1(value.topic, "chapter");
                                }}
                                className={classes.auto}
                                disableClearable={true}
                                defaultValue={{
                                  chapter: data.chapter ? data.chapter : "",
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                            )}
                            <ErrorMessage
                              component={TextError}
                              name="chapter"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <FormikControl
                            control="select"
                            label="Question Type"
                            name="questionType"
                            options={[
                              { _id: "1", name: "Text" },
                              { _id: "2", name: "Multi" },
                            ]}
                            value={data.questionType}
                            onChange={(e) => {
                              handleChange(e);
                              handleInput(e);
                            }}
                          />
                        </div>

                        <div className="col-md-6">
                          <FormikControl
                            control="input"
                            type="text"
                            name="questionmarks"
                            label="Marks for Question"
                            onChange={(e) => {
                              handleChange(e);
                              handleInput(e);
                            }}
                          />
                        </div>

                        <div className="form-group col-md-12">
                          <label>Question</label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={data.question ? data.question : ""}
                            onChange={(event, editor) => {
                              handleQuestionName(editor.getData());
                            }}
                          />
                          {questionError && (
                            <div className="error">Required</div>
                          )}
                        </div>

                        {data.questionType === "Multi" && (
                          <React.Fragment>
                            <div className="col-md-6">
                              <FormikControl
                                control="input"
                                type="text"
                                label="Correct Answer"
                                name="correctanswer"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInput(e);
                                }}
                              />
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  handleFileChange(e);
                                  setAns1(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                }}
                              ></input>
                              <br />
                              <br />
                              <img
                                src={ans1}
                                alt="Placeholder"
                                className="w-100"
                              />
                              <br />
                              <br />
                            </div>

                            <div className="col-md-6">
                              <FormikControl
                                control="input"
                                type="text"
                                name="answer1"
                                label="Wrong Answer 1"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInput(e);
                                }}
                              />
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  handleFileChange(e);
                                  setAns2(
                                    URL.createObjectURL(e.target.files[0]),
                                    console.log(URL.createObjectURL(e.target.files[0]))
                                  );
                                }}
                              ></input>
                              <br />
                              <br />
                              <img
                                src={ans2}
                                alt="Placeholder"
                                className="w-100"
                              />
                              <br />
                              <br />
                            </div>

                            <div className="col-md-6">
                              <FormikControl
                                control="input"
                                type="text"
                                name="answer2"
                                label="Wrong Answer 2"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInput(e);
                                }}
                              />
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  handleFileChange(e);
                                  setAns3(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                }}
                              ></input>
                              <br />
                              <br />
                              <img
                                src={ans3}
                                alt="Placeholder"
                                className="w-100"
                              />
                              <br />
                              <br />
                            </div>

                            <div className="col-md-6">
                              <FormikControl
                                control="input"
                                type="text"
                                name="answer3"
                                label="Wrong Answer 3"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInput(e);
                                }}
                              />
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  handleFileChange(e);
                                  setAns4(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                }}
                              ></input>
                              <br />
                              <br />
                              <img
                                src={ans4}
                                alt="Placeholder"
                                className="w-100"
                              />
                              <br />
                              <br />
                            </div>
                          </React.Fragment>
                        )}

                        <div className="text-center col-md-12">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary mt-4"
                            onClick={() => handleSubmit(formik)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddQuestion;
