import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/Images/log.png";
import { useHistory } from "react-router-dom";
import FlatList from 'flatlist-react';
import { Button, Modal } from "react-bootstrap";
import Autocomplete1 from '@material-ui/lab/Autocomplete';

import styles from "./Topbar.module.css";
import { getCurriculumList } from "../../../services/Admin/commonService";
import TextField from '@material-ui/core/TextField';
import { getUpdateCurriculum } from "../../../services/Admin/curriculumService";
import { userCurriculum, userCurriculumCode, userID } from "../../../services/Authentication/AuthService";

const Topbar = () => {
  let history = useHistory();
  function Logout() {
    sessionStorage.clear();
    history.push("/login");
  }
  let notification = ['No Notification avaliable']
  const [show, setShow] = useState(false);
  const [cur, setCurList] = useState(false);
  const [selected, setSelected] = useState({});
  const [error, setError] = useState();
  const listCurricluim = () => {
    getCurriculumList().then(res => {
      if (res.status === 200) {
        setCurList(res.data.data)
      }
    }).catch(err => {
      setCurList([])
    })
  }
  useEffect(() => {
    setSelected({ curriculum: userCurriculum(), curriculumCode: userCurriculumCode() })
    listCurricluim()
  }, [])
  const updateCur = () => {
    let payload = {
      userID: userID(),
      curriculum: selected.curriculum,
      curriculumCode: selected.curriculumCode,
    }

    getUpdateCurriculum(payload).then(res => {
      if (res.status === 200) {
        sessionStorage.token = res.data.token
        sessionStorage.loginType = 'student'
        handleClose()
        window.location.reload()
      }
    }).catch(err => {
      setError('Something went wrong')
    })
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <header>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Curriculum</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Autocomplete1
            id="combo-box-demo"
            // className="form-control"
            options={cur}
            getOptionLabel={(option) => option.curriculum}
            onChange={(event, value) => {
              if (value === null) {
                setSelected('')
                return
              }
              setSelected({ curriculum: value.curriculum, curriculumCode: value.curriculumCode })
            }}
            disableClearable={true}
            defaultValue={{ curriculum: selected.curriculum ? selected.curriculum : '' }}
            style={{ width: '100%', height: '38px' }}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
          {error && <p>{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateCur}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <nav
        className="navbar navbar-expand-lg navbar-light "
      >
        <ul className="navbar-nav collapse navbar-collapse" id="navbarCollapse">
          <div className={styles.logo}>
            <Link to="/student/dashboard">
              <a class="navbar-brand" href="#">
                <img src={Logo} width="187.5px" height="75px" alt=""></img>
              </a>
            </Link>

          </div>
          <div className={`navbar-nav ml-auto ${styles.navbarNav}`}>

            <li className="nav-item">
              <Link
                to="/student/dashboard"
                exact
                activeClassName="active"
                className="nav-link text-dark "
              >
                Home
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/student/curriculum"
                exact
                activeClassName="active"
                className="nav-link text-dark "
              >
                {/* <img
              src={logo2}
              style={{ height: "23px", width: "23px", marginRight: "5px" }}
              alt="imgh"
            /> */}
                {/* <i className={`las la-graduation-cap ${styles.sideIcon}`}></i> */}
                Curriculum
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/student/assignments"
                exact
                activeClassName="active"
                className="nav-link text-dark "
              >
                {/* <img
              src={logo2}
              style={{ height: "23px", width: "23px", marginRight: "5px" }}
              alt="imgh"
            /> */}
                {/* <i className={`las la-graduation-cap ${styles.sideIcon}`}></i> */}
                Assignments
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/student/my-queries/open"
                exact
                activeClassName="active"
                className="nav-link text-dark "
              >
                {/* <img
              src={logo2}
              style={{ height: "23px", width: "23px", marginRight: "5px" }}
              alt="imgh"
            /> */}
                {/* <i className={`las la-graduation-cap ${styles.sideIcon}`}></i> */}
                My Queries
              </Link>
            </li>
            <br></br>
            <li className="nav-item">
              <Link
                to="/student/help-from-tutor/open"
                exact
                activeClassName="active"
                className="nav-link"
              >
                {/* <img
              src={logo2}
              style={{ height: "23px", width: "23px", marginRight: "5px" }}
              alt="imgh"
            /> */}
                {/* <i className={`las la-graduation-cap ${styles.sideIcon}`}></i> */}
                Help-From-Tutor
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/student/StudentTraining"
                exact
                activeClassName="active"
                className="nav-link"
              >
                {/* <img
              src={logo2}
              style={{ height: "23px", width: "23px", marginRight: "5px" }}
              alt="imgh"
            /> */}
                {/* <i className={`las la-graduation-cap ${styles.sideIcon}`}></i> */}
                Student Training
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/student/sessions"
                exact
                activeClassName="active"
                className="nav-link"
              >
                {/* <img
              src={logo2}
              style={{ height: "23px", width: "23px", marginRight: "5px" }}
              alt="imgh"
            /> */}
                {/* <i className={`las la-graduation-cap ${styles.sideIcon}`}></i> */}
                Sessions
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false" >
                <i className="las la-bell"></i>
              </Link>
              <div className={`dropdown-menu ${styles.dropdownMenu}`}
                aria-labelledby="navbarDropdown">
                <div className="flat">
                  {/* {notification.map(data => {
                    return (
                      <div className="dropdown-item">
                        <p>{data}</p>
                      </div>
                    )
                  })} */}
                  <FlatList
                    list={notification}
                    className="flat"
                    renderWhenEmpty={() => (<p>No notification</p>)}
                    display={{
                      grid: false
                    }}
                    renderItem={(data, idx) => {

                      return (
                        <Fragment >

                          <div key={idx} className="dropdown-item">

                            <p>{data} </p>
                          </div>
                          {idx < notification.length - 1 && <div className="dropdown-divider"></div>}

                        </Fragment>

                      )
                    }}
                  />
                </div>

                {/* 
                <div className="dropdown-item">
                  <p>demo</p>
                </div> */}

              </div>
            </li>
            <li className={`nav-item dropdown`}>
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="las la-user-circle"></i>
              </Link>

              <div
                className={`dropdown-menu ${styles.dropdownMenu}`}
                aria-labelledby="navbarDropdown"
              >
                <Link className="dropdown-item" onClick={handleShow}>
                  Change Curriculum
                </Link>
                <div className="dropdown-divider"></div>

                <Link className="dropdown-item" to="/student/profile">
                  Profile
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="#" onClick={Logout}>
                  Logout
                </Link>
              </div>
            </li>
          </div>
        </ul>
      </nav>
    </header>
  );
};

export default Topbar;
