import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";
import { remove } from "lodash";

const headers = {
  "x-access-token": getAuthToken(),
};

export const getAllCourse = () => {
  return http.get(apiUrl + "/getAllCourse", {
    headers: headers,
  });
};

export function saveCourse(payload, courseId) {
  if (!courseId) {
    //Add Course
    return http.post(apiUrl + "/createCourse", payload, {
      headers: headers,
    });
  } else {
    //Edit Course
    delete payload._id;
    const editpayload = {
      condition: { _id: courseId },
      updateData: payload,
    };
    
    return http.post(apiUrl + "/updateCourse", editpayload, {
      headers: headers,
    });
  }
}

export const getSubjectsForCourse = (payload) => {
  return http.post(apiUrl + "/getSubjectsForCourse", payload, {
    headers: headers,
  });
};

export const getCourse = (courseId) => {
  return http.post(apiUrl + "/getCourse", courseId, {
    headers: headers,
  });
};

export const getSubjectinfo = (payload) => {
  return http.post(apiUrl + "/getSubjectinfo", payload, {
    headers: headers,
  });
};

export const copyCourse = (id) => {
  return http.post(apiUrl + "/copyChapter/"+ id, {
    headers: headers,
  });
};
export const deleteCourse = (id) => {
  return http.post(apiUrl + "/deleteCourse/"+id, {
    headers: headers,
  });
};
