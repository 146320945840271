import React, { useState } from "react";
import parse from "html-react-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Modal, Button } from "react-bootstrap";
import styles from "./MyQueries.module.css";
import DisplayErrorMessage from "../../Share/DisplayErrorMessage";
import { userID } from "../../../services/Authentication/AuthService";

const InprogressQueries = (props) => {
  const { 
    isReplay,
    modalShow,
    inprogressQueries,
    replayMessage,
    replayQuery,
    cancelQuery,
    handleResolveQuery,
    handleReplayQuery,
    handleInputReplay,
    handleClose,
    handleShow,
    selectedQuery,
  } = props;

  const [queryID, setQueryID] = useState(null);

  const resolveQuery = (queryID) => {
    setQueryID(queryID);
  };

  if (inprogressQueries && inprogressQueries.length === 0) {
    return (
      <DisplayErrorMessage
        message="No inprogress queries found"
        errorSize="big"
      />
    );
  }

  return (
    <React.Fragment>
      {inprogressQueries &&
        inprogressQueries.length &&
        inprogressQueries.map((q, index) => {
          if (q.status === 1) {
            return (
              <div className={`cardDiv ${styles.queryDiv}`} key={q._id}>
                <h5 title="Curriculum" className={styles.curriculum}>
                  {q.curriculum} {"  -"}
                </h5>
                <div title="Subjet" className={styles.subject}>
                  {q.subject} {"-"}
                </div>
                <div title="Topic" className={styles.topic}>
                  {q.topic} {"-"}
                </div>
                <div title="Chapter" className={styles.chapter}>
                  {q.chapter}
                </div>

                <hr className={styles.hr} />
                <div className={styles.messagesDiv}>
                  {q.messages &&
                    q.messages.map((m) => {
                      return (
                        <React.Fragment>
                          {m.author_id === userID() ? (
                            <div className="col-md-12">
                              <p
                                key={m.createdAt}
                                className={styles.studentReview}
                              >
                                {parse(m.message)}
                              </p>
                            </div>
                          ) : (
                            <div className="col-md-12 text-right">
                              <p
                                key={m.createdAt}
                                className={styles.teacherReview}
                              >
                                {parse(m.message)}
                              </p>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                </div>
                <div className={styles.queryFeatures}>
                  <span
                    className={styles.replay}
                    onClick={() => {
                      replayQuery(index);
                    }}
                  >
                    <i className="las la-reply"></i> Replay
                  </span>
                  <span
                    className={styles.resolve}
                    onClick={() => {
                      handleShow();
                      resolveQuery(q.chatID);
                    }}
                  >
                    <i className="las la-check"></i> Resolve
                  </span>
                </div>

                {isReplay && (
                  <div
                    className="row mt-3"
                    key={`item-${index}`}
                    className={`${
                      selectedQuery === index ? styles.open : styles.close
                    }`}
                  >
                    <div className="col-md-12 pad-0 mt-2">
                      <CKEditor
                        editor={ClassicEditor}
                        data={replayMessage}
                        onChange={(event, editor) => {
                          handleInputReplay(editor.getData());
                        }}
                      />
                    </div>
                    <div className="col-md-12 pad-0 text-right">
                      <button
                        className="btn btn-danger mt-3 mr-2 mr-0"
                        onClick={cancelQuery}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary mt-3 mr-0"
                        onClick={() => handleReplayQuery(q)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          }
        })}

      <Modal show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Resolve Query</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want close the query?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={() => handleResolveQuery(queryID)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default InprogressQueries;
