import React, { Component } from "react";
import LoadingModal from "../../components/Share/LoadingModal";
import AddCurriculum from "../../components/admin/curriculum/addCurriculum";
import ViewCurriculum from "../../components/admin/curriculum/viewCurriculum";
import Curriculum from "../../components/admin/curriculum/curriculum";
import { userID } from "../../services/Authentication/AuthService";
import {
  getAllCurriculum,
  saveCurriculum,
  getCurriculum,
  deleteCurriculum
} from "../../services/Admin/curriculumService";
import { event } from "jquery";
import { indexOf } from "lodash";
import { fileUpload } from "../../services/Admin/videoService";

class CurriculumContainer extends Component {
  state = {
    mode: "",
    search: '',
    curriculumListAll: [],
    curriculumList: [],
    curriculumImg: "",
    curriculumListIndividual: [],
    displayImage: '',
    addCurriculumData: {
      organizationName: "",
      organizationCode: "",
      curriculumAward: "",
      shortName: "",
      curriculum: "",
      longName: "",
      marksMode: "",
      curriculumCode: "",
      liveSession: "",
      showPreviousCurriculum: "",
      createDate: new Date(),
      duration: "",
      effectiveDate: "",
      endDate: "",
      startDate: "",
      eligibility: "",
      passCriteria: "",
      experience: '',
      price:"",
     
    },
    isLoading: false,
  };

  handleInput = ({ currentTarget: input }) => {

    const addCurriculumData = this.state.addCurriculumData;
    if (input.value === "Yes") {
      addCurriculumData[input.name] = true;
    } else if (input.value === "No") {
      addCurriculumData[input.name] = false;
    } else {
      addCurriculumData[input.name] = input.value;
    }
    if (input.name === 'organizationImage') {

    }
    this.setState({ addCurriculumData });
  };
  handleInputImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      console.log('even', event.target.files)
      this.setState({ curriculumImg: event.target.files[0] })
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ displayImage: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  handleCkeditor = (data, name) => {
    const addCurriculumData = this.state.addCurriculumData;
    addCurriculumData[name] = data;
    this.setState({ addCurriculumData });
  };

  handleDates = (date, name) => {
    const addCurriculumData = this.state.addCurriculumData;
    addCurriculumData[name] = date;
    this.setState({ addCurriculumData });
  };

  generateCurriculamName = () => {
    const addCurriculumData = this.state.addCurriculumData;
    if (addCurriculumData.shortName !== "") {
      addCurriculumData.curriculum =
        addCurriculumData.organizationCode +
        "-" +
        addCurriculumData.curriculumAward +
        "-" +
        addCurriculumData.shortName;
    }
  };

  getAllCurriculumList = async () => {
    getAllCurriculum()
      .then((res) => {
        this.setState({ curriculumList: res.data.data, curriculumListAll: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleAddCurriculum = async () => {

    let payload = this.state.addCurriculumData;
    payload.createDate = new Date();
    payload.createdBy = userID();
    let curriculumId = this.props.match.params.id;
    let data = new FormData()
    if (this.state.curriculumImg) {
      data.append("upfile", this.state.curriculumImg, this.state.curriculumImg.name)
    }
    let headers = {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    };
    if (this.state.curriculumImg){
      fileUpload(data,headers).then(res=>{
        console.log('file-', res.data.location, res.data.fieldname.originalname);
        payload.location = res.data.location
        payload.fieldname = res.data.fieldname.originalname
        saveCurriculum(payload, curriculumId)
          .then((res) => {
            this.props.history.push("/admin/curriculum");
          })
          .catch((err) => {
            console.log("err", err);
          });

      }).catch(err=>{
        console.log('err-',err);
      })
    }else{
      this.setState({ isLoading: true });
      saveCurriculum(payload, curriculumId)
        .then((res) => {
          this.props.history.push("/admin/curriculum");
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    
   
  };

  getIndividualCurriculumList = async () => {
    const curriculumId = this.props.match.params.id;
    const payload = {
      _id: curriculumId,
    };
    try {
      const response = await getCurriculum(payload);
      this.setState({
        curriculumListIndividual: response.data.data[0],
      });
    } catch (ex) {
      // if (ex.response && ex.response.status === 404) {
      //   this.props.history.replace("/not-found");
      // }
    }
  };

  editCurriculum = async () => {
    const curriculumId = this.props.match.params.id;
    const payload = {
      _id: curriculumId,
    };
    try {
      const response = await getCurriculum(payload);
      this.setState({
        addCurriculumData: response.data.data[0],
        displayImage: response.data.data[0].location
      });
    } catch (ex) {
      // if (ex.response && ex.response.status === 404) {
      //   this.props.history.replace("/not-found");
      // }
    }
  };
  deleteEntireCurriculum = async () => {
    const codeCurriculum = this.state.curriculumListIndividual.curriculumCode;
    const curriculum = this.state.curriculumListIndividual.curriculum;
    const payload = {
      curriculumCode: codeCurriculum,
      curriculum: curriculum
    };
    try {
      await deleteCurriculum(payload);
      this.props.history.push("/admin/curriculum");
    } catch (ex) {
      // if (ex.response && ex.response.status === 404) {
      //   this.props.history.replace("/not-found");
      // }
    }
  };
  handleUpdateCurriculum = async () => {
    const curriculumId = this.props.match.params.id;
    let payload = this.state.addCurriculumData;
    try {
      await saveCurriculum(payload, curriculumId);
      this.props.history.push("/admin/curriculum");
    } catch (ex) {
      // if (ex.response && ex.response.status === 404) {
      //   this.props.history.replace("/not-found");
      // }
    }
  };
  searchCurr = (event) => {
    this.setState({ search: event.target.value })
    let val = String(event.target.value).toLowerCase()
    if (!val) {
      this.getAllCurriculumList()
      return
    }
    let filter = this.state.curriculumListAll.filter(q => {

      if (q.curriculum.toLowerCase().indexOf(val) !== -1) {

        return q
      }
    })
    // console.log('filter-' + JSON.stringify(filter))
    this.setState({ curriculumList: [...filter] })

  }
  closeViewCurriculum = () => {
    this.props.history.push("/admin/curriculum");
  };

  loadDefaultValues = () => {
    const pathName = this.props.match.path;
    if (pathName === "/admin/add-curriculum") {
      this.setState({ mode: "Add" });
    } else if (pathName === "/admin/edit-curriculum/:id") {
      this.setState({ mode: "Edit" });
      this.editCurriculum();
    } else if (pathName === "/admin/view-curriculum/:id") {
      this.setState({ mode: "View" });
      this.getIndividualCurriculumList();
    } else if (pathName === "/admin/curriculum") {
      this.setState({ mode: "List" });
      this.getAllCurriculumList();
    }
  };

  componentDidMount() {
    this.loadDefaultValues();
  }


  render() {
    let path = this.props.match.path;
    return (
      <React.Fragment>
        {path === "/admin/curriculum" && (
          <Curriculum curriculumList={this.state.curriculumList}
            searchCurr={this.searchCurr}
            search={this.state.search}
          />
        )}

        {(path === "/admin/add-curriculum" ||
          path === "/admin/edit-curriculum/:id") && (
            <AddCurriculum
              mode={this.state.mode}
              handleAddCurriculum={this.handleAddCurriculum}
              curriculumListIndividual={this.state.curriculumListIndividual}
              handleCkeditor={this.handleCkeditor}
              addCurriculumData={this.state.addCurriculumData}
              handleInput={this.handleInput}
              generateCurriculamName={this.generateCurriculamName}
              handleDates={this.handleDates}
              handleInputImage={this.handleInputImage}
              displayImage={this.state.displayImage}
            />
          )}

        {path === "/admin/view-curriculum/:id" && (
          <ViewCurriculum
            curriculumListIndividual={this.state.curriculumListIndividual}
            closeViewCurriculum={this.closeViewCurriculum}
            deleteEntireCurriculum={this.deleteEntireCurriculum}
          />
        )}
        <LoadingModal visible={this.state.isLoading} />
      </React.Fragment>
    );
  }
}

export default CurriculumContainer;
