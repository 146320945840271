import React, { useState, useEffect, Fragment } from "react";
import TestLinkComponent from "../../components/admin/meeting/testLinkComponent";
import { getAdminMeet } from "../../services/Admin/commonService";


const TestLinkContainer = () => {
    const [meet, setMeet] = useState(false)

    useEffect(() => {

        getAllMetting()
    }, [])

    const getAllMetting = () => {
        getAdminMeet().then(res => {
            if (res.data.status && res.status === 200) {
                setMeet(res.data.data)
            }
        }).catch(err => { })
    }

    return <Fragment>
        <TestLinkComponent meet={meet} />
    </Fragment>
}
export default TestLinkContainer