import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import quer from "../../../assets/Banners/Banner3.png";
import styles from "./MyQueries.module.css";

const QueryType = (props) => {
  const { queryType, raiseQuery } = props;
  const path = useHistory().location.pathname;
  return (
    <Fragment>
      <div className="row">
        {/* <div className={`col-md-12 ${styles.dashimg} mb-2 `}>
          <img src={quer} style={{ width: "100%", height: "100%" }} />
        </div> */}
      </div>
      <div className="col-md-12 row">
        <div
          className={`tabItem ${
            path === "/student/my-queries/open" ? "tabItemActive" : ""
          }`}
          onClick={() => queryType("open")}
        >
          Open
        </div>
        <div
          className={`tabItem ${
            path === "/student/my-queries/inprogress" ? "tabItemActive" : ""
          }`}
          onClick={() => queryType("inprogress")}
        >
          Inprogress
        </div>
        <div
          className={`tabItem ${
            path === "/student/my-queries/completed" ? "tabItemActive" : ""
          }`}
          onClick={() => queryType("completed")}
        >
          Completed
        </div>

        <button className="btn btn-right " onClick={raiseQuery}>
          Raise Query
        </button>
      </div>
    </Fragment>
  );
};

export default QueryType;
