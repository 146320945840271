import React from "react";
import { Link } from "react-router-dom";
import Select from "../../../common/select";

import {
  curriculumListFun,
  subjectListFun,
  topicListFun,
} from "../../../utils/utils";
import cur from "../../../assets/Banners/Banner2.png";
import styles from "./Curriculum.module.css";
import { Modal, Dropdown, Button } from "react-bootstrap";

const Curriculum = ({
  curriculumList,
  subjectsList,
  topicsList,
  chaptersList,
  handleInput,
  curriculumData,
  handleClose,
  handleShow,
  modalShow,
  chapterRaiseQuery,
  chapterRequestSession,
}) => {
  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);
  let topicOptions = topicListFun(topicsList);
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-4">
          <Select
            name="curriculum"
            optname="curriculum"
            label="Curriulum Name"
            placeholder="Select Curriculum"
            value={curriculumData.curriculum ? curriculumData.curriculum : ""}
            options={curriculumOptions}
            onChange={(e) => handleInput(e, "curriculum")}
          />
        </div>

        <div className="col-md-4">
          <Select
            name="subject"
            optname="subject"
            label="Subject Name"
            placeholder="Select Subject"
            value={curriculumData.subject ? curriculumData.subject : ""}
            options={subjectOptions}
            onChange={(e) => handleInput(e, "subject")}
          />
        </div>

        <div className="col-md-4">
          <Select
            name="topic"
            optname="topic"
            label="Topic Name"
            placeholder="Select Topic"
            value={curriculumData.topic ? curriculumData.topic : ""}
            options={topicOptions}
            onChange={(e) => handleInput(e, "topic")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className={styles.chaptersDiv}>
            {/* <h4 className="text-heading ml-0 mt-2 mb-3">Chapters List</h4> */}
            <table className="table table-bordered">
              <thead>
                <tr>
                  {/* <th style={{ width: "15%" }}>Chapter Name</th>
                  <th style={{ width: "15%" }}>Video Link</th>
                  <th style={{ width: "15%" }}>Raise</th>

                  <th style={{ width: "15%" }}>Reading Material</th>
                  <th style={{ width: "15%" }}>Exercise Material</th> */}

                  {/* <th>History</th> */}
                </tr>
              </thead>
              <tbody>
                {chaptersList.length > 0 &&
                  chaptersList.map((c, i) => {
                    console.log("cccc", c);
                    return (
                      <tr key={i + 1}>
                        <td>{c.chapter}</td>
                        <td>
                          <Link to={"#"} onClick={() => handleShow(c)}>
                            Link
                          </Link>
                        </td>
                        <td>
                          <button
                            onClick={() => chapterRaiseQuery(c.chapter)}
                            className="btn btn-primary btn-sm mr-2 theam-color"
                          >
                            Raise Query
                          </button>
                          <button
                            onClick={() => chapterRequestSession(c.chapter)}
                            className="btn btn-primary btn-sm mr-2 theam-color"
                          >
                            Request Session
                          </button>
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-button-dark-example1"
                              variant="secondary"
                            >
                              Reading Material
                            </Dropdown.Toggle>

                            <Dropdown.Menu variant="dark">
                              {c.norArr &&
                                c.norArr.length > 0 &&
                                c.readArr.map((data) => {
                                  console.log("999999999999999999999999999");
                                  // return <a href={data.location} target="_blank">{data.name}</a>
                                  return (
                                    <React.Fragment>
                                      <Dropdown.Item
                                        href={data.location}
                                        target="_blank"
                                      >
                                        {data.name}
                                      </Dropdown.Item>
                                      <Dropdown.Divider />
                                    </React.Fragment>
                                  );
                                })}
                              {c.norArr && c.norArr.length <= 0 && (
                                <React.Fragment>
                                  <Dropdown.Item>
                                    No Reading Material
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                </React.Fragment>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>

                          {/* <a href={} download="reading material">Read here</a> */}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-button-dark-example1"
                              variant="secondary"
                            >
                              Exercise Material
                            </Dropdown.Toggle>

                            <Dropdown.Menu variant="dark">
                              {c.exArr &&
                                c.exArr.length > 0 &&
                                c.exArr.map((data) => {
                                  // return <a href={data.location} target="_blank">{data.name}</a>
                                  return (
                                    <React.Fragment>
                                      <Dropdown.Item
                                        href={data.location}
                                        target="_blank"
                                      >
                                        {data.name}
                                      </Dropdown.Item>
                                      <Dropdown.Divider />
                                    </React.Fragment>
                                  );
                                })}
                              {c.exArr && c.exArr.length <= 0 && (
                                <React.Fragment>
                                  <Dropdown.Item>
                                    No Exercise Material
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                </React.Fragment>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>

                          {/* <a href={} download="reading material">Read here</a> */}
                        </td>

                        {/* <td>
                          <div className={styles.progress}>
                            <div
                              className={`progress-bar ${styles.progressBar}`}
                              style={{ width: "30%" }}
                            ></div>
                          </div>
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className={styles.box}>
              <div className="col-md-12">
                <span>Chapter Name:</span>
                <div className={styles.txt}>
                  <p></p>
                </div>
              </div>
              <div className="col-md-12">
                <span>VIDEO LINK:</span>
                <div className={styles.txt}>
                  <p></p>
                </div>
              </div>

              <div className="col-md-12">
                <span>RAISE:</span>
                <div className={styles.txt}>
                  <p></p>     
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Curriculum;
