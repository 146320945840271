import React, { Component } from "react";
import Curriculum from "../../components/Students/Curriculum/Curriculum";
import Video from "../../components/Students/Curriculum/video";
import {getUserCurriculum} from "../../services/Student/CurriculumStudentService"
import { getCurriculumCodeFun } from "../../utils/utils";
import {
  userID,

} from "../../services/Authentication/AuthService";
import {
  getAllSubjectList,
  getAllTopicsList,
  getAllChaptersList,
} from "../../components/Share/GetCurriculumDetails";

import {
  userCurriculumCode,
  userCurriculum,
  userCurriculumsList,
} from "../../services/Authentication/AuthService";

class CurriculumContainer extends Component {
  state = {
    curriculumList: [],
    subjectsList: [],
    topicsList: [],
    chaptersList: [],
    chapterSingle: [],
    modalShow: false,
    curriculumData: {
      curriculum: "",
      curriculumCode: "",
      subject: "",
      topic: "",
    },
  };
  getUserCurriculums=()=>{
    getUserCurriculum(userID()).then((res)=>{
      this.setState({ curriculumList: res.data.data });

   
     })
  }

  handleInput = async ({ currentTarget: input }, name) => {
    const data = { ...this.state.curriculumData };
    const { options, selectedIndex } = input;
    let optValue = options[selectedIndex].innerHTML;
    data[input.name] = optValue;
    
    if (name === "curriculum") {
      data.subject = "";
      data.topic = "";
      data.chapter = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      data["curriculumCode"] = curriculumCodeValue;
      let subjectsList = await getAllSubjectList(curriculumCodeValue);
      this.setState({ subjectsList });
    }

    if (name === "subject") {
      data.topic = "";
      data.chapter = "";
      let topicsList = await getAllTopicsList(data.curriculumCode, input.value);
      this.setState({ topicsList });
    }

    if (name === "topic") {
      let chaptersList = await getAllChaptersList(
        data.curriculumCode,
        data.subject,
        input.value
      );
      let chap = chaptersList.map(el => {
        let exArr = []
        let norArr = []
        if (el.readArr) {
          el.readArr.forEach(el1 => {

            if (el1.name) {

              let ind = el1.name.indexOf('exercise')
              let ind1 = el1.name.indexOf('Exercise')
              if (ind !== -1 || ind1 !== -1) {
                //console.log('name',el1.name)
                exArr.push(el1)
              } else {
                norArr.push(el1)
              }
            }
            //console.log('ell',el1)
          });
        }
        el['exArr'] = exArr
        el['norArr'] = norArr
        console.log('elll', el);
        return el
      })
      this.setState({ chaptersList: chap });
    }
     console.log( this.state.chaptersList,"<-data");
    this.setState({ curriculumData: data });
  };

  handleClose = () => {
    this.setState({ modalShow: false });
  };

  handleShow = (chapter) => {
    this.setState({ modalShow: true, chapterSingle: chapter });
  };

  chapterRaiseQuery = (chapter) => {
    const curriculumData = this.state.curriculumData;
    curriculumData.chapter = chapter;
    sessionStorage.setItem(
      "curriculumDataQuery",
      JSON.stringify(this.state.curriculumData)
    );
    this.props.history.push("/student/my-queries/raise-query");
  };

  chapterRequestSession = (chapter) => {
    const curriculumData = this.state.curriculumData;
    curriculumData.chapter = chapter;
    sessionStorage.setItem(
      "curriculumDataSession",
      JSON.stringify(this.state.curriculumData)
    );
    this.props.history.push("/student/help-from-tutor/request-session");
  };

  async componentDidMount() {
    this.getUserCurriculums()
    let curr = userCurriculumsList();
    const data = this.state.curriculumData;
    data.curriculumCode = userCurriculumCode();
    data.curriculum = userCurriculum();

    let subjectsList = await getAllSubjectList(data.curriculumCode);
    data.subject = subjectsList[0].subject;
    let topicsList = await getAllTopicsList(data.curriculumCode, data.subject);
    data.topic = topicsList[0].topic;
    let chaptersList = await getAllChaptersList(
      data.curriculumCode,
      data.subject,
      data.topic
    );
    let chap = chaptersList.map(el => {
      let exArr = []
      let norArr = []
      if (el.readArr) {
        el.readArr.forEach(el1 => {

          if (el1.name) {

            let ind = el1.name.indexOf('exercise')
            let ind1 = el1.name.indexOf('Exercise')
            if (ind !== -1 || ind1 !== -1) {
              //console.log('name',el1.name)
              exArr.push(el1)
            } else {
              norArr.push(el1)
            }
          }
          //console.log('ell',el1)
        });
      }
      el['exArr'] = exArr
      el['norArr'] = norArr
      console.log('elll', el);
      return el
    })
    console.log('chap', chap);
    this.setState({
      // curriculumList: curr,
      curriculumData: data,
      subjectsList,
      topicsList,
      chaptersList: chap,
    });
  }

  render() {
    const {
      curriculumList,
      subjectsList,
      topicsList,
      chaptersList,
      curriculumData,
      modalShow,
      chapterSingle,
    } = this.state;

    let path = this.props.match.path;

    return (
      <React.Fragment>
        {path === "/student/curriculum" && (
          <Curriculum
            curriculumList={curriculumList}
            subjectsList={subjectsList}
            topicsList={topicsList}
            chaptersList={chaptersList}
            curriculumData={curriculumData}
            modalShow={modalShow}
            handleInput={this.handleInput}
            handleClose={this.handleClose}
            handleShow={this.handleShow}
            chapterRaiseQuery={this.chapterRaiseQuery}
            chapterRequestSession={this.chapterRequestSession}
          />
        )}
        {modalShow && (
          <Video
            modalShow={modalShow}
            chapterSingle={chapterSingle}
            handleClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default CurriculumContainer;
