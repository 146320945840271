import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FlatList from 'flatlist-react';
import { getInactiveUser } from '../../../services/Admin/commonService'
import styles from "./header.module.css";
import { useSelector, useDispatch } from 'react-redux';

const Header = (props) => {
  const [user, setUser] = useState([]);
  const [session, setSession] = useState([]);

  let history = useHistory();
  // let lis = useSelector(state => {
  //   return state.headUser.userList
  // })

  function Logout() {
    sessionStorage.clear();
    history.push("/login");
  }
  useEffect(() => {
    if (props.apiSession) {
      let sessionMap = props.apiSession.filter(q => {
        if (q.student.length > 10) {
          return q
        }
      })
      setSession(sessionMap)

    }

  }, [props.apiSession])

  useEffect(() => {

    setUser(props.userList)


  }, [props.userList])

  let notification = ['demoq', 'demo', 'demo', 'demo']
  useEffect(() => {

  }, [user])
  // const getInactive=()=>{
  //   getInactiveUser().then(result=>{
  //     console.log(result)
  //   }).catch(err=>console.log('err header-'+err))
  // }
  const getUserInfo = (info) => {
    sessionStorage.setItem('inactiveUser', JSON.stringify(info))
  }
  return (
    <header>
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${styles.navbar}`}
      >
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className={`navbar-nav ml-auto ${styles.navbarNav}`}>

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false" >
                <i className="las la-bell"></i>
              </Link>
              <div className={`dropdown-menu ${styles.dropdownMenu}`}
                aria-labelledby="navbarDropdown">
                <div className="flat">
                  {/* {notification.map(data => {
                    return (
                      <div className="dropdown-item">
                        <p>{data}</p>
                      </div>
                    )
                  })} */}
                  <FlatList
                    list={session}
                    className="flat"
                    renderWhenEmpty={() => (
                      <div className="dropdown-item">

                        <p>No Session</p>
                      </div>)}
                    display={{
                      grid: false
                    }}
                    renderItem={(data, idx) => {
                      return (
                        <Fragment key={idx} >
                          <Link >
                            <div className="dropdown-item" >

                              <p>{data.title} </p>
                            </div>
                          </Link>
                          {/* {idx < notification.length - 1 && <div className="dropdown-divider"></div>} */}
                          <div className="dropdown-divider"></div>

                        </Fragment>

                      )
                    }}
                  />
                </div>

                {/* 
                <div className="dropdown-item">
                  <p>demo</p>
                </div> */}

              </div>
            </li>

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false" >
                <i className="las la-bell"></i>
              </Link>
              <div className={`dropdown-menu ${styles.dropdownMenu}`}
                aria-labelledby="navbarDropdown">
                <div className="flat">
                  {/* {notification.map(data => {
                    return (
                      <div className="dropdown-item">
                        <p>{data}</p>
                      </div>
                    )
                  })} */}
                  <FlatList
                    list={user}
                    className="flat"
                    renderWhenEmpty={() => (
                      <div className="dropdown-item">

                        <p>No Notification</p>
                      </div>)}
                    display={{
                      grid: false
                    }}
                    renderItem={(data, idx) => {
                      return (
                        <Fragment key={idx} >
                          <Link to={`/admin/inactiveUser/${data.userID}`} >
                            <div className="dropdown-item" onClick={() => getUserInfo(data)}>

                              <p>{data.firstName} {data.middleName} {data.lastName}</p>
                            </div>
                          </Link>
                          {idx < notification.length - 1 && <div className="dropdown-divider"></div>}

                        </Fragment>

                      )
                    }}
                  />
                </div>

                {/* 
                <div className="dropdown-item">
                  <p>demo</p>
                </div> */}

              </div>
            </li>

            <li className={`nav-item dropdown`}>
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="las la-user-circle"></i>
              </a>
              <div
                className={`dropdown-menu ${styles.dropdownMenu}`}
                aria-labelledby="navbarDropdown"
              >
                <Link className="dropdown-item" to="/admin/profile">
                  Profile
                </Link>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#" onClick={Logout}>
                  Logout
                </a>
              </div>
            </li>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
