import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./Assignments.module.css";
import Countdown from 'react-countdown';
import { Button, Modal } from "react-bootstrap";

const AssignmentPreview = ({ assignmentIndividual, getTime, show, closeModal, meetingLink, getMeetingLink }) => {



  const data = assignmentIndividual;
  useEffect(() => {
    timerr()

  }, [data])
  const height = window.innerHeight - 155;
  const [showMss, setShowMss] = useState(true)
  const [timer, setTimer] = useState(null)
  if (!data) {
    return false;
  }

  const Completionist = () => {
    setShowMss(false)
    return <span>Start the Test now!</span>
  };

  const timerr = () => {
    let event, now, remaning, h, m
    try {
      console.log('ccccccccc22', data.startTime);
      if (data.startdate) {


        h = data.startTime.split(':')[0]
        m = data.startTime.split(':')[1]
        console.log('ds', h);
        now = new Date().getTime()
        let ii = moment(data.startdate).format("MM/DD/YY")
        ii = ii + ' ' + h + ':' + m
        console.log('ddd', ii);
        let da = new Date(ii).getTime()
        remaning = da - now
        if (remaning > 0) {
          console.log('date', remaning)
          let datee = Date.now() + remaning
          setTimer(datee)
        } else {
          setShowMss(false)
        }

      }
    } catch (err) {
      console.log('error');
      // console.log(err)
    }

  }
  const getTestTime = () => {
    let now = getTime()
    let h, m, event

    if (data.startdate)
      event = new Date()
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    let da = new Date(event.toISOString())
    // let enGB = (da.toLocaleDateString('en-GB', options))
    console.log(da.getTime());
    // let time = moment(data.startdate).format("DD/MM/YYYY hh:mm")
    h = data.startdate.split('T')[1].split(':')[0]
    m = data.startdate.split('T')[1].split(':')[1]
    // console.log('hhm', data.startdate);
    if (h < now.hours && m < now.min) {
      return true

    } else {
      return false
    }
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className={`${styles.assignmentPreviewDiv}`}>
          <div className={` col-md-12 text-right`}>
            <Link to={`/student/assignments`}>
              <button
                className={`btn btn-sm btn-secondary theam-color mr-0 ${styles.testBack}`}
              >
                Back
              </button>
            </Link>
          </div>
          <div
            className="col-md-6 pad-0"
            style={{ border: "1px solid #e8e8e8" }}
          >
            <div style={{ height: height }}>
              <h2 className="mt-3">Test Details</h2>
              <table class="table">
                <tr>
                  <th>Test Name</th>
                  <td>{data.assessmentName ? data.assessmentName : ""}</td>
                </tr>
                <tr>
                  <th>Curriculum Name</th>
                  <td>{data.curriculum}</td>
                </tr>
                <tr>
                  <th>Subject Name</th>
                  <td>{data.subject ? data.subject : ''}</td>
                </tr>
                <tr>
                  <th>
                    Start Date &nbsp;<small>(DD/MM/YYYY)</small>
                  </th>
                  <td>{moment(data.startdate).format("DD/MM/YYYY")}</td>
                </tr>
                <tr>
                  <th>
                    End Date &nbsp;<small>(DD/MM/YYYY)</small>
                  </th>
                  <td>{moment(data.enddate).format("DD/MM/YYYY")}</td>
                </tr>
              </table>

            </div>
          </div>

          <div className="col-md-12 text-center">


            <Link
              onClick={() => {
                // window.open(`/#/student/test-view/${data.testID}`, "_blank")

                window.open(`/#/student/test-view/${data.testID}`, "_blank")



              }

              }
            >

              <button disabled={showMss} className={`btn btn-sm btn-success ${styles.testStart}`}>
                Start Test
              </button>
            </Link>

          </div>
        </div>
      </div>
      <Modal show={show} >
        <Modal.Header >
          <Modal.Title>Instructions</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <ol>
            <li>
              Make sure you have good internet connection
            </li>
            <li>
              Make sure to join Teams meet and share your screen
            </li>
            <li>
              Do not minimize the browser during test
            </li>
            <li>
              Time remaning-      {timer && <Countdown date={timer}>
                <Completionist />
              </Countdown>}
            </li>
          </ol>   
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={closeModal}>
            Go Back
          </Button>

          <Button variant="primary" disabled={showMss} onClick={getMeetingLink}>
            Join teams Meeting
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AssignmentPreview;
