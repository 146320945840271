import React, { useState, useEffect, Fragment } from "react";
import Profile from "../../components/Share/Profile"
import { getUserInfo, } from "../../services/Admin/commonService";
import { userID } from "../../services/Authentication/AuthService";
import {getUserCurriculum} from "../../services/Student/CurriculumStudentService"


const StudentProfileContainer = props => {
    const [userInfo, setUserInfo] = useState('')
    const [usercur, setUserCur] = useState('')
    const [userCurCode, setUserCurCode] = useState('')

    useEffect(() => {
        getUser()
        getcur()
    }, [])

    const getUser = () => {
        getUserInfo(+userID()).then(res => {
            if (res.status === 200) {
                if (res.data.status) {
                    setUserInfo(res.data.userInfo)
                }
            } else {
                setUserInfo('')
            }
        }).catch(err => { console.log('err') })
    }
    const getcur=()=>{
        getUserCurriculum(userID()).then(res=>{
             let curArr=[];
             let curCode=[];
             res.data.data.map((e)=>{
                curArr.push(e.curriculum);
                curCode.push(e.curriculumCode);
            })
         
            setUserCur(curArr.toString())
            setUserCurCode(curCode.toString())
        })
    };
    console.log(userInfo,"<-info");
    const proptoCertificate = () => {
        props.history.push('/student/certificate')
    }
    return <Fragment>
        {userInfo && <Profile role={userInfo.role}
            firstName={userInfo.firstName}
            middleName={userInfo.middleName}
            lastName={userInfo.lastName}
            mobile={userInfo.mobile}
            dob={userInfo.dob}
            email={userInfo.email}
            gender={userInfo.gender}
            curriculum={usercur}
            curriculumCode={userCurCode}
            address={userInfo.address}
            country={userInfo.country}
            state={userInfo.state}
            city={userInfo.city}
            proptoCertificate={proptoCertificate}
        />
        }
    </Fragment>
}

export default StudentProfileContainer;