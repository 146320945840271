import React, { Component } from "react";
import RaiseQuery from "../../components/Students/MyQueries/RaiseQuery";
import OpenQueries from "../../components/Students/MyQueries/OpenQueries";
import InprogressQueries from "../../components/Students/MyQueries/InprogressQueries";
import CompletedQueries from "../../components/Students/MyQueries/CompletedQueries";
import QueryType from "../../components/Students/MyQueries/QueryType";
import { getUserCurriculum } from "../../services/Student/CurriculumStudentService"

import { getCurriculumDetails } from "../../services/Student/CurriculumStudentService";
import {
  queryInsert,
  queryUpdate,
  getAllQueries,
  updateRating
} from "../../services/Student/QueryService";

import { getCurriculumCodeFun } from "../../utils/utils";

import {
  getAllSubjectList,
  getAllTopicsList,
  getAllChaptersList,
} from "../../components/Share/GetCurriculumDetails";

import {
  userID,
  userCurriculumCode,
  userCurriculumsList,
} from "../../services/Authentication/AuthService";

class MyQueriesContainer extends Component {
  state = {
    isQuery: false,
    isFeedback: false,
    isReplay: false,
    modalShow: false,
    curriculumList: [],
    subjectsList: [],
    topicsList: [],
    chaptersList: [],
    openQueries: [],
    inprogressQueries: [],
    completedQueries: [],
    queryData: {
      curriculumCode: "",
      curriculum: "",
      subject: "",
      topic: "",
      chapter: "",
      message: "",
    },
    replayMessage: "",
    feedbackMessage: "",
    selectedQuery: "",
    selectedFeedback: "",
    loading: false
  };

  raiseQuery = () => {
    this.props.history.push("/student/my-queries/raise-query");
  };

  backQuery = () => {
    this.props.history.push("/student/my-queries/open");
  };

  replayQuery = (index) => {
    this.setState({
      isReplay: true,
      isFeedback: false,
      selectedQuery: index,
    });
  };

  feedbackQuery = (index) => {
    this.setState({
      isReplay: false,
      isFeedback: true,
      selectedFeedback: index,
    });
  };

  ratingUpdate = (payload) => {
    // console.log("ratin" + newRating)
    this.setState({ loading: true })
    updateRating(payload).then(res => {
      if (res.status === 200) {
        this.setState({ loading: false })
        this.getAllQueriesList();
      }
      this.setState({ loading: false })
    }).catch(err => { console.log(err) })
  }

  cancelQuery = () => {
    this.setState({
      isReplay: false,
      isFeedback: false,
      replayMessage: "",
      feedbackMessage: "",
    });
  };

  handleClose = () => {
    this.setState({ modalShow: false });
  };

  handleShow = () => {
    this.setState({ modalShow: true });
  };

  queryType = (type) => {
    if (type === "open") {
      this.props.history.push("/student/my-queries/open");
    } else if (type === "inprogress") {
      this.props.history.push("/student/my-queries/inprogress");
    } else if (type === "completed") {
      this.props.history.push("/student/my-queries/completed");
    }
  };

  handleInput = async ({ currentTarget: input }, name) => {
    const data = { ...this.state.queryData };
    const { options, selectedIndex } = input;

    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;
      data[input.name] = optValue;
    } else {
      data[input.name] = input.value;
    }

    if (name === "curriculum") {
      data.subject = "";
      data.topic = "";
      data.chapter = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      data["curriculumCode"] = curriculumCodeValue;
      let subjectsList = await getAllSubjectList(curriculumCodeValue);
      this.setState({ subjectsList });
    }

    if (name === "subject") {
      data.topic = "";
      data.chapter = "";
      let topicsList = await getAllTopicsList(data.curriculumCode, input.value);
      this.setState({ topicsList });
    }

    if (name === "topic") {
      let chaptersList = await getAllChaptersList(
        data.curriculumCode,
        data.subject,
        input.value
      );
      this.setState({ chaptersList });
    }

    this.setState({ queryData: data });
  };

  handleInputMessage = (data1) => {
    const data = this.state.queryData;
    data.message = data1;
    this.setState({ queryData: data });
  };

  handleInputReplay = (data) => {
    this.setState({ replayMessage: data });
  };

  handleInputFeedback = (event) => {
    this.setState({ feedbackMessage: event.target.value });
  };

  getCurriculum = (payload, name) => {
    getCurriculumDetails(payload)
      .then((res) => {
        if (name === "curriculum") {
          this.setState({ subjectList: res.data.data });
        } else if (name === "subject") {
          this.setState({ topicList: res.data.data });
        } else if (name === "topic") {
          this.setState({ chapterList: res.data.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleSubmitQuery = async () => {
    const data = this.state.queryData;
    const payload = {
      sender_id: userID(),
      curriculumCode: data.curriculumCode,
      curriculum: data.curriculum,
      subject: data.subject,
      topic: data.topic,
      chapter: data.chapter,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      messages: [
        {
          message: data.message,
          author_id: userID(),
          createdAt: new Date().getTime(),
        },
      ],
    };

    queryInsert(payload)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            isQuery: false,
            queryData: {
              curriculum: "",
              curriculumCode: "",
              subject: "",
              topic: "",
              chapter: "",
              message: "",
            },
          });
          sessionStorage.removeItem("curriculumDataQuery");
          this.getAllQueriesList();
          this.props.history.push("/student/my-queries/open");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleReplayQuery = (data) => {
    data.messages.push({
      message: this.state.replayMessage,
      author_id: userID(),
      createdAt: new Date().getTime(),
    });

    const payload = {
      chatID: data.chatID,
      sender_id: userID(),
      curriculum: data.curriculum,
      curriculumCode: data.curriculumCode,
      subject: data.subject,
      topic: data.topic,
      chapter: data.chapter,
      updatedAt: new Date().getTime(),
      messages: data.messages,
    };

    queryUpdate(payload)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ isQuery: false, isReplay: false, replayMessage: "" });
          this.getAllQueriesList();
          this.props.history.push("/student/my-queries/inprogress");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleResolveQuery = (queryID) => {
    const payload = {
      chatID: queryID,
      sender_id: userID(),
      status: 2,
    };
    queryUpdate(payload)
      .then((res) => {
        // console.log("res", res);
        if (res.status === 200) {
          this.setState({ isQuery: false, modalShow: false });
          this.getAllQueriesList();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleFeedbackQuery = (data) => {
    const payload = {
      chatID: data.chatID,
      sender_id: userID(),
      feedback: this.state.feedbackMessage,
    };

    queryUpdate(payload)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            isQuery: false,
            isReplay: false,
            isFeedback: false,
            feedbackMessage: "",
          });
          this.getAllQueriesList();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  getAllQueriesList = async () => {
    const payload = {
      userID: userID(),
      curriculumCode: userCurriculumCode(),
    };
    getAllQueries(payload)
      .then((res) => {
        const data = res.data.data;
        data &&
          data.length > 0 &&
          data.map((q) => {
            if (q.status === 0) {
              this.setState({ openQueries: res.data.data });
            } else if (q.status === 1) {
              this.setState({ inprogressQueries: res.data.data });
            } else if (q.status === 2) {
              this.setState({ completedQueries: res.data.data });
            }
          });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  getUserCurriculums = () => {
    getUserCurriculum(userID()).then((res) => {
      this.setState({ curriculumList: res.data.data });


    })
  };

  async componentDidMount() {
    this.getUserCurriculums()
    let curr = userCurriculumsList();
    // this.setState({ curriculumList: curr });
    this.getAllQueriesList();

    let data = JSON.parse(sessionStorage.getItem("curriculumDataQuery"));
    if (data) {
      let subjectsList = await getAllSubjectList(data.curriculumCode);
      let topicsList = await getAllTopicsList(
        data.curriculumCode,
        data.subject
      );
      let chaptersList = await getAllChaptersList(
        data.curriculumCode,
        data.subject,
        data.topic
      );
      this.setState({
        subjectsList,
        topicsList,
        chaptersList,
        queryData: data,
      });
    }
  }

  render() {
    const path = this.props.match.path;
    const {
      queryData,
      isFeedback,
      isReplay,
      modalShow,
      replayMessage,
      feedbackMessage,
      openQueries,
      inprogressQueries,
      completedQueries,
      curriculumList,
      subjectsList,
      topicsList,
      chaptersList,
    } = this.state;
    return (
      <React.Fragment>
        {path !== "/student/my-queries/raise-query" && (
          <QueryType queryType={this.queryType} raiseQuery={this.raiseQuery} />
        )}

        {path === "/student/my-queries/raise-query" && (
          <RaiseQuery
            curriculumList={curriculumList}
            subjectsList={subjectsList}
            topicsList={topicsList}
            chaptersList={chaptersList}
            queryData={queryData}
            backQuery={this.backQuery}
            handleSubmitQuery={this.handleSubmitQuery}
            handleInput={this.handleInput}
            handleInputMessage={this.handleInputMessage}
            CurriculumSubjectList={this.state.CurriculumSubjectList}
            topicChaptersList={this.state.topicChaptersList}
          />
        )}

        <div className="col-md-12" style={{ border: "1px solid black", marginTop:"25px",
        width: "96vw",
        marginLeft: "10px",
    borderRadius: "6px"}}>
        {path === "/student/my-queries/open" && (
          <OpenQueries openQueries={openQueries} />
        )}

        {path === "/student/my-queries/inprogress" && (
          <InprogressQueries
            isFeedback={isFeedback}
            isReplay={isReplay}
            modalShow={modalShow}
            inprogressQueries={inprogressQueries}
            replayMessage={replayMessage}
            replayQuery={this.replayQuery}
            cancelQuery={this.cancelQuery}
            handleResolveQuery={this.handleResolveQuery}
            handleReplayQuery={this.handleReplayQuery}
            handleInputReplay={this.handleInputReplay}
            handleClose={this.handleClose}
            handleShow={this.handleShow}
            selectedQuery={this.state.selectedQuery}
          />
        )}

        {path === "/student/my-queries/completed" && (
          <CompletedQueries
            isFeedback={isFeedback}
            isReplay={isReplay}
            completedQueries={completedQueries}
            feedbackMessage={feedbackMessage}
            handleFeedbackQuery={this.handleFeedbackQuery}
            handleInputFeedback={this.handleInputFeedback}
            feedbackQuery={this.feedbackQuery}
            cancelQuery={this.cancelQuery}
            selectedFeedback={this.state.selectedFeedback}
            ratingUpdate={this.ratingUpdate}
          />
        )}
      </div>
      </React.Fragment >
    );
  }
}

export default MyQueriesContainer;
