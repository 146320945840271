import React from "react";
import parse from "html-react-parser";
import DisplayErrorMessage from "../../Share/DisplayErrorMessage";
import styles from "./HelpFromTutor.module.css";

const OpenRequests = ({ openRequests }) => {
  if (openRequests && openRequests.length === 0) {
    return (
      <DisplayErrorMessage message="No open requests found" errorSize="big" />
    );
  }

  return (
    <React.Fragment>
      {openRequests.length > 0 &&
        openRequests.map((q) => {
          if (q.status === "PENDING" && q.role === "student") {
            return (
              <div className={`cardDiv ${styles.tutorDiv}`} key={q._id}>
                <h5 title="Curriculum" className={styles.curriculum}>
                  {q.curriculum} {"  -"}
                </h5>
                <div title="Subjet" className={styles.subject}>
                  {q.subject} {"-"}
                </div>
                <div title="Topic" className={styles.topic}>
                  {q.topics ? q.topics.join(", ") : ""}
                </div>
                {/* <div title="Chapter" className={styles.chapter}>
                  {q.chapter}
                </div> */}

                <hr className={styles.hr} />
                <p>{q.desc ? parse(q.desc) : ""}</p>
                <div className={styles.tutorFeatures}>
                  <p className={styles.status}>Status : Pending</p>
                </div>
              </div>
            );
          }
        })}
    </React.Fragment>
  );
};

export default OpenRequests;
