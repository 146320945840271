import { getLogo } from '../../services/Admin/commonService';


export const GET_LOGO = "GET_LOGO";
export const LOGO = "LOGO"


export const getlogo = () => {
    return dispatch => {
        getLogo().then(res => {
            // console.log('img-' + res.data.img.imageUrl)
            localStorage.setItem('logo', res.data.img.imageUrl)
            dispatch({ type: GET_LOGO, logo: res.data.img.imageUrl })
        }).catch(err => console.log('get logo image'))
    }
}

