import React, { useState, useEffect, Fragment, } from "react";
import Logo from "../../assets/Images/logo.png";
import styles from './verify.module.css'
import { Link } from "react-router-dom";
import { passwordChange } from "../../services/Authentication/AuthService";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

const Verify = props => {
    const [logoImg, setLogoImg] = useState('')
  let image = useSelector(state => {
    localStorage.setItem('logo', state.logo.logo)
    return state.logo.logo
  })
  useEffect(() => {
    //console.log('image logo',image)
    setLogoImg(image)
  }, [image])
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    let history = useHistory();

    const emailSent = (event) => {
        setEmail(event.target.value)

    }
    const submitEmail = () => {
        if (email.trim() === '') {
            setError('Email cant be empty')
            return;
        }
        passwordChange({ emailId: email }).then(res => {
            if (res.status === 200) {
                if(res.data.status){
                    history.push("/login");

                }else{
                    setError(res.data.Message)

                }

            } else {
                setError(res.data.Message)
            }
        })
    }
    return <Fragment>
        <div className={styles.cardContainer}>
            <div className="card" className={styles.card}>
               {logoImg && <img src={logoImg} height="170px"
                    className={styles.image} alt="logo" />}
                <div className="card-body">
                    <div className="mb-3">
                        <label for="exampleInputEmail1" className="form-label">Email address</label>
                        <input type="email" className={`form-control ${styles.input}`} id="exampleInputEmail1" value={email} onChange={emailSent} aria-describedby="emailHelp" />
                        <small className="form-text">Enter a verified Email.</small>
                        {error && <small className="form-text text-danger">{error}.</small>}

                        <button type="submit" className="btn btn-primary mt-3 w-100" onClick={submitEmail}>Submit</button>

                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}
// const style = {
//     card: {
//         width: "50%",
//     },
//     cardImg: {
//         height: 100
//     }
// }

export default Verify