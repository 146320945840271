import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
const StudentList = (props) => {
  const { studentList, search, searchStudent } = props;
  const history = useHistory();

  // useEffect(() => {
  //     window.scrollTo(0, 0)
  // }, [])
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-12  ">
            <h3>Student List</h3>
          </div>
          <div className="col-md-12 d-flex "></div>
          <div class="input-group row col-12   mb-2 ">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <img
                  src="https://img.icons8.com/search"
                  style={{ maxWidth: "40%" }}
                />
              </div>
            </div>
            <input
              type="text"
              value={search}
              onChange={(text) => searchStudent(text)}
              class="form-control"
              placeholder="Search Student"
            />
          </div>
          <div className="card">
            <div
              className="card-body"
              style={{ display: "block", overflowX: "scroll" }}
            >
              <table className="table table-bordered overflow-hidden">
                <thead>
                  <tr>
                    <th>Student Name</th>
                    <th>Role</th>
                    <th>Email</th>
                    <th>Curriculum code</th>
                    <th>Curriculum</th>
                    <th>gender</th>
                    <th>D.O.B</th>
                    <th>Mobile No.</th>
                    <th>address</th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {studentList &&
                    studentList.length > 0 &&
                    studentList.map((q) => {
                      if (q.role === "student") {
                        return (
                          <tr>
                            <td>
                              {q.firstName} {q.middleName} {q.lastName}{" "}
                            </td>
                            <td>{q.role}</td>
                            <td>{q.email}</td>
                            <td>
                              {q.curriculumCode ? q.curriculumCode : ""}
                            </td>
                            <td>
                              {q.curriculum
                                ? q.curriculum
                                : ""}
                            </td>
                            <td>{q.gender}</td>
                            <td>{q.dob && q.dob.split('T')[0]}</td>
                            <td>{q.mobile}</td>
                            <td>{q.address}</td>

                            <td>
                              <Link to={`/admin/student-view/${q.userID}`}>
                                <button className="btn btn-sm btn-info m-1">
                                  View
                                </button>
                              </Link>
                              <Link to={`/admin/student-edit/${q.userID}`}>
                                <button className="btn btn-sm btn-info m-1">
                                  Edit
                                </button>
                              </Link>
                            </td>
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StudentList;
