import React from "react";
import { Modal } from "react-bootstrap";
import loadingImg from "../../assets/Images/loading.gif";
import styles from "./share.module.css";

const LoadingModal = (visible) => {
  return (
    <React.Fragment>
      <Modal
        show={visible.visible}
        animation={false}
        centered
        contentClassName={styles.modalContent}
        backdropClassName={styles.modalBackdrop}
      >
        <img
          src={loadingImg}
          alt="Loading"
          height="100"
          width="100"
          style={{ margin: "0px auto" }}
        />
      </Modal>
    </React.Fragment>
  );
};

export default LoadingModal;
