import React from "react";
import parse from "html-react-parser";
import Select from "../common/select";
import Input from "../common/input";

import {
  curriculumListFun,
  subjectListFun,
  topicListFun,
  chapterListFun,
} from "../../../utils/utils";
import { Link } from "react-router-dom";

const AddTemplate = (props) => {
  const {
    mode,
    templateData,
    curriculumList,
    subjectsList,
    topicsList,
    chaptersList,
    handleInput,
    handleGetQuestions,
    questionsList,
    optioncheckbox,
    handleSubmit,
    submitFalse,
  } = props;

  const data = templateData;

  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);
  let topicOptions = topicListFun(topicsList);
  let chapterOptions = chapterListFun(chaptersList);

  const templateTypeOptions = [
    { _id: "1", name: "Public" },
    { _id: "2", name: "Private" },
  ];
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <h1 className="form-heading">{mode} Template</h1>
          <div className="add-btn-div">
            <Link
              to={`/admin/template`}
              className="btn btn-sm btn-danger"
            >
              Cancel
            </Link>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="templateName"
                    label="Template Name"
                    value={data.templateName ? data.templateName : ""}
                    onChange={handleInput}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    name="templateType"
                    label="Template Type"
                    value={data.templateType ? data.templateType : ""}
                    options={templateTypeOptions}
                    onChange={handleInput}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    name="curriculum"
                    optname="curriculum"
                    label="Curriulum Name"
                    value={data.curriculum ? data.curriculum : ""}
                    options={curriculumOptions}
                    onChange={(e) => {
                      handleInput(e, "curriculum");
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    name="subject"
                    optname="subject"
                    label="Subject Name"
                    value={data.subject ? data.subject : ""}
                    options={subjectOptions}
                    onChange={(e) => {
                      handleInput(e, "subject");
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    name="topic"
                    optname="topic"
                    label="Topic Name"
                    value={data.topic ? data.topic : ""}
                    options={topicOptions}
                    onChange={(e) => handleInput(e, "topic")}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    name="chapter"
                    optname="chapter"
                    label="Chapter Name"
                    value={data.chapter ? data.chapter : ""}
                    options={chapterOptions}
                    onChange={(e) => handleInput(e, "chapter")}
                  />
                </div>

                {data.curriculum && data.subject && (
                  <div className="col-md-12 text-right">
                    <button
                      type="submit"
                      onClick={handleGetQuestions}
                      className="btn btn-sm btn-primary"
                    >
                      List
                    </button>
                  </div>
                )}

                <div className="col-md-12 mt-4">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th>ID</th>
                        <th>Question</th>
                        <th>Marks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {questionsList &&
                        questionsList.map((ql) => {
                          return (
                            <tr key={ql._id}>
                              <td
                                style={{ width: "50px", textAlign: "center" }}
                              >
                                <input
                                  type="checkbox"
                                  name="optioncheckbox"
                                  value
                                  onChange={(e) =>
                                    optioncheckbox(e, ql.questionID)
                                  }
                                />
                              </td>
                              <td style={{ width: "105px", fontSize: "13px" }}>
                                {ql.questionID}
                              </td>
                              <td>
                                <span>{parse(ql.question)}</span>
                              </td>
                              <td>
                                {ql.questionmarks ? ql.questionmarks : ""}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary mt-4"
                      onClick={handleSubmit}
                      disabled={submitFalse}
                    >
                      Submit
                    </button>
                  </div>
                  {/* )} */}
                </div>
                {/* ) : (
                ""
              )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTemplate;
