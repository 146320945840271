import React from 'react'
import "./Subscription.css";

export default function Subscription() {
  return (
    <React.Fragment>
      <section className="pricing-table">
        <div className="container">
          <div className="block-heading">
            <h2>Our Pricing</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam
              urna, dignissim nec auctor in, mattis vitae leo.
            </p>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-md-5 col-lg-4">
              <div className="item">
                <div className="heading">
                  <h3>BASIC</h3>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <div className="features">
                  <h4>
                    <span className="feature">Full Support</span> :{" "}
                    <span className="value">No</span>
                  </h4>
                  <h4>
                    <span className="feature">Duration</span> :{" "}
                    <span className="value">30 Days</span>
                  </h4>
                  <h4>
                    <span className="feature">Storage</span> :{" "}
                    <span className="value">10GB</span>
                  </h4>
                </div>
                <div className="price">
                  <h4>$25</h4>
                </div>
                <button className="btn btn-block btn-primary" type="submit">
                  BUY NOW
                </button>
              </div>
            </div>
            <div className="col-md-5 col-lg-4">
              <div className="item">
                <div className="ribbon">Best Value</div>
                <div className="heading">
                  <h3>PRO</h3>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <div className="features">
                  <h4>
                    <span className="feature">Full Support</span> :{" "}
                    <span className="value">Yes</span>
                  </h4>
                  <h4>
                    <span className="feature">Duration</span> :{" "}
                    <span className="value">60 Days</span>
                  </h4>
                  <h4>
                    <span className="feature">Storage</span> :{" "}
                    <span className="value">50GB</span>
                  </h4>
                </div>
                <div className="price">
                  <h4>$50</h4>
                </div>
                <button className="btn btn-block btn-primary" type="submit">
                  BUY NOW
                </button>
              </div>
            </div>
            <div className="col-md-5 col-lg-4">
              <div className="item">
                <div className="heading">
                  <h3>PREMIUM</h3>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <div className="features">
                  <h4>
                    <span className="feature">Full Support</span> :{" "}
                    <span className="value">Yes</span>
                  </h4>
                  <h4>
                    <span className="feature">Duration</span> :{" "}
                    <span className="value">120 Days</span>
                  </h4>
                  <h4>
                    <span className="feature">Storage</span> :{" "}
                    <span className="value">150GB</span>
                  </h4>
                </div>
                <div className="price">
                  <h4>$150</h4>
                </div>
                <button className="btn btn-block btn-primary" type="submit">
                  BUY NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
