import React from "react";
import styles from "./Assignments.module.css";
import cur from "../../../assets/Banners/Banner4.png";
import IconFormalAssignments from "../../../assets/Icons/IconFormalAssignments.png";

const AssignmentType = ({ isType, assignmentType }) => {
  return (
    <React.Fragment>
      <div className="row"></div>
      <div className="col-md-12 row mb-35" id="buttonItem">
        <div
          className={`tabItem ${isType === "formal" ? "tabItemActive" : ""}`}
          onClick={() => assignmentType("formal")}
        >
          {/* <img src={IconFormalAssignments} style={{ height: 24 }} /> */}
          FORMAL ASSIGNMENTS
        </div>
        <div
          className={`tabItem ${isType === "informal" ? "tabItemActive" : ""}`}
          onClick={() => assignmentType("informal")}
        >
          INFORMAL ASSIGNMENTS
        </div>
      </div>
    </React.Fragment>
  );
};

export default AssignmentType;
