import React from "react";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";
import { deleteTemplate } from "../../../services/Admin/templateService";
const ViewTemplate = (props) => {
  const history = useHistory();
  const { templateData,  questionsList } = props;

  return (
    <div className="row justify-content-center">
      <div className="closePage">
        <h3>View Template</h3>
        <button className="btn btn-sm btn-danger" onClick={() => {deleteTemplate({templateID:templateData.templateID}); history.goBack();}}>
          Delete
        </button>
        <button className="btn btn-sm btn-secondary mr-2" onClick={() => history.goBack()}>
          Close
        </button>
      </div>
      <div className="col-md-10">
        <div className="card-deck">
          <div className="card">
            <div className="card-body">
              <div className="text-view">
                <label>Template ID</label>
                <span>
                  {templateData.templateID ? templateData.templateID : ""}
                </span>
              </div>
              <div className="text-view">
                <label>Template Name</label>
                <span>
                  {templateData.templateName ? templateData.templateName : ""}
                </span>
              </div>
              <div className="text-view">
                <label>Curriculum Name</label>
                <span>
                  {templateData.curriculum ? templateData.curriculum : ""}
                </span>
              </div>
              <div className="text-view">
                <label>Subject Name</label>
                <span>{templateData.subject ? templateData.subject : ""}</span>
              </div>
              <div className="text-view">
                <label>templateType</label>
                <span>
                  {templateData.templateType ? templateData.templateType : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-3 ">
          {questionsList.length > 0 &&
            questionsList.map((ql, i) => {
              return (
                <div key={ql._id} className="question-list card mb-2">
                  <label>Question Type: {ql.questionType}</label>
                  <label>Question Marks: {ql.questionmarks}</label>
                  <label>Question ID: {ql.questionID}</label>
                  <hr />
                  <div>
                    <div className="ans">
                      <span style={{ float: "left", marginRight: "10px" }}>
                        {i + 1 + "."}
                      </span>
                      {parse(ql.question)}
                    </div>
                    {ql.questionType === "Multi" && (
                      <React.Fragment>
                        <div className="ans">
                          <i className="las la-check-circle"></i>{" "}
                          {ql.correctanswer}
                        </div>
                        <div className="ans">
                          <i className="las la-times-circle"></i> {ql.answer1}
                        </div>
                        <div className="ans">
                          <i className="las la-times-circle"></i> {ql.answer2}
                        </div>
                        <div className="ans">
                          <i className="las la-times-circle"></i> {ql.answer3}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ViewTemplate;
