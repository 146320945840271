import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/Images/log.png";
import styles from "./Sidebar.module.css";

const Sidebar = () => {
  const [logoImg, setLogoImg] = useState()
  useEffect(() => {
    setLogoImg(localStorage.logo)
  }, [])
  return (
    <aside className={styles.mainSidebar}>
      <div className={styles.logo}>
        <img src={Logo} alt="logo" />
        <span>Teacher Dashboard</span>
        <hr />
      </div>

      <ul className="navbar-nav ml-auto mt-3">
        <li className="nav-item">
          <NavLink
            to="/teacher/dashboard"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-th-large ${styles.sideIcon}`}></i>
            Dashboard
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/teacher/assignments-list"
            exact
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Assignments
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/teacher/calender"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Calender
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/teacher/queries-list"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Querises
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/teacher/teacher-session"
            exact
            activeClassName="active"
            className={`nav-link ${styles.navLink}`}
          >
            <i className={`las la-calendar ${styles.sideIcon}`}></i>
            Session
          </NavLink>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
