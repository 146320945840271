import * as Yup from "yup";

const registerValidation = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  // mobile: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Required")
    .matches(
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  gender: Yup.string().required("Required"),
  dob: Yup.date().required("Required"),
  // curriculum: Yup.string().required("Required"),
  curriculum: Yup.string(),
  parentOrGardien: Yup.object().shape({
    parentFirstName: Yup.string(),
    parentLastName: Yup.string(),
    parentMobile: Yup.number(),
    parentEmail: Yup.string().email(),
    parentAddress: Yup.string(),
  }),
  address: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string(),
  pincode: Yup.number().required("Required"),
  identityNumber: Yup.number(),
  identityUrl: Yup.string(),
  isGoogle: Yup.boolean()
});


const GoogleregisterValidation = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  mobile: Yup.number().required("Required"),
  email: Yup.string().email().required("Required"),
  mobile: Yup.number().required("Required"),
  // password: Yup.number().required("Required"),
  gender: Yup.string().required("Required"),
  dob: Yup.date().required("Required"),
  curriculum: Yup.string().required("Required"),
  parentOrGardien: Yup.object().shape({
    parentFirstName: Yup.string().required("Required"),
    parentLastName: Yup.string().required("Required"),
    parentMobile: Yup.number().required("Required"),
    parentEmail: Yup.string().email().required("Required"),
    parentAddress: Yup.string().required("Required"),
  }),
  address: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  pincode: Yup.number().required("Required"),
  identityNumber: Yup.number().required("Required"),
  identityUrl: Yup.string().required("Required"),
  isGoogle: Yup.boolean()
});


const loginValidation = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
});

const sessionValidation = Yup.object().shape({
  curriculum: Yup.string().required("Required"),
  subject: Yup.string().required("Required"),
  topic: Yup.string().required("Required"),
  chapter: Yup.string().required("Required"),
});

export { registerValidation, loginValidation, sessionValidation, GoogleregisterValidation };
