const registerValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  mobile: "",
  email: "",
  password: "",
  gender: "",
  dob: "",
  curriculum: "",
  curriculumCode: "",
  parentOrGardien: {
    parentFirstName: "",
    parentMiddleName: "",
    parentLastName: "",
    parentMobile: "",
    parentEmail: "",
    parentAddress: "",
  },
  address: "",
  country: "",
  state: "",
  city: "",
  pincode: "",
  identityUrl: "",
  identityNumber: "",
};

const loginValues = {
  email: "",
  password: "",
};

const sessionValues = {
  curriculum: "",
  curriculumCode: "",
  subject: "",
  topic: "",
  chapter: "",
  message: "",
  sessionMode:"",
};

export { registerValues, loginValues, sessionValues };
