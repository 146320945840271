//import { map } from 'jquery';
import React, { useState } from 'react'
import "./Subscription.css";
import { getAllCurriculum } from '../../services/Admin/curriculumService';
import Subscription_card from './Subscription_card';

export default function Subscription() {
  const [data, setData] = useState([]);

  // const [arr, setArr] = React.useState();


  // const data=getAllCurriculum().then((res)=>{
  //   return res.data.data;
  // }).catch((err)=>{
  //   console.log(err);
  // });
  // var d;
  // const getData = () => {
  // getAllCurriculum()
  //   .then((res) =>{ 
  //     console.log(res.data.data, "Response");
  //     setArr(res.data.data)
  // }).catch((err) => {
  // console.log("err", err);
  // });
  // }

  // React.useEffect(() => {
  //   console.log("Effect Running");
  //   getData();
  // }, [])

  const getResponseData = () => {
    getAllCurriculum()
      .then((res) => {
        console.log(res.data.data, "Response");
        setData(res.data.data);
        // setArr(res.data.data)
      }).catch((err) => {
        console.log("err", err);
      });
  }


  // console.log(arr, "Array");
  // var d = [{
  //   curriculum: "BASIC",
  //   Price: "$25",
  //   Discription: "Storage: 50GB , Support: NO Support , Duration: 15 Days "
  // },
  // {
  //   curriculum: "PRO",
  //   Price: "$50",
  //   Discription: "Storage: 100GB , Support: little Support , Duration: 30 Days "
  // },
  // {
  //   curriculum: "PREMIUM",
  //   Price: "$150",
  //   Discription: "Storage: 250GB , Support: Full Support , Duration: 60 Days "
  // },
  // {
  //   curriculum: "Unlimited",
  //   Price: "$250",
  //   Discription: "Storage: 500GB , Support: Full Support , Duration: 120 Days "
  // },
  // {
  //   curriculum: "Unlimited",
  //   Price: "$250",
  //   Discription: "Storage: 500GB , Support: Full Support , Duration: 120 Days "
  // },]

  // const loadScript = (src) => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement('script')

  //     script.src = src

  //     script.onload = () => {
  //       resolve(true)
  //     }

  //     script.onerror = () => {
  //       resolve(false)
  //     }

  //     document.body.appendChild(script)
  //   })
  // }

  // useEffect(() => {
  //   loadScript("https://checkout.razorpay.com/v1/checkout.js");
  //   getResponseData();
  // }, [])

  // console.log('====================================');
  // console.log(data, "Data Data");
  // console.log('====================================');



  return (

    <React.Fragment>
      <div className="parent"></div>
      <div className="container text-center" id="heading">
        <h1>Curriculum</h1>
        <p id="head-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam
          urna, dignissim nec auctor in, mattis vitae leo.</p>
      </div>

        <Subscription_card/>
      {/* <div className="main">
        {
          data && data.map((e) => {
            return (
              <>

                <div className="Container text-center" id="blocks">
                  <div className="block" id="block-I"><h2>{e.curriculum}</h2>
                    <div className="info">
                      {e.curriculumCode}
                    </div>
                    <div className="amount">
                      <h1>$250</h1>
                    </div>
                    <button className="btn btn-block btn-primary" id="button" type="submit" onClick={handlePay}>
                      BUY NOW</button>
                  </div>
                </div>
              </>
            )
          }
          )
        }
      </div> */}



      {/* <div className="block" id="block-II"><h2>PRO</h2>
              <p className="block-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <div className="features">
                <h4>
                  <span className="key">Full Support</span> :{" "}
                  <span className="value">Yes</span>
                </h4>
                <h4>
                  <span className="key">Duration</span> :{" "}
                  <span className="value">60 Days</span>
                </h4>
                <h4>
                  <span className="key">Storage</span> :{" "}
                  <span className="value">50GB</span>
                </h4>
              </div>
              <div className="amount">
                <h2>$50</h2>
              </div>
              <button className="btn btn-block btn-primary" id="button" type="submit">
                BUY NOW
              </button>
            </div> */}

      {/* <div className="block" id="block-III"><h2>PREMIUM</h2>
              <p className="block-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <div className="features">
                <h4>
                  <span className="key">Full Support</span> :{" "}
                  <span className="value">Yes</span>
                </h4>
                <h4>
                  <span className="key">Duration</span> :{" "}
                  <span className="value">120 Days</span>
                </h4>
                <h4>
                  <span className="key">Storage</span> :{" "}
                  <span className="value">150GB</span>
                </h4>
              </div>
              <div className="amount">
                <h2>$150</h2>
              </div>
              <button className="btn btn-block btn-primary" id="button" type="submit">
                BUY NOW
              </button>
            </div> */}

      {/* <div className="block" id="block-IV"><h2>UNLIMITED</h2>
              <p className="block-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <div className="features">
                <h4>
                  <span className="key">Full Support</span> :{" "}
                  <span className="value">Yes</span>
                </h4>
                <h4>
                  <span className="key">Duration</span> :{" "}
                  <span className="value">120 Days</span>
                </h4>
                <h4>
                  <span className="key">Storage</span> :{" "}
                  <span className="value">Unlimited</span>
                </h4>
              </div>
              <div className="amount">
                <h2>$250</h2>
              </div>
              <button className="btn btn-block btn-primary" id="button" type="submit">
                BUY NOW
              </button></div> */}
    </React.Fragment >
  );
}
