import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const videosList = (payload) => {
  return http.post(apiUrl + "/videosList", payload, {
    headers: headers,
  });
};

export const deleteVideo = (payload) => {
  return http.post(apiUrl + "/deleteVideo", payload, {
    headers: headers,
  });
};

export const videoUpload = (payload, fileUpload) => {
  const videoHeaders = { headers, "x-access-token": getAuthToken() };
  return http.post(apiUrl + "/api/profile/video-upload", payload, {
    headers: videoHeaders,
  });
};

export const submitVideo = (payload) => {
  return http.post(apiUrl + "/submitVideo", payload, {
    headers: headers,
  });
};

export const getVideo = (payload) => {
  return http.post(apiUrl + "/getvideo", payload, {
    headers: headers,
  });
};
export const getVideoList = (payload) => {
  return http.post(apiUrl + "/getVideoList", payload, {
    headers: headers,
  });
};

export const submitRead = (payload) => {
  return http.post(apiUrl + '/submitRead', payload, {
    headers: headers,
  })
}
export const checkVideoStatus = (payload) => {
  return http.post(apiUrl + '/checkVideo', payload, {
    headers: headers,
  })
}
export const fileUpload = (payload, head) => {
  const videoHeaders = { headers, "x-access-token": getAuthToken() };

  return http.post(apiUrl + "/api/profile/upload-file", payload, {
    headers: head,
  });
};
export const fileUploadpdf = (payload, head) => {
  const videoHeaders = { headers, "x-access-token": getAuthToken() };

  return http.post(apiUrl + "/api/profile/upload-file-pdf", payload, {
    headers: head,
  });
};