import React, { useCallback, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NavLink } from "react-router-dom";
import styles from "./Assignments.module.css";
import parse from "html-react-parser";
import Logo from "../../../assets/Images/logo.png";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import fscreen from 'fscreen';
import './Assignments.module.css'
const TestView = (props) => {
  const { questions, handleOption, handleOptionText, submitTest } = props;
  const handle = useFullScreenHandle();
  const [showTest, setShowTest] = useState(false)
  // const [minutes, setMinutes] = useState(60);
  // const [seconds, setSeconds] = useState(0);

  // useEffect(() => {
  //   let myInterval = setInterval(() => {
  //     if (seconds > 0) {
  //       setSeconds(seconds - 1);
  //     }
  //     if (seconds === 0) {
  //       if (minutes === 0) {
  //         clearInterval(myInterval);
  //       } else {
  //         setMinutes(minutes - 1);
  //         setSeconds(59);
  //       }
  //     }
  //   }, 1000);
  //   return () => {
  //     clearInterval(myInterval);
  //   };
  // });
  const reportChange = (state, handle1) => {
    console.log(handle)

    if (!state && showTest) {
      if (window.confirm("Do you want to exit test?")) {
        console.log('true');
        window.close();

      } else {
        console.log('false');
       }
    }

  }
  useEffect(() => {
    // handle.enter()
    // console.log('fullscreen' + fscreen.fullscreenEnabled)
    if (fscreen.fullscreenElement === null) {
      setShowTest(false)
    } else {
      setShowTest(true)
    }

  }, [fscreen.fullscreenElement])
  const enterFullscreen = () => {
    handle.enter()
    //console.log(fscreen.fullscreenElement)

  }

  return (
    <React.Fragment>

      <div>

        <div className={`${styles.testInfo}`}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 text-left">
                <NavLink to="/student/dashboard">
                  <img src={Logo} alt="logo" style={{ height: "60px" }} />
                </NavLink>
              </div>
              <div className="col-md-8">
                <h3 >Assessment 1</h3>
                <p>
                  Subject: <span>EDC</span>

                </p>
                <button className="btn btn-sm btn-success" onClick={enterFullscreen}>
                  Start Test
                </button>
              </div>
            </div>
          </div>
        </div>


        <FullScreen handle={handle} style={{ backgroundColor: "white" }} onChange={reportChange}>
          {showTest && <div style={{ height: '100%', backgroundColor: "white",overflow:'auto'}}>
            <div className={`${styles.testInfo}`}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-left">
                    <NavLink to="/student/dashboard">
                      <img src={Logo} alt="logo" style={{ height: "60px" }} />
                    </NavLink>
                  </div>
                  <div className="col-md-8">
                    <h3 >Assessment 1</h3>
                    <p>
                      Subject: <span>EDC</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                minHeight: window.innerHeight - 119,
                background: "#f7f7f7",
                padding: "30px 0px",
              }}
            >
              <div className="container">
                <div className="row justify-content-center">
                  {/* <div className="col-md-4">
              <div className={`${styles.testTimerBg}`}>
                <ul className={`${styles.testTimerLeft}`}>
                  <li>
                    <h4>20</h4>
                    <p>Questions</p>
                  </li>
                  <li>
                    {minutes === 0 && seconds === 0 ? null : (
                      <h4>
                        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                      </h4>
                    )}
                    <p>Minutes</p>
                  </li>
                </ul>
              </div>
            </div> */}
                  <div className="col-md-8">
                    {questions &&
                      questions.length > 0 &&
                      questions.map((q, i) => {
                        if (q.questionType === "Multi") {
                          return (
                            <div className={`${styles.quesItem}`} key={q._id}>
                              <div className={`${styles.quesTitle}`}>
                                {q.question ? (
                                  <React.Fragment>
                                    <span>Q{i + 1} :</span> {parse(q.question)}
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="ui form">
                                <div className={`${styles.radioCheckbox}`}>
                                  <input
                                    type="radio"
                                    name={`question${i + 1}`}
                                    value={q.answer1}
                                    onChange={(e) => handleOption(e, q)}
                                  />
                                  <label>{q.answer1}</label>
                                </div>
                                <div className={`${styles.radioCheckbox}`}>
                                  <input
                                    type="radio"
                                    name={`question${i + 1}`}
                                    value={q.answer2}
                                    onChange={(e) => handleOption(e, q)}
                                  />
                                  <label>{q.answer2}</label>
                                </div>
                                <div className={`${styles.radioCheckbox}`}>
                                  <input
                                    type="radio"
                                    name={`question${i + 1}`}
                                    value={q.answer3}
                                    onChange={(e) => handleOption(e, q)}
                                  />
                                  <label>{q.answer3}</label>
                                </div>
                                <div className={`${styles.radioCheckbox}`}>
                                  <input
                                    type="radio"
                                    name={`question${i + 1}`}
                                    value={q.correctanswer}
                                    onChange={(e) => handleOption(e, q)}
                                  />
                                  <label>{q.correctanswer}</label>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className={`${styles.quesItem}`} key={q._id}>
                              <div className={`${styles.quesTitle}`}>
                                {q.question ? (
                                  <React.Fragment>
                                    <span>Q{i + 1} :</span> {parse(q.question)}
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="ui form">
                                <CKEditor
                                  editor={ClassicEditor}
                                  onChange={(event, editor) => {
                                    handleOptionText(editor.getData(), q);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        }
                      })}

                    <div className="col-md-12 text-center">
                      <button
                        onClick={() => submitTest(questions)}
                        className={`btn btn-sm btn-primary`}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </FullScreen>
      </div>



    </React.Fragment>
  );
};

export default TestView;
